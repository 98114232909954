import React, { useState, useEffect } from "react";
import { Col, Layout, Menu, message, Row, Select, Spin } from "antd";
import Icon from "@ant-design/icons";
import { GET } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { LesseeSvg } from "../../components/layouts/Svg";
import { GetResourceActions, IsActionAllowed } from "../../util/AllowedActions";
import DealerForm from "./components/DealerForm";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDealershipOptions } from "../../reducers/DealerUsers";

const { Content, Sider } = Layout;

export default function DealerUserRender(props) {
  const location = useLocation();
  const { allowedActions, handleNoAuth, actionTypeParent } = props;
  const adminUserActions = GetResourceActions("AdminUser", allowedActions);
  const allowedGet = IsActionAllowed("get", adminUserActions);
  const allowedUpdate = IsActionAllowed("update", adminUserActions);
  const { id } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [dealerUser, setDealerUser] = useState();
  const [activeMenu, setActiveMenu] = useState([1]);
  const [actionType, setActionType] = useState('DETAILS');
  const [isDisable, setIsDisable] = useState(location.state?.actionType === 'DETAILS' ? true : false) ;
  
  const dispatch = useDispatch();
  const dealerDataOptions = useSelector((state) => state.dealerUsers.dealerListOptions);
  
  const getDealerUser = async () => {
    if (allowedGet) {
      setLoading(true);
      try {
        const response = await GET(`/api/v1/dealer-users/${id}`);
        if(response) {
        setDealerUser(response.data);
        setLoading(false);
        }
      } catch (e) {
        logger.error("Error while fetching Dealer User", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error(e.response?.data?.message);
        }
      }
    }
  };

  const getDealershipOptionsData = async () => {
    if (allowedGet) {
      setLoading(true);
      try {
        const response = await GET(`/api/v1/dealerships/dealerships-options`); 
        dispatch(getDealershipOptions({ data: response.data.dealerships_options }));
      
        setLoading(false);
      } catch (e) {
        logger.error("Error while fetching Dealerships Options", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error("Error while fetching Dealerships Options");
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setActionType(location.state?.actionType || "DETAILS");
    if (id !== "create") {
      getDealerUser();
    }
    if (dealerDataOptions.length === 0) {
      getDealershipOptionsData();
    }
  }, [setActionType, id]);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="dealers" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu.toString()]}
              style={{ width: "100%" }}
              onSelect={({ key }) => {
                if (!key.includes("sub")) {
                  setActiveMenu([...activeMenu, key]);
                }
              }}
            >
              <Menu.Item key="1">
                <Icon component={LesseeSvg} />
                Dealer User
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            {actionType && (
              <MainBreadcrumb
                items={[
                  { text: "Home", link_type: "linkto", link: "/dashboard" },
                  { text: "Dealers", link_type: "ahref", link: void 0 },
                  {
                    text: "Dealer Users",
                    link_type: "ahref",
                    link: "/new-dealer-users",
                  },
                  {
                    text: `${id}`,
                    link_type: "ahref",
                    link: void 0,
                  },
                ]}
              />
            )}

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  { id !== 'create' ? dealerUser && actionType  && 
                    <DealerForm
                      actionType={actionType}
                      dealerData={dealerUser}
                      dealershipsOptions={dealerDataOptions}
                      setLoading={setLoading}
                      handleNoAuth={handleNoAuth}
                      allowedUpdate={allowedUpdate}
                      isDisable={isDisable}
                      setIsDisable={setIsDisable}
                    />
                   : (<DealerForm
                    actionType={actionType}
                    dealerData={dealerUser}
                    dealershipsOptions={dealerDataOptions}
                    setLoading={setLoading}
                    handleNoAuth={handleNoAuth}
                    allowedUpdate={allowedUpdate}
                    isDisable={isDisable}
                  />)}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  );
}

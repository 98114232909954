import React from "react";
import { useHistory } from 'react-router-dom';
import { Card, Row, Col, Table, message, Button } from "antd";
import { GET } from '../../../util/network';
import logger from '../../../util/logger';

export default function BlackboxTable({ data, handleNoAuth, setLoading }) {
    const history = useHistory();

    const dataSource = data.leaseApplicationBlackboxRequests || []
    const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    if(authData !== null) {
        loggedInUserId = authData.id;
    }
    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = locked_by != loggedInUserId ? true : false

    const handleRepullBlackbox = async () => {
        setLoading(true);
        try {
          await GET(`/api/v1/lease_applications/repull-datax?application_identifier=${data.applicationIdentifier}`);
          message.success("Blackbox Will Be Repulled Shortly");
        } catch (e) {
          logger.error("Error processing Blackbox", e);

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error processing Blackbox');
          }
        }
        setLoading(false);
    }

    const columns = [
      {
        title: 'Lessee',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Type',
        dataIndex: 'blackboxEndpoint',
        key: 'blackboxEndpoint'
      },
      {
        title: 'Decision',
        dataIndex: 'decision',
        key: 'decision'
      },
      {
        title: 'Score',
        dataIndex: 'adjustedScore',
        key: 'adjustedScore'
      },
      {
        title: 'Requested At',
        dataIndex: 'createdAt',
        key: 'createdAt'
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        defaultSortOrder: 'descend',
        sorter: (a, b) => new Date(a.updatedAtUnformatted) - new Date(b.updatedAtUnformatted)
      },
      {
        title: 'Details',
        dataIndex: 'leaseApplicationId',
        key: 'leaseApplicationId',
        render(val, row) {
            return <a href = {`${val}/blackbox/${row.id}`} >View</a>
        }
      },
    ];

    return (
      <Card title='Blackbox'>
          <Row>
            <Col span={24}>
              <Table className='slc-table' rowKey="id" columns={columns} dataSource={dataSource} pagination={true} size="small" bordered={false}/>
            </Col>
          </Row>
          <Row>
            {
              data.creditStatus !== 'Unsubmitted' && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Button
                  className='slc-button-link-only'
                  type="link"
                  onClick={ () => { handleRepullBlackbox() } }
                  disabled={disableFlag}
                >
                  Repull Blackbox
                </Button>
              </Col>
            }
          </Row>
      </Card>
    );
}
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import FundingDelays from './FundingDelays';
import {
  Card,
  Form,
  Radio,
  Select,
  Col,
  Input,
  message,
  Button,
  Row
} from "antd";
import { GET, POST } from '../../../util/network';
import logger from '../../../util/logger';
import { useDispatch } from "react-redux";
import {insertFundingDelay} from "../../../reducers/LeaseApplications";

const Option = Select.Option;

export default function FundingDelay({ data, appNumber, getApplicationDetails, handleNoAuth, save, setLoading }) {
  const history = useHistory();
  const [reasons, setReasons] = useState([]);
  const [form] = Form.useForm();
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false
  const dispatch = useDispatch();

  useEffect(() => {
    const getReasons = async () => {
      try {
        const response = await GET(`/api/v1/funding_delay_reasons`);
        setReasons(response.data.funding_delay_reasons);
      } catch (e) {
        logger.error("Fetch Reasons Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error fetching funding delay reasons');
        }
      }
    };
    getReasons();
  }, []);
  
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onFinish = async (values) => {
    if (values.notes === undefined) {
      values.notes = ""
    }
    values = { ...values };
    setLoading(true)
    try {

      const response = await POST(`/api/v1/lease_applications/create_funding_delay`, {
        application_identifier: appNumber,
        funding_delays_attributes: values
      });
      message.success("Data saved successfully");
      dispatch(insertFundingDelay(response.data));
      form.resetFields();
    } catch (e) {
      logger.error("Save Funding Delay Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving your data');
      }
    }
    setLoading(false)
  }

  const generateFundingStatusEmail = async () => {
    setLoading(true)
    try {
      const response = await POST(`/api/v1/lease_applications/generate-funding-delay-status-email`,
          { application_identifier: data.applicationIdentifier })
      message.success('Funding Delay Status Email Sent.')
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history)
      } else {
        message.error('Error while sending funding delay status email')
      }
    }
    setLoading(false)
  }

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return (
    <>
      <Form {...layout} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>        
        <Col span={24}>
          <Card title='Apply New Funding Delay'>
            <Row>
              <Col span={24}>
                <Form.Item 
                  label="Reasons" 
                  style={{ width: "100%" }}
                  name="funding_delay_reason_id"
                >
                  <Select placeholder="Please select Reasons" disabled={disableFlag}>
                    {
                      (reasons || []).map((obj, key) => {
                        return <Option key={key} value={obj.id}>{obj.reason}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
                </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item 
                  label="Status" 
                  style={{ width: "100%" }} 
                  name="status"
                >
                  <Radio.Group>
                    <Radio value="Required" disabled={disableFlag}>
                      Required
                    </Radio>
                    <Radio value="Not Required" disabled={disableFlag}>
                      Not Required
                    </Radio>
                    <Radio value="Cleared" disabled={disableFlag}>
                      Cleared
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Notes"
                  style={{ width: "100%" }}
                  name="notes"
                >
                  <Input.TextArea name="notes" rows={4} style={{ width: "100%" }} disabled={disableFlag} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
              <Form.Item
                  style={{ width: "100%" }}
                >
                  <Button type="primary" htmlType="submit" disabled={disableFlag}>
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Form>

      <Row gutter={[16, 16]} className='mt-20'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card title='Funding Delays'>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <FundingDelays 
                   
                  setLoading={setLoading} 
                  appNumber={appNumber} 
                  data={data} 
                  save={save} 
                />
              </Col>
            </Row>
            { data.fundingDelays.filter(fundingDelay => fundingDelay.status === 'Required').length > 0 &&
              <Row gutter={[16, 16]} className='mt-20'>
                <Col span={24}>
                  <Button type='primary' onClick={() => {generateFundingStatusEmail()}} disabled={disableFlag}>Generate Funding Status Email</Button>
                </Col>
              </Row>
            }
          </Card>
        </Col>
      </Row>
      
    </>
  );
}
import React from "react";
import CreditSummary from './CreditSummary';
import CreditReports from './CreditReports';
import BlackboxTable from './BlackboxTable';
import HistoricalRelatedCard from './HistoricalRelatedCard';
import TloReports from "./TloReports";

export default function Credit({ data, setLoading, save }){
  return (
      <>
        <HistoricalRelatedCard data={data}  setLoading={setLoading} save={save}/><br/>
        <CreditSummary data={data}  setLoading={setLoading} save={save}/><br/>
        <CreditReports data={data} setLoading={setLoading} /><br/>
        <BlackboxTable data={data} setLoading={setLoading} /><br/>
        <TloReports data={data} setLoading={setLoading} /><br/>
      </>
  );
}
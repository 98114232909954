import React, {useState} from "react";
import {Layout, message} from "antd";
import "../../App.css";
import {styled} from "@mui/system";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import DealerUserTable from "./components/DealerUserTable";
import moment from "moment";
import {Input, Col, Row} from "antd";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchDealerusers, updatePageState} from "../../reducers/DealerUsers";
import {handleNoAuth} from "../../util/authenticate";
import {DELETE, PUT} from "../../util/network";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogModal from "../LeaseApplications/DialogModal";

export default function DealerUserList({dealerData, allowedGet, total, pageState, setLoading}) {
  const {Content} = Layout;
  const {Search} = Input;
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchVal, setSearchVal] = useState(decodeURIComponent(pageState.search));
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState({type: '', status: ''});
  const [recordId, setRecordId] = useState();

  const handleGlobalSearch = (e) => {
    setSearchVal(e.target.value);
    if (e.target.value === '') {
      dispatch(updatePageState({search: e.target.value}))
    }
  };

  const onSearch = () => {
    dispatch(updatePageState({search: encodeURIComponent(searchVal)}))
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      flex: 1.5,
      minWidth: 75,
    },
    {
      field: "firstName",
      headerName: "First Name",
      type: "string",
      flex: 1.5,
      minWidth: 75,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
      flex: 1.2,
      minWidth: 66,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      flex: 1.5,
      minWidth: 75,
    },
    {
      field: "roleName",
      headerName: "Role",
      type: "string",
      flex: 1.5,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.roleName === 'sales_staff'  ? " Sales Staff"
          : record?.row?.roleName === 'sales_manager'   ? 'Sales Manager' 
          : record?.row?.roleName === 'service_manager' ? 'Service Manager'
          : record?.row?.roleName === 'finance_manager' ? 'Finance Manager'
          : record?.row?.roleName === 'title_clerk' ? 'Title Clerk'
          : record?.row?.roleName === 'dealer_principal' ? 'Dealer Principal'
          : record?.row?.roleName === 'other' ? 'Other' : ''
      },
    },
    {
      field: "dealershipName",
      headerName: "Dealership",
      type: "string",
      flex: 1.5,
      minWidth: 75,
    },
    {
      field: "signInCount",
      headerName: "Sign In Count",
      type: "string",
      flex: 1.5,
      minWidth: 75,
    },
    {
      field: "firstSignInAt",
      headerName: "First Sign In At",
      type: "string",
      flex: 1.5,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.firstSignInAt === null
          ? ""
          : moment(record.row.firstSignInAt).format("MMMM d, YYYY HH:mm:ss");
      },
    },
    {
      field: "lastSignInAt",
      headerName: "Last Sign In At",
      type: "string",
      flex: 1.5,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.lastSignInAt === null
          ? ""
          : moment(record.row.lastSignInAt).format("MMMM d, YYYY HH:mm:ss");
      },
    },
    {
      field: "internalUser",
      headerName: "Internal User Flag",
      type: "string",
      flex: 1.5,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.internalUser === false ? (
          <Chip label="No" color="primary" variant="outlined"/>
        ) : (
          <Chip label="Yes" color="warning" variant="outlined"/>
        );
      },
    },
    {
      field: "is_disabled",
      headerName: "Disabled",
      type: "string",
      flex: 0.5,
      minWidth: 75,
      renderCell: (record) => {
        const columnValue = record?.row?.isDisabled === false ? 'No' : 'Yes'
        return <>
          <Button
            style={{
              textTransform: "none",
              backgroundColor: columnValue === 'No' ? 'green' : '#FF5733',
              color: 'white',
              borderRadius: '25px'
            }}
            variant="text"
            onClick={() => {
              setOpen(true)
              setAction({type: 'statusUpdate', status: record?.row?.isDisabled})
              setRecordId(record?.row?.id)
            }}
          >
            {columnValue}
          </Button>
        </>
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "action",
      flex: 2.6,
      minWidth: 140,
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{textTransform: "none"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/dealer-users/${record.row.id}`,
                  state: {
                    actionType: "DETAILS",
                  },
                });
              }}
            >
              View
            </Button>

            <Button
              style={{textTransform: "none"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/dealer-users/${record.row.id}`,
                  state: {
                    actionType: "UPDATE",
                  },
                });
              }}
            >
              Edit
            </Button>

            <Button
              type="link"
              name="deleteButton"
              onClick={() => {
                setOpen(true)
                setAction({type: 'Delete', status: true})
                setRecordId(record?.row?.id)
              }}
            >
              <DeleteIcon style={{color: 'red'}}/>
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ];

  const closeDialog = () => {
    setOpen(false);
  }

  const completeTask = () => {
    action.type === 'statusUpdate' ? changeStatus() : completeDeletion();
    closeDialog();
  }

  const completeDeletion = async () => {
    setLoading(true);
    try {
      await DELETE(`/api/v1/dealer-users/${recordId}`);
      dispatch(fetchDealerusers({pageState: pageState}));
      message.success('Successfully deleted dealer user.');
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on dealer user deletion.');
      }
    }
    setLoading(false);
  }

  const changeStatus = async () => {
    setLoading(true);
    try {
      await PUT(`/api/v1/dealer-users/${recordId}/change_disable`, {
        is_disabled: action.status !== true
      });

      dispatch(fetchDealerusers({pageState: pageState}));
      message.success('Successfully changed status.');
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on status change.');
      }
    }
    setLoading(false);
  };

  return (
    <Layout id="content-area-layout">
      <Content
        id="main-content"
        style={{backgroundColor: "#ffff !important"}}
      >
        <Row style={{marginBottom: "1em"}}>
          <Col flex={4}>
            <h1>Dealer Users</h1>
          </Col>
          <Col style={{marginRight: '10px'}}>
            <Button style={{backgroundColor: "#e93b1b", color: 'white', border: 'none'}} onClick={() => {
              history.push({
                pathname: `/dealer-users/create`,
                state: {
                  actionType: "CREATE",
                },
              });
            }} type="primary">
              Create New Dealer User
            </Button>
          </Col>

          <Col>
            <Search
              name="globalSearch"
              placeholder="First Name or Last Name or Email or Dealership"
              size="large"
              onChange={handleGlobalSearch}
              value={searchVal}
              onSearch={onSearch}
            />
          </Col>
        </Row>
        <StyledBox>
          {dealerData && (
            <DealerUserTable
              dealerData={dealerData}
              columns={columns}
              total={total}
              pageState={pageState}
            />
          )}
        </StyledBox>
        <>
          <DialogModal
            modalState={open}
            modalClose={closeDialog}
            submitFunction={completeTask}
            contentData={""}
            title={action.type === 'statusUpdate' ? "Are you sure you want to change status?" : "Are you sure you want to delete this dealer user?"}
          />
        </>
      </Content>
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: "100%",
  "& .super-app-theme--header": {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
});

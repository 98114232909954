import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Form, Input, Select, Button, InputNumber, message } from "antd";
import { GET } from '../../util/network';
import logger from '../../util/logger';
import _ from "lodash";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default ({ data, disableFlag, handleNoAuth, save }) => {
    const history = useHistory();

    const { lessee } = data || {};
    const [employerForm] = Form.useForm();
    const [employmentDetailsForm] = Form.useForm();
    const [employerStateOptions, setEmployerStateOptions] = useState([])
    const [employmentStatusOptions, setEmploymentStatusOptions] = useState([])
    const [requireEmploymentFields, setRequireEmploymentFields] = useState(false)

    const getEmployerStates = async ( ) => {
      try {
        let result = await GET(`/api/v1/states`);

        setEmployerStateOptions(formatOptions({options: (result.data.states || []), type: 'collection'}))
      } catch (e) {
        logger.error("Request Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error getting state information');
        }
      }
    }
    useEffect(() => {
      getEmployerStates()
    }, []);


    const getEmployerStatus = async ( ) => {
      try {
        let result = await GET(`/api/v1/employment-status`);
        setEmploymentStatusOptions(formatOptions({options: (result.data.employment_status || []), type: 'collection'}))

        if (result.data && result.data.employment_status) {
          let currentStatus = _.find(result.data.employment_status, function(item) { return item[0] == lessee.employmentStatus; })
          if (currentStatus[1] == 0 || currentStatus[1] == 1) {
            setRequireEmploymentFields(true)
          }
        }
      } catch (e) {
        logger.error("Request Error", e);
      }
    }
    useEffect(() => {
      getEmployerStates()
      getEmployerStatus()
    }, []);


    const formatOptions = ({options, type}) => {
      let newOptions = []
      if (type == 'collection'){
        options.map((value) => { newOptions.push({ value: value[1], label: value[0]})})
      }else{
        options.map((value) => { newOptions.push({ value: value, label: value})})
      }
      return newOptions
    }

    const handleEmploymentStatus = (value) => {
      if (value === '0' || value === '1') {
        setRequireEmploymentFields(true)
      } else {
        setRequireEmploymentFields(false)
      }
    }

    const handleSubmit = async (values) => {
      values = { ...values };
      save(values)
  }

    return data ? (
        <>
          <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <Card className='h-100' title="Employer">
                  <Form 
                    form={employerForm} 
                    {...layout}  
                    colon={false}
                    initialValues={{ 
                      lesseeAttributes: {
                        employerName: lessee?.employerName,
                        employerPhoneNumber: lessee?.employerPhoneNumber,
                        employmentAddressAttributes: {
                          id: lessee?.employmentAddress?.id,
                          city: lessee?.employmentAddress?.city,
                          state: lessee?.employmentAddress?.state,
                        }
                      }
                    }}
                    onFinish={handleSubmit}
                  >
                    { 
                      lessee?.employmentAddress && <Form.Item style={{display: 'none'}} name={['lesseeAttributes', 'employmentAddressAttributes','id']} > <Input /> </Form.Item>
                    }
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Employer Name" 
                          name={['lesseeAttributes', 'employerName']}
                          hasFeedback
                          rules={[{ required: true, message: 'Employer Name is required!' }]}
                        >  
                          <Input disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Phone Number" 
                          name={['lesseeAttributes', 'employerPhoneNumber']}
                          hasFeedback
                          rules={[{ required: true, message: 'Employer Name is required!' }]}
                        >  
                          <Input disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="City" 
                          name={['lesseeAttributes', 'employmentAddressAttributes', 'city']}
                          hasFeedback
                          rules={[{ required: true, message: 'City is required!' }]}
                        >  
                          <Input disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                        <Col span={24}> 
                          <Form.Item 
                            label="State" 
                            name={['lesseeAttributes', 'employmentAddressAttributes','state']}
                            hasFeedback
                            rules={[{ required: true, message: 'State is required!' }]}
                          >  
                            <Select disabled={disableFlag}>
                              {
                                employerStateOptions && employerStateOptions.map(({value, label}, index) => {
                                  return <Option key={index} value={`${value}`}>{label}</Option>
                                })
                              }
                            </Select>
                          </Form.Item>
                        </Col> 
                      </Row>
                      <Row>
                        <Col span={24}> 
                          <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={disableFlag}>
                              Save
                            </Button>
                          </Form.Item>
                        </Col> 
                      </Row>
                  </Form>
                </Card>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <Card className='h-100' title="Employment Details">
                  <Form 
                    form={employmentDetailsForm} 
                    {...layout}  
                    colon={false}
                    initialValues={{ 
                      lesseeAttributes: {
                        employmentStatus: lessee?.employmentStatus,
                        jobTitle: lessee?.jobTitle,
                        timeAtEmployerMonths: lessee?.timeAtEmployerMonths,
                        timeAtEmployerYears: lessee?.timeAtEmployerYears,
                        grossMonthlyIncome: lessee?.grossMonthlyIncome,
                      }
                    }}
                    onFinish={handleSubmit}
                  >

                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Employment Status"
                          name={['lesseeAttributes','employmentStatus']}
                          hasFeedback
                          rules={[{ required: true, message: 'Employment Status is required!' }]}
                        >  
                          <Select onChange={handleEmploymentStatus} disabled={disableFlag}>
                            {
                              employmentStatusOptions && employmentStatusOptions.map(({value, label}, index) => {
                                return <Option key={index} value={`${value}`}>{label}</Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Job Title" 
                          name={['lesseeAttributes', 'jobTitle']}
                          hasFeedback
                          rules={[{ required: requireEmploymentFields, message: 'Job Title is required!' }]}
                        >  
                          <Input disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Years Employed" 
                          name={['lesseeAttributes', 'timeAtEmployerYears']}
                          hasFeedback
                          rules={[{ required: requireEmploymentFields, message: 'Years Employed is required!' }]}
                        >  
                          <InputNumber disabled={disableFlag} />
                        </Form.Item>
                      </Col> 
                    </Row>                    
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Months Employed" 
                          name={['lesseeAttributes', 'timeAtEmployerMonths']}
                        >  
                          <InputNumber disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Gross Monthly Income" 
                          name={['lesseeAttributes', 'grossMonthlyIncome']}
                          hasFeedback
                          rules={[{ required: requireEmploymentFields, message: 'Gross Monthly Income is required!' }]}
                        >  
                          <InputNumber disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                        <Col span={24}> 
                          <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={disableFlag}>
                              Save
                            </Button>
                          </Form.Item>
                        </Col> 
                      </Row>
                  </Form>
                </Card>
              </Col>
          </Row>
        </>
    ) : null;
};

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Spin, Layout, message, Menu, Collapse, Result } from 'antd';
import Icon, { InfoCircleOutlined, BankOutlined, RocketOutlined, ProfileOutlined } from '@ant-design/icons';
import { AttachmentSvg, CreditSvg, SalesSvg, UnderwritingSvg } from '../../components/layouts/Svg';
import { PUT } from '../../util/network';
import logger from '../../util/logger';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import Attachments from './Attachments/Attachments';
import Capabilities from './Capabilities/Capabilities';
import CreditCommitteeApproval from './CreditCommitteeApproval/CreditCommitteeApproval';
import Financial from './Financial/Financial';
import Organization from './Organization/Organization';
import SalesRecommendation from './SalesRecommendation/SalesRecommendation';
import Underwriting from './Underwriting/Underwriting';
import UnderwritingApproval from './UnderwritingApproval/UnderwritingApproval';
import AuditLog from './AuditLog/AuditLog.js';
import DealershipStatus from './DealerShipStatus/DealershipStatus'
import './Dealership.css';
import {Button} from "@material-ui/core";

const { Content, Sider } = Layout;
const { Panel } = Collapse;

export default function Dealership({ data, dealerRepresentatives, remitToDealerCalculation, loading, setLoading, getDealership, ...props }) {
  const {
    allowedCreditComApprovalGet,
    allowedCreditComApprovalUpdate,
    allowedGet,
    allowedSalesRecGet,
    allowedSalesRecUpdate,
    allowedUpdate,
    allowedUwApprovalGet,
    allowedUwApprovalUpdate,
    dealershipId,
    handleNoAuth,
  } = props;

  const history = useHistory();
  let hash = window.location.hash || null;
  const {id} = useParams();

  const dealershipsStatus =
    [
      { id: 1, Name: "New", value: "New" },
      { id: 2, Name: "Sales Approved", value: "Sales Approved" },
      { id: 3, Name: "Underwriting Approved", value: "Underwriting Approved" },
      { id: 4, Name: "Credit Committee Approved", value: "Credit Committee Approved" },
      { id: 5, Name: "Declined", value: "Declined" },
      { id: 6, Name: "Legacy Dealer – Can Sign In", value: "Active" },
      { id: 7, Name: "Active Dealer – Can Submit", value: "Can Submit" },
      { id: 8, Name: "Closed", value: "Closed" },
    ]

  let Menus = [];

  if (allowedGet) {
    Menus.push(
      {
        label: "Status",
        componentLabel: null,
        iconType: <InfoCircleOutlined />,
        Component: DealershipStatus ,
        menuType: "parent",
        isAllowedToShow: true,
        menuKey: 9,
      },
      {
        label: "Organization",
        componentLabel: null,
        iconType: <InfoCircleOutlined />,
        Component: Organization,
        menuType: "parent",
        isAllowedToShow: true,
        menuKey: 1,
      },
      {
        label: "Underwriting",
        componentLabel: null,
        iconType: <Icon component={CreditSvg} />,
        Component: Underwriting,
        menuType: "parent",
        isAllowedToShow: true,
        menuKey: 2,
      },
      {
        label: "Financial",
        componentLabel: null,
        iconType: <BankOutlined />,
        Component: Financial,
        menuType: "parent",
        isAllowedToShow: true,
        menuKey: 3,
      },
      {
        label: "Attachments",
        componentLabel: null,
        iconType: <Icon component={AttachmentSvg} />,
        Component: Attachments,
        menuType: "parent",
        isAllowedToShow: true,
        menuKey: 4,
      },
      {
        label: "Sales",
        componentLabel: "Sales Recommendation",
        iconType: <Icon component={SalesSvg} />,    
        Component: SalesRecommendation,
        menuType: "child",
        isAllowedToShow: true,
        menuKey: 5,
      },
      {
        label: "Underwriting",
        componentLabel: "Underwriting Approval",
        iconType: <Icon component={UnderwritingSvg} />,
        Component: UnderwritingApproval,
        menuType: "child",
        isAllowedToShow: true,
        menuKey: 6,
      },
      {
        label: "Credit Committee",
        componentLabel: "Credit Committee Approval",
        iconType: <RocketOutlined />,
        Component: CreditCommitteeApproval,
        menuType: "child",
        isAllowedToShow: true,
        menuKey: 7,
      },
      {
        label: "Capabilities",
        componentLabel: null,
        iconType: <ProfileOutlined />,
        Component: Capabilities,
        menuType: "parent",
        isAllowedToShow: true,
        menuKey: 8,
      },
      {
        label: "Audit Log",
        componentLabel: null,
        iconType: <ProfileOutlined />,
        Component: AuditLog,
        menuType: "parent",
        isAllowedToShow: true,
        menuKey: 10,
      }
    )
  }  

  const [activeMenu, setActiveMenu] = useState([1]);
  const activeMenuList =  Menus.filter(menu => menu.isAllowedToShow);
  const approvalMenuList =  Menus.filter(menu => menu.menuType === 'child');
  const mainMenuList =  Menus.filter(menu => menu.menuType === 'parent');

  const dealership = data && data.dealership ? data.dealership : null;  
  let dealershipName;

 if (dealership) {
    dealershipName = dealership.name ? dealership.name : dealership.id; 
  }  

  const save = async (values) => {
    if (allowedUpdate) {
      setLoading(true);
      try {
        await PUT(`/api/v1/dealerships/${dealershipId}`, values);
        message.success("Data saved successfully");   
        getDealership()
      } catch (e) {
        logger.error("Dealership Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data');
        }
      }
      setLoading(false);
    }
  };

  const removeCharacter = (char, value) => {
    value = value.replace(char, '');

    return value;
  }

  const handleSubmit = async (values) => {
    if (allowedUpdate) {
      if (values.employerIdentificationNumber) {
        let formattedEIN = removeCharacter('-', values.employerIdentificationNumber);
        
        if (formattedEIN) {
          values.employerIdentificationNumber = formattedEIN;
        }
        
        values = { ...values };
      }
      
      save(values);
    } else {
      message.warning("You are not allowed to edit this page"); 
    }
  }

  const handleUrlHash = () => {
    if(hash !== null)
    {
      let panelHash = hash.match(/#(.*?)-p$/)
      if(panelHash && panelHash[1])
      {
        let menu = Menus.find((menuItem) => menuItem.label === panelHash[1].replace(/%20/g, ' ')) || null;
        if (menu !== null)
        {
          let x = document.getElementById('item-'+menu?.menuKey);
          x.click();
          setActiveMenu([menu?.menuKey])
        }
      }
    }
  }

  useEffect(() => {
    handleUrlHash()
  }, []);

  return (
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Layout>
        <MainHeader activePage="dealers"/>
        <Layout name='Dealership Edit' id='#dealership-edit'>
          <Sider {...SiderProps} >
            <Menu
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu]}
              style={{ width: '100%' }}
              onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
            >
              {
                mainMenuList.map(({ label, iconType, menuKey, isAllowedToShow }) => {
                  return (                    
                    !isAllowedToShow ? null : (
                      <Menu.Item
                        key={(menuKey).toString()}
                      > 
                        <a href={`#${label}-p`} id= {'item-'+menuKey}>
                          {iconType}
                          {label}
                        </a>
                      </Menu.Item>
                    )
                  )
                })
              }              
              <Menu.Divider />              
              <Menu.ItemGroup title='Approvals'>
                {
                  approvalMenuList.map(({ label, iconType, menuKey, isAllowedToShow }) => {
                    return (                      
                      !isAllowedToShow ? null : (
                        <Menu.Item
                          key={(menuKey).toString()}
                        > 
                          <a href={`#${label}-p`} id= {'item-'+menuKey}>
                            {iconType}
                            {label}
                          </a>
                        </Menu.Item>
                      )
                    )
                  }) 
                }
              </Menu.ItemGroup>           
            </Menu>
          </Sider>

          <Layout id='content-area-layout'>
            <MainBreadcrumb items={
              [{ text: "Home", link_type: "linkto", link: "/dashboard" },
              { text: "Dealerships", link_type: "linkto", link: "/dealerships" },
              { text: `${dealershipName}` }]
              }
            />

            <Content id='main-content'>
              <Row gutter={[0, 24]} >
                <Button
                  color="secondary"
                  sx={{ fontSize: "10px", margin: 1, padding: "5px 6px" }}
                  variant="outlined"
                  onClick={(event) => {
                    history.push("/dealership/" + id + "/activities");
                  }}
                >
                  Dealer Activity
                </Button>
                <Col span={24} >
                  <>
                    {
                    allowedGet
                      ?
                        <Collapse data={data} accordion={false} onChange={(key) => { key !== undefined && setActiveMenu(key); }} activeKey={activeMenu}>
                          {
                            activeMenuList.map(({ label, componentLabel, Component, menuKey, collapseRef }) => {
                              label = componentLabel ? componentLabel : label;
                              return (                          
                                <Panel 
                                  className='slc-panel'
                                  header={ label } 
                                  key={menuKey} 
                                  ref={collapseRef} 
                                  id={`${label}-p`}
                                >
                                <Component 
                                  dealership={ dealership }
                                  dealershipId={ dealershipId }
                                  dealerRepresentatives={ dealerRepresentatives }
                                  getDealership={getDealership} 
                                  handleSubmit={ handleSubmit }
                                  allowedCreditComApprovalGet={allowedCreditComApprovalGet}
                                  allowedCreditComApprovalUpdate={allowedCreditComApprovalUpdate}
                                  allowedGet={allowedGet}
                                  allowedSalesRecGet={allowedSalesRecGet}
                                  allowedSalesRecUpdate={allowedSalesRecUpdate}
                                  allowedUpdate={allowedUpdate}
                                  allowedUwApprovalGet={allowedUwApprovalGet}
                                  allowedUwApprovalUpdate={allowedUwApprovalUpdate}
                                  remitToDealerCalculation={remitToDealerCalculation}
                                  setLoading={setLoading}
                                  handleNoAuth={handleNoAuth}
                                  dealershipsStatus={dealershipsStatus}
                                  />
                                </Panel>
                              )
                            })
                          }
                        </Collapse>
                      :
                        <Result 
                          status='warning'
                          title='You are not allowed to access this page.'
                        /> 
                    }
                  </>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter/>
      </Layout>
    </Spin> 
  );
}
import React from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PreviewIcon from '@mui/icons-material/Preview';
import { styled, alpha } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import LockUnlock from './LockUnlock';
import PublishIcon from '@mui/icons-material/Publish';
import Badge from '@material-ui/core/Badge';
import ApprovalTwoToneIcon from '@mui/icons-material/ApprovalTwoTone';
import Tooltip from '@mui/material/Tooltip';

export default function ActionsButton(props) {
    const history = useHistory();
    const { params, slcRow, open, anchorEl, submitToSpeedLeasing, handleClose2, handleClickOpen, lockApplication, applicationLockedBy, setApplicationlockedBy, actionColors, handleClick,
        handleTransferOwnershipClick, setAnchorEl } = props
    let auth_token = window.localStorage.getItem('auth_token');
    const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    if (authData !== null) {
        loggedInUserId = authData.id;
    }
    const losPath = (path) => {
        return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
    }
    return (
        <>
            <Badge badgeContent={params.row.unseenAttachmentsCount > 0 ?
              <Tooltip title={<p2>Stipulations</p2>}>
                  <ApprovalTwoToneIcon style={{ color: "#e93b1b", fontSize: '20px' }}></ApprovalTwoToneIcon>
              </Tooltip> : <></>
            }>
                <StyledButton
                    id={params.row.id}
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    onClick={(e) => { handleClick(e, params.row); setApplicationlockedBy(params.row.lockedStatus) }}
                    endIcon={<KeyboardArrowDownIcon />}
                    color={actionColors(params)}
                    size="small"
                >
                    {params.row.lockedStatus.status ? params.row?.lockedStatus?.lockedByUserFirstName : 'Actions'}
                </StyledButton>
            </Badge>

            <StyledMenu
                id={params.row.id}
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose2}
                keepMounted
            >
                <MenuItem onClick={(event) => { history.push('/lease-applications/' + slcRow.id); }} disableRipple >
                    <PreviewIcon sx={{ marginRight: 1 }} />
                    View Credit Application
                </MenuItem>
                <LockUnlock
                    applicationLockedBy={applicationLockedBy}
                    loggedInUserId={loggedInUserId}
                    handleClickOpen={handleClickOpen}
                    slcRow={slcRow}
                    lockApplication={lockApplication}
                    handleTransferOwnershipClick={handleTransferOwnershipClick}
                    setAnchorEl={setAnchorEl}
                />
                {/* <MenuItem onClick={() => { history.push('/lease-applications/' + slcRow.id); }} disableRipple>
                    <ArticleIcon sx={{ marginRight: 1 }} />
                    Edit Credit Application
                </MenuItem> */}
                <MenuItem onClick={() => { location.href = losPath(`/admins/lease_applications/${slcRow.id}/verification`) }} disableRipple>
                    <VerifiedUserIcon sx={{ marginRight: 1 }} />
                    Lease Verification
                </MenuItem>

                <MenuItem onClick={() => { location.href = losPath(`/admins/lease_calculators/${slcRow.LeaseCalculatorId}/edit`) }} disableRipple>
                    <EditIcon sx={{ marginRight: 1 }} />
                    Edit Payment Calculator
                </MenuItem>
                {slcRow != null && slcRow.CreditStatus == "Unsubmitted" &&
                    <MenuItem onClick={() => {
                        submitToSpeedLeasing(slcRow.id)
                        setAnchorEl(null)
                    }} disableRipple>
                        <PublishIcon sx={{ marginRight: 1 }} />
                        Submit to speed Leasing
                    </MenuItem>

                }
            </StyledMenu>
        </>
    )
}

const StyledButton = styled((props) => (
    <Button
        {...props}
    />
))(({ theme }) => ({
    fontSize: "10px !important",
    padding: "6px 7px !important",
    width: '140px !important',
    margin: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
        minWidth: 32,
        paddingLeft: 8,
        paddingRight: 8,
        "& .MuiButton-startIcon": {
            margin: 0
        }
    }
}))


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    fontSize: 12,
    '& .MuiPaper-root': {
        borderRadius: 5,
        minWidth: 180,
        boxShadow: '0px 0px 2px 0px #d9d9d9',
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 16,
                color: theme.palette.text.secondary,
                transition: "color 1s"
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
            '&:hover': {
                backgroundColor: "#cdc9c933",
                color: "#e93b1b",
                lineHeight: 1.5,
                textDecoration: 'underline',
                textUnderlineOffset: '8px',
                textDecorationcolor: '#e93b1b',
                textDecorationThickness: "2px",
                fontWeight: "500"
            }
        },
    },
}));
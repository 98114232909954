import React from 'react'
import { Button, Col, Select, Upload, Form, Input, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
const Option = Select.Option;
export default function PromotionForm(props) {
    const { allowedGet, allowedUpdate, layout, form, handleSubmit, getFile, dealerShips } = props
    return (
        <>

            <Form
                {...layout}
                id='banner-message'
                form={form}
                colon={false}
                onFinish={handleSubmit}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                        <Row className='flex-jc-fs'>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Row className='flex-jc-fs'>
                                    <Form.Item
                                        label='Title'
                                        name='title'
                                        style={{ width: '90%' }}
                                        hasFeedback
                                        rules={[{
                                            required: true,
                                            message: 'Title is required'
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Row className='flex-jc-fs'>
                                    <Form.Item
                                        label="Image"
                                        name='image'
                                        getValueFromEvent={getFile}
                                        rules={[{
                                            required: true,
                                            message: 'Image is required'
                                        }]}
                                    >
                                        <Upload maxCount={1}>
                                            <Button icon={<UploadOutlined />}>Upload Image</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        label="File"
                                        name='file'
                                        getValueFromEvent={getFile}
                                        rules={[{
                                            required: true,
                                            message: 'File is required'
                                        }]}
                                    >
                                        <Upload maxCount={1} accept=".pdf">
                                            <Button icon={<UploadOutlined />}>Upload File</Button>
                                        </Upload>
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='flex-jc-fs'>
                            <Col span={24}>
                                <Form.Item
                                    label="Dealers"
                                    name="dealer"
                                    rules={[{
                                        required: true,
                                        message: 'Dealers is required'
                                    }]}
                                >
                                    <Select
                                        allowClear
                                        mode="multiple"
                                        placeholder="Select  dealership"
                                        filterOption={(inputValue, option) =>
                                            option.props.children
                                                .toString()
                                                .toLowerCase()
                                                .includes(inputValue.toLowerCase())
                                        }
                                        showSearch
                                    >
                                        <Option key={0} value={0}>{"All"}</Option>
                                        {dealerShips && dealerShips.map(option => (
                                            <Option key={option.id} value={option.id}>{option.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {allowedUpdate &&
                            <Row className='flex-jc-fs'>
                                <Col xs={24} sm={24} md={24} lg={8} xl={6}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Form>

        </>
    )
}

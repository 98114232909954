import React from "react";
import {message, Table} from "antd";
import moment from 'moment';
import {PUT} from "../../../util/network";
import {markNotificationByIdAsRead} from "../../../reducers/Notifications";

const columns = [
  {
    title: 'File Name',
    dataIndex: 'file',
    key: 'file',
  },
  {
    title: 'Recipient',
    dataIndex: 'recipient',
    key: 'recipient',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Download Link',
    dataIndex: 'downloadLink',
    key: 'downloadLink',
    render(val) {
      if(val){
        if (process.env.NODE_ENV === 'development'){
          return <a href={`${process.env.REACT_APP_BASE_URL}/${val}`} target="_blank" rel="noreferrer">{'Download'}</a>
        }
        else{
          return <a href={`${val}`} target="_blank" rel="noreferrer">{'Download'}</a>
        }
      }
      else{
        return <a>{'Download'}</a>
      }
    }
  },
  {
    title: 'Resend',
    dataIndex: 'resend',
    key: 'resend',
    render(val) {
      if(val){
        return <a onClick={(e) => {
          e.stopPropagation();
          callResendApi(val);
        }} rel="noreferrer">{'Resend'}</a>
      }
      else{
        return <a>{'Resend'}</a>
      }
    }
  },
  {
    title: 'Originally Sent At',
    key: 'originallySentAt',
    dataIndex: 'originallySentAt',
    render(val) {
      if(val){
        return moment(val).format("MMMM DD, YYYY hh:mm:ss") ;
      }
      else{
        return ''
      }
    }
  },
  {
    title: 'Last Sent At',
    key: 'lastSentAt', 
    dataIndex: 'lastSentAt',
    render(val) {
      if(val){
        return moment(val).format("MMMM DD, YYYY hh:mm:ss") ;
      }
      else{
        return ''
      }
    }
  },
];

const callResendApi = async (id) => {
  try {
    await PUT(`/api/v1/notifications/resend`, { id: id });
    message.success("This attachment will now be resent.")
  } catch (e) {
    message.error("Error while marking notifications as read")
  }
}
export default function notificationEmails({ data }) {
  const dataSource = data.notificationEmails || []
  return dataSource ? (
      <Table className='slc-table' rowKey="id" columns={columns} dataSource={dataSource} size="small" pagination={true}/>
  ) : null;
}

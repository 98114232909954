import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Form,
  Layout,
  Menu,
  message,
  Row,
  Select,
  Spin,
  DatePicker,
} from "antd";
import Icon from "@ant-design/icons";
import { GET, POST, PUT } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { AddNew } from "../../components/layouts/Svg";
import { GetResourceActions, IsActionAllowed } from "../../util/AllowedActions";
import moment from 'moment';

const { Content, Sider } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function UpdateLessee(props) {
  const { id } = props.match.params;
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory();
  const adminUserActions = GetResourceActions("AdminUser", allowedActions);
  const allowedCreate = IsActionAllowed("create", adminUserActions);
  const [modelGroupForm] = Form.useForm();
  const [data, setData] = useState(null);
  const [makeOptions, setMakeOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLessee = async () => {
    setLoading(true)
    try {
      const response = await GET(`/api/v1/lessees/${id}`);
      setData(response?.data.data);
    } catch (e) {
      message.error("Error while loading Lessee.")
    }
    setLoading(false)
  }

  const save = async (values) => {
   {
      setLoading(true);
      try {
        await PUT(`/api/v1/lessees/${id}`, values)
          .then((response) => {
            message.success("Saved successfully");
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              message.error("Error while saving!");
              handleNoAuth(history);
            } else {
              message.error("Error while saving!");
            }
          });
      } catch (e) {
        logger.error("Error while saving!", e);
      }

      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    values = { ...values };
    save(values);
  };

  useEffect(() => {
    getLessee();
  }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
    { data &&
        <Layout>
        <MainHeader activePage="lesseeRelated" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Edit Lessee
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                {text: "Lessee Related", link_type: "ahref", link: void 0},
                {
                  text: "Lessee",
                  link_type: "ahref",
                  link: "/lessee-list",
                },
                { text: "Edit Lessee", link_type: "ahref", link: void 0 },
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <>
                    <Form
                      form={modelGroupForm}
                      {...layout}
                      colon={false}
                      onFinish={handleSubmit}
                      initialValues={{
                        first_name: data.firstName,
                        middle_name: data.middleName,
                        last_name: data.lastName,
                        suffix: data.suffix,
                        email_address: data.emailAddress,
                        ssn: data.ssn,
                        date_of_birth: moment(data.dateOfBirth),
                        mobile_phone_number: data.mobilePhoneNumber,
                        home_phone_number: data.homePhoneNumber,
                        drivers_license_id_number: data.driversLicenseIdNumber,
                        drivers_license_state: data.driversLicenseState,
                        employment_details: data.employmentDetails
                      }}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Card title="Lessee edit">
                            <Row gutter={[24, 16]}>
                              <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                <Form.Item
                                  label="First Name"
                                  name="first_name"
                                  hasFeedback
                                  messageVariables={{
                                    name: "first name",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Last Name"
                                  name="last_name"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Asset Year",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Middle Name"
                                  name="middle_name"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Asset Vin",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Suffix"
                                  name="suffix"
                                  hasFeedback
                                  messageVariables={{
                                    name: "suffix",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Email Address "
                                  name="email_address"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Exact odometer mileage",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="SSN"
                                  name="ssn"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Asset Color",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Date of birth"
                                  name="date_of_birth"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Asset Color",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <DatePicker />
                                </Form.Item>
                                <Form.Item
                                  label="Mobile Phone Number"
                                  name="mobile_phone_number"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Asset Color",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Home Phone Number"
                                  name="home_phone_number"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Asset Color",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="License Id Number"
                                  name="drivers_license_id_number"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Asset Color",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Drivers License State"
                                  name="drivers_license_state"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Asset Color",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Employment Details"
                                  name="employment_details"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Asset Color",
                                  }}
                                  rules={[{ required: false }]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]} className="mt-20">
                        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Button type="primary" htmlType="submit">
                                  Update
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    }
    </Spin>
  );
}
import React, {useState, useEffect} from "react";
import {message, Spin} from "antd";
import {GET} from "../../util/network";
import FundingDelaysList from "./FundingDelaysList";

export default function FundingDelaysRenderer(props) {
  const {handleNoAuth} = props;
  let auth_token = window.localStorage.getItem('auth_token');

  const [loading, setLoading] = useState(false);
  const [pageState, setPageState] = useState({page: 0, limit: 25});
  const [response, setResponse] = useState({data: [], total: 0});

  const getFundingDelays = async () => {
    setLoading(true);
    try {
      const response = await GET(
        `/api/v1/funding-delays?page=${pageState.page}&limit=${pageState.limit}`
      );
      setResponse(response?.data);
    } catch (e) {
      message.error("Error while loading model group.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getFundingDelays();
  }, [pageState]);

  return (
    <Spin spinning={loading}>
      <FundingDelaysList
        data={response.fundingDelays}
        loading={loading}
        pageState={pageState}
        setPageState={setPageState}
        total={response.total}
      />
    </Spin>
  );
}

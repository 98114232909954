const user_data = window.localStorage.getItem('user_data');
const userData = user_data ? JSON.parse(user_data) : null;
const allowedActions = userData && userData.user_permissions ? userData.user_permissions : null; 

export const AllowedActions = () => {
  return allowedActions;
}

export const GetActionPath = (actionName, allowedActions) => {
  if (actionName && allowedActions) {

    const action = allowedActions.find(o => o.name === actionName);    
    const actionPath = action && action.actionUrl ? action.actionUrl : null;
     
    return actionPath;
  } else {
    return false;
  }
}

export const GetResourceActions = (resourceName, allowedActions) => {
  if (resourceName && allowedActions) {
    const resourceActions = allowedActions 
      ? 
        allowedActions
          .filter(actions => actions.resource == resourceName)
          .map(resources => resources.actions)
          .flat()
      : 
        null

    return resourceActions;
  } else {
    return false;
  }
}

export const IsActionAllowed = (actionName, allowedActions) => {
  if (actionName && allowedActions) {
    const isAllowed = allowedActions.some(item => item == actionName);

    return isAllowed;
  } else {
    return false;
  }
}

export default AllowedActions;
import React from "react";
import { Row, Col, Table } from "antd";

let auth_token = window.localStorage.getItem('auth_token')

export default function NegativePay({ data }) {
    const dataSource = data ? data.leaseDocumentRequests : []
    const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    if(authData !== null) {
        loggedInUserId = authData.id;
    }
    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = locked_by != loggedInUserId ? true : false

    const columns = [
        {
            title: 'Year',
            dataIndex: 'assetYear',
            key: 'assetYear'
        },
        {
            title: 'Make',
            dataIndex: 'assetMake',
            key: 'assetMake'
        },
        {
            title: 'Model',
            dataIndex: 'assetModel',
            key: 'assetModel'
        },
        {
            title: 'VIN',
            dataIndex: 'assetVin',
            key: 'assetVin'
        },
        {
            title: 'Color',
            dataIndex: 'assetColor',
            key: 'assetColor'
        },
        {
            title: 'VIN Verification Status',
            dataIndex: 'vinVerified',
            key: 'vinVerified'
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'id',
            render(val) {
                if(!disableFlag){
                    return <a href={`/lease_document_request/${val}`}>View</a>
                }
                else{
                    return <a>View</a>
                }
            }
        },
    ];

    return (
        <Row>
            <Col span={24}>
                <Table className='slc-table' rowKey = "id" columns={columns} dataSource={dataSource} pagination={true} />
            </Col>
        </Row>
    );
}
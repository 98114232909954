import React, { Component } from 'react';
import Axios from 'axios';
import MainHeader from '../../components/layouts/MainHeader';
import Notifications from './Notifications';
import './AllNotifications.css';

class AllNotifications extends Component {
    constructor(props) {
        super(props);
    }

    handleNotificationClick = (id, url) => {
        let auth_token = window.localStorage.getItem('auth_token')
        let base_url = process.env.REACT_APP_BASE_URL
        Axios.put(`${base_url}/api/v1/notifications/mark_as_read`, {id: id},
        { 'headers': { 'Authorization': ` Token ${auth_token}` } }).then(() => {
            window.location = url
        });
    }

    markAllAsReadHandler=()=>{
        let auth_token = window.localStorage.getItem('auth_token');
        let base_url = process.env.REACT_APP_BASE_URL;
        Axios.put(`${base_url}/api/v1/notifications/mark_all_read`,'',
        { 'headers': { 'Authorization': ` Token ${auth_token}` } }).then(response => {         
            if(response.data.status=== 200){
                this.getReadNotifications(1);
                this.getUnReadNotifications();
            }
           this.setState({
            unReadNotificationCount:0
           })
        });
    }

     calculateNoOfDays = 
     (createdDate) => {
        const oneMonth = 30 * 24 * 60 * 60 * 1000;
        const today = new Date();
        const secondDate = new Date(createdDate);
        const noOfMonths = Math.floor(Math.abs((today - secondDate) / oneMonth));
        if (noOfMonths >= 1) {
          return `about ${noOfMonths} ${noOfMonths ===1 ? ' month':' months'}  ago`;
        } else {
  
          const oneDay = 24 * 60 * 60 * 1000;
          const noOfDays = Math.floor(Math.abs((today - secondDate) / oneDay));
  
  
          if (noOfDays >= 1) {
            return `${noOfDays} ${noOfDays === 1 ? 'day' : 'days'} ago`
          } else {
            const oneHour = 60 * 60 * 1000;
            const noOfHours = Math.floor(Math.abs((today - secondDate) / oneHour));
            if (noOfHours > 1) {
              return `${noOfHours} ${noOfHours ===1 ? 'hour':'hours' } ago`
            } else {
              const oneMinute = 60 * 1000;
              const noOfMinutes = Math.floor(Math.abs((today - secondDate) / oneMinute));
              if (noOfMinutes > 1) {
                return `${noOfMinutes} ${noOfHours ===1 ? 'minute':'minutes' } ago`
  
              } else {
                return 'Few seconds ago'
              }
            }
          }
        }
      }

       renderNotifications = (notifications)=>{
        let notificationsSection = [];
        notifications.forEach((val,index)=>{
            notificationsSection.push(
            <div key={index} className={index%2 ? "even":"odd"} onClick={(e) =>
                {e.preventDefault(); this.handleNotificationClick(val.id, val.url)}}>
                <div className="col">
                    <li className="dropdown-item">
                        <a href={val.url} id={index}>
                            <span className="content">
                                {val.notification_content}
                            </span>
                        </a>
                        <span className="time">{this.calculateNoOfDays(val.created_at)}</span>
                    </li>
                </div>
            </div>
        ) });
        return notificationsSection;
    }
    getNotifications=(notificationType,pageNo)=>{
        let auth_token = window.localStorage.getItem('auth_token');
        let base_url = process.env.REACT_APP_BASE_URL;
        Axios.get(`${base_url}/api/v1/notifications/get_all_notifications?${notificationType}=${pageNo}`,
        { 'headers': { 'Authorization': ` Token ${auth_token}` } }).then(response => {
            let notifications= []; 
            if(notificationType === 'unread_page'){
                notifications = response.data.unread.entries;
                this.setState({
                    unReadNotifications:this.renderNotifications(notifications),
                    unReadNotificationsPaginationData:response.data.unread.pagination
                });
            }else if(notificationType === 'read_page'){
                notifications = response.data.read.entries;
                this.setState({
                    readNotifications:this.renderNotifications(notifications),
                    readNotificationsPaginationData:response.data.read.pagination
                });
            }

             
        });
    }

    getUnReadNotifications=(pageNo)=>{
        this.getNotifications('unread_page',pageNo);
    }

    getReadNotifications=(pageNo)=>{
        this.getNotifications('read_page',pageNo);
    }

    componentDidMount(){
        this.getUnReadNotifications(1);
        this.getReadNotifications(1);
    }

    render() {
        const {unReadNotifications=[],unReadNotificationsPaginationData,
            readNotifications=[],readNotificationsPaginationData,unReadNotificationCount} =this.state || {};
        return (
            <>
                <MainHeader activePage="home"/>
                <section className='sub-header'>
                    <div className='title-bar-left'>
                        <div className='breadcrumb'>
                            <a href='/admins'>ADMINS</a>&nbsp;                            
                            <span className='breadcrumb-sep'>/</span>                            
                        </div>
                        <p></p>
                        <h2 className='page-title'>All Notifications</h2>
                    </div>
                    <div className='title-bar-right'>
                        <span className='action-items'>
                            <a className='mark-all-read-btn'onClick={this.markAllAsReadHandler}>Mark All Read</a>
                        </span>
                    </div>
                </section>
                <main className='main'>
                    <section className='panel'>
                        <h3>Unread Notifications</h3>
                        <div className='panel-contents'>
                            {this.state? <Notifications notifications={unReadNotifications} 
                                paginationData={unReadNotificationsPaginationData}
                                handlePagination={this.getUnReadNotifications}
                                pageType='unread_page'/> :''}

                        </div>
                    </section>
                    <section className='panel'>
                        <h3>Read Notifications</h3>
                        <div className='panel-contents'>
                            {this.state? <Notifications notifications={readNotifications}
                                paginationData={readNotificationsPaginationData}
                                handlePagination={this.getReadNotifications}
                                pageType='read_page'/> :''}
                        </div>
                    </section>
                </main>
            </>
        );
    }
}

export default AllNotifications;
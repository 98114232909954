import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {
  Layout,
  Menu,
  Spin,
} from "antd";
import {styled} from '@mui/material'
import {Table, TableBody, TableContainer, TableRow} from '@mui/material'
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import {SiderProps} from "../../components/layouts/MainSiderProps";
import Box from '@mui/material/Box';
import {AddNew} from "../../components/layouts/Svg";
import Icon from "@ant-design/icons";

const {Content, Sider} = Layout;

export default function FundingDelayView(props) {
  const {data} = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{width: "100%"}}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Funding Delay View
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                {text: "Home", link_type: "linkto", link: "/dashboard"},
                {text: "Lease Application Related", link_type: "ahref", link: void 0},
                {
                  text: "Funding Delay",
                  link_type: "ahref",
                  link: "/funding-delays-list",
                },
                {text: "Details", link_type: "ahref", link: void 0},
              ]}
            />

            {data && (
              <Box sx={{flexGrow: 1}}>
                <Grid container direction="row" style={{height: "98vh"}}>
                  <Grid item md={16} container spacing={2}>
                    <Grid item md={12} sm={9} lg={6}>
                      <TableContainer component={Box}>
                        <Table aria-label="customized table">
                          <TableBody>
                            <StyledTableRow>
                              <StyledtableCell1>ID</StyledtableCell1>
                              <StyledtableCell2>{data.id}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Lease Application Id</StyledtableCell1>
                              <StyledtableCell2>
                                <a onClick={() => {
                                  history.push('/lease-applications/' + data.lease_application_id);
                                }}>{data.lease_application_identifier}</a>
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Funding Delay Reason</StyledtableCell1>
                              <StyledtableCell2>{data.funding_delay_reason}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Status</StyledtableCell1>
                              <StyledtableCell2>{data.status}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Created At</StyledtableCell1>
                              <StyledtableCell2>{data.created_at}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Updated At</StyledtableCell1>
                              <StyledtableCell2>{data.updated_at}</StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Note</StyledtableCell1>
                              <StyledtableCell2>{data.notes}</StyledtableCell2>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  )
}

const StyledtableCell1 = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "15px",
  width: "200px",
})

const StyledTableRow = styled(TableRow)({
  "& td": {border: 0, padding: 15},
})

const StyledtableCell2 = styled(TableCell)({
  fontSize: "15px"
})

import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {GET} from '../util/network';

export const fetchDealerusers = createAsyncThunk('dealers/fetchDealerusers', async (params) => {
  let response;
  if (params.pageState !== undefined) {
    let encodedParam = Object.entries(params.pageState).map(([key, val]) => (key !== 'dealer') && `${key}=${val}`).join('&');
    response = await GET(`/api/v1/dealer-users?` + encodedParam);
  } else {
    response = await GET(`/api/v1/dealer-users`);
  }

  return {data: response?.data} || {};
})

export const fetchDealershipOptions = createAsyncThunk(
  "dealers/fetchDealershipOptions",
  async () => {
    const response = await GET(`/api/v1/dealerships/dealerships-options`);
    return response.data.dealerships_options || [];
  }
);

const initialState = {
  dealerList: [],
  pageState: {
    total: 0,
    page: 0,
    limit: 50,
    search: "",
  },
  isLoading: false,
  success: true,
  dealerListOptions: [],
};

export const dealerUsersSlice = createSlice({
  name: "dealerUsers",
  initialState,
  reducers: {
    updatePageState: (state, action) => {
      return {
        ...state,
        pageState: {
          ...state.pageState, ...action.payload
        },
        isNewAppTimer: false,
      }
    },
    getDealershipOptions: (state, action) => {
      state.dealerListOptions = action.payload.data;
      return state;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchDealerusers.fulfilled, (state, action) => {
      state.isLoading = true;
      state.dealerList = action.payload.data;
      state.isLoading = false;
      return state;
    }),
      builder.addCase(fetchDealerusers.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchDealerusers.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload;
      });

    builder.addCase(fetchDealershipOptions.fulfilled, (state, action) => {
      state.dealerListOptions = action.payload;
      return state;
    }),
      builder.addCase(fetchDealershipOptions.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchDealershipOptions.rejected, (state, action) => {
        state.success = false;
        state.error = action.payload;
      });
  }
});

export const {updatePageState, getDealershipOptions} = dealerUsersSlice.actions;
export default dealerUsersSlice.reducer;

import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { updateFilterState} from '../../../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";

export default function AssetsGroup(props) {
    const { filterDefaultData, filterState, newFilterState, setNewFilterState } = props;

    return (
        <>
            <ListItem >
                <StyledAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    value={newFilterState.assetMake}
                    options={filterDefaultData.make}
                    getOptionLabel={option => option}
                    renderInput={(params) => <TextField   {...params} size="small" label="Make" />}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, assetMake: value })
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOptionBox>{option}</StyledOptionBox>
                        </li>
                    )}
                />
            </ListItem>
            <ListItem >
                <StyledAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    value={newFilterState.calculatorStatus}
                    options={filterDefaultData.newUsed}
                    getOptionLabel={option => option}
                    renderInput={(params) => <TextField   {...params} size="small" label="New / Used" />}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, calculatorStatus: value })
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOptionBox>{option}</StyledOptionBox>
                        </li>
                    )}
                />
            </ListItem>
            <ListItem >
                <StyledAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    value={newFilterState.assetMileageTier}
                    options={filterDefaultData.milageTier}
                    getOptionLabel={option => option}
                    renderInput={(params) => <TextField   {...params} size="small" label="Mileage Tier" />}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, assetMileageTier: value })
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOptionBox>{option}</StyledOptionBox>
                        </li>
                    )}
                />
            </ListItem>
            <ListItem >
                <StyledAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    value={newFilterState.assetCreditTier}
                    options={filterDefaultData.creditTier}
                    getOptionLabel={option => option}
                    renderInput={(params) => <TextField   {...params} size="small" label="Credit Tier" />}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, assetCreditTier: value })
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOptionBox>{option}</StyledOptionBox>
                        </li>
                    )}
                />
            </ListItem>
            <ListItem>
                <StyledTextField
                    label="Asset Vin"
                    id="outlined-size-small"
                    size="small"
                    value={newFilterState.assetVin}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, assetVin: event.target.value })
                    }}
                />
            </ListItem>
        </>
    )
}

const StyledTextField = styled(TextField)({
    width: 350,
    marginLeft: "1px"
})

const StyledAutoComplete = styled(Autocomplete)({
    width: 350,
    marginLeft: 1
});

const StyledOptionBox = styled(Box)({
    "&:hover": {
        color: "#e93b1b",
        lineHeight: 1.5,
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationcolor: '#e93b1b',
        textDecorationThickness: "2px",
        fontWeight: "500"
    },
    "&:focus": {
        borderColor: "#ef4829a8 !important"
    },
});

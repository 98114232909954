import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@mui/material/Grid';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from '@mui/material/Avatar';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Textarea from '@mui/joy/Textarea';
import { styled } from '@mui/system';
import ReplyIcon from '@mui/icons-material/Reply';

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

const useStylesCardSubheader = makeStyles(({
  subAlignment: {
    color: 'black',
    textAlign: 'right',
    fontWeight: 500,
    fontSize: '12px'
  }
}));

const useStylesCardContent = makeStyles(({
  content: {
    marginTop: '-2%',
    marginLeft: '-3%',
    marginRight: '-3%'
  }
}));

const useStylesCardContentReply = makeStyles(({
  content: {
    marginTop: '-3%',
    marginBottom: '-3%',
    marginLeft: '-3%',
    marginRight: '-3%'
  }
}));

const ReadMore = (props) => {
  const { text } = props;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const readMoreSpan = () => {
    if(text && text.length > 150){
      return <span onClick={toggleReadMore} style={{ color: 'blue' }}>
                {isReadMore ? "...Read more" : " Show less"}
            </span>
    }
  }

  return (
    <p style={{ marginLeft: '66px', fontSize: "14px", flex: 1, overflow: "auto", width: '90%', marginBottom: '10px' }}>
      {isReadMore ? text && text.slice(0, 150) : text}
      { readMoreSpan() }
    </p>
  );
};

export default function NotesDialogModal(props) {
  const classes = useStyles();
  const classesSubheader = useStylesCardSubheader()
  const classesCardContent = useStylesCardContent()
  const classesCardContentReply = useStylesCardContentReply()
  const { unlockDialog, handleClose, notesData, addNote, saveEditedNote, deleteNote, addReply, data, paramsRow, deleteNoteReply,
          setVisibleToDealers, handleVisibility } = props;
  const [newNote, setNewNote] = useState('');
  const authData =  JSON.parse(`${window.localStorage.getItem('user_data')}`)
  const [editedNotes, setEditedNotes] = useState([])
  const [repliable, setRepliable] = useState([]);
  const [replies, setReplies] = useState([])
  const [notes, setNotes] = useState([notesData])

  useEffect(() => {
    if(paramsRow !== undefined){
      const dummyNotes = data.find(app => app.id === paramsRow.id)?.comments
      if(dummyNotes){
        setNotes(dummyNotes)
      }
    }
    else{
      setNotes(notesData)
    }
  },[data, paramsRow, notesData])

  const avatarBackgroundColor = (note) => {
    if(note.authorId === parseInt(authData.id)){
      return 'green'
    }
    else if(note.authorId !== parseInt(authData.id) && note.namespace == 'admins'){
      return 'red'
    }
    else{
      return 'blue'
    }
  }

  const editNotes = (id, note) => {
    const newEditedNotes = [...editedNotes.filter(eNote => eNote.id !== id)]
    const index = editedNotes.findIndex(note => note.id === id)
    const copy = {...editedNotes[index]}
    copy.body = note
    newEditedNotes.push(copy)
    setEditedNotes(newEditedNotes)
  }

  const cardNoteReply = (reply, note) => {
    return <Card elevation={4} style={{ marginLeft: '66px', width: '88%' }}>
      <CardHeader
        avatar={
          <Avatar sx={{ backgroundColor: 'red', width: 30, height: 30 }}>
            {reply.author && reply.author.charAt(0)}
          </Avatar>
        }
        title={
          <Typography variant={"body2"}>
            {reply.author}
          </Typography>
        }
        subheader={
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant={"body2"}>Admin</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography  variant={"body2"} className={classesSubheader.subAlignment}>{moment(reply.createdAt).format('LLL')}</Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent className={classesCardContentReply.content}>
        <Grid container rowSpacing={1}>
          <Grid item xs={11}>
            <ReadMore text={reply.body}>
            </ReadMore>
          </Grid>
          <Grid item xs={1}>
            {deleteReplyButton(reply, note)}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  }

  const cardAddReply = (note) => {
    if(repliable.includes(note.id)) {
      return <Card elevation={4} style={{ marginLeft: '66px', width: '90%', border: "none", boxShadow: "none" }}>
        <Grid container rowSpacing={1}>
          <Grid item xs={11}>
            <Textarea
              aria-label="minimum height"
              minRows={2}
              placeholder="Click here to add a reply..."
              style={{ width: '100%' }}
              value={replies.find(reply => reply.id === note.id)?.body || ''}
              onChange={(event) => {
                const newReplies = replies.filter(reply => reply.id !== note.id)
                newReplies.push({ id: note.id, body: event.target.value})
                setReplies(newReplies)
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={<h5>Save Reply</h5>}>
              <IconButton onClick={() => {
                const reply = replies.find(reply => reply.id === note.id) || {}
                if(reply.body?.length > 0){
                  addReply(note, reply.body || '')
                  setReplies(replies.filter(reply => reply.id !== note.id))
                  setRepliable(repliable.filter(id => id !== note.id))
                }
                else{
                  setReplies(replies.filter(reply => reply.id !== note.id))
                  setRepliable(repliable.filter(id => id !== note.id))
                }
              }}>
                <SaveIcon color={'error'} style={{ fontSize: '16px' }}/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
    }
  }

  const cardNote = (note, index) => {
    return <Card elevation={4}>
      <CardHeader
        avatar={
          <Avatar sx={{ backgroundColor: avatarBackgroundColor(note), width: 30, height: 30 }}>
            {note.author && note.author.charAt(0)}
          </Avatar>
        }
        action={
          <Grid container spacing={1}>
            {
              repliable.includes(note.id) == false && <Grid item xs={6}>
                {replyNoteButton(note)}
              </Grid>
            }
            <Grid item xs={6} style={{ textAlign: 'left' }}>
              {deleteNoteButton(note)}
            </Grid>
          </Grid>
        }
        title={
          <Typography variant={"body2"}>
            {note.author}
          </Typography>
        }
        subheader={
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant={"body2"}>{ note.namespace === 'admins' ? 'Admin' : 'Dealer' }</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"body2"} className={classesSubheader.subAlignment}>{moment(note.createdAt).format('LLL')}</Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent className={classesCardContent.content}>
        <Grid container rowSpacing={1}>
          <Grid container rowSpacing={1}>
            <Grid item xs={10}>
              {
                noteBody(note)
              }
            </Grid>
            <Grid item xs={1}>
              {
                saveEditNoteButton(note)
              }
            </Grid>
            <Grid item xs={1}>
              {
                noteVisiblitySwitch(note)
              }
            </Grid>
          </Grid>
          {
            cardAddReply(note)
          }
          {
            (note.replies instanceof Array) && note.replies.map((reply, index) => {
              return <Grid item key={index} xs={12}>
                {
                  cardNoteReply(reply, note)
                }
              </Grid>
            })
          }
        </Grid>
      </CardContent>
    </Card>
  }

  const noteBody = (note) => {
    const notesWithId = editedNotes.filter(n => n.id === note.id)
    if(notesWithId.length === 0){
      return <ReadMore text={note.body}></ReadMore>
    }
    else{
      return <TextField
        autoFocus
        multiline
        style={{ width: '100%' }}
        InputProps={{ classes, style: { fontSize: '14px', marginLeft: '66px' } }}
        defaultValue={note.body}
        onChange={event => editNotes(note.id, event.target.value)}
        disabled={note.authorId !== parseInt(authData.id) ? true : false}
      />
    }
  }

  const noteVisiblitySwitch = (note) => {
    return note.authorId === parseInt(authData.id) && note.namespace === 'admins' &&
      <Tooltip title={<h5>Visible to Dealers</h5>}>
        <FormGroup>
          <FormControlLabel control={
            <Switch
              defaultChecked={note.visibleToDealers}
              onChange={(value) => handleVisibility(note, value.target.checked)}
              color="error"
              size="small"
            />
          }/>
        </FormGroup>
      </Tooltip>
  }

  const saveEditNoteButton = (note) => {
    const notesWithId = editedNotes.filter(n => n.id === note.id)
    if(notesWithId.length > 0){
      return saveNoteButton(note)
    }
    else{
      return editNoteButton(note)
    }
  }

  const editNoteButton = (note) => {
    if(note.authorId === parseInt(authData.id)){
      return <Tooltip title={<h5>Edit Note</h5>}>
        <IconButton onClick={() => {
          const newEditedNotes = [...editedNotes]
          newEditedNotes.push(note)
          setEditedNotes(newEditedNotes)}}><EditIcon color={'error'} style={{ fontSize: '16px' }}/>
        </IconButton>
      </Tooltip>
    }
  }

  const saveNoteButton = (note) => {
    if(note.authorId === parseInt(authData.id)){
      return <Tooltip title={<h5>Save Note</h5>}>
        <IconButton onClick={() => {
          const editedNoteBody = editedNotes.find(editedNote => editedNote.id === note.id)
          const body = editedNoteBody?.body || ''
          setEditedNotes(editedNotes.filter(n => n.id !== note.id))
          saveEditedNote(body, note.id);
        }}>
          <SaveIcon color={'error'} style={{ fontSize: '16px' }}/>
        </IconButton>
      </Tooltip>
    }
  }

  const deleteNoteButton = (note) => {
    if(note.authorId === parseInt(authData.id)){
      return <Tooltip title={<h5>Delete Note</h5>}>
        <IconButton onClick={() => { deleteNote(note.id) }}>
          <DeleteIcon color={'error'} style={{ fontSize: '16px' }}/>
        </IconButton>
      </Tooltip>
    }
  }

  const replyNoteButton = (note) => {
      return <Tooltip title={<h5>Reply to Note</h5>}>
        <IconButton onClick={() => {
          const newRepliable = [...repliable]
          newRepliable.push(note.id)
          setRepliable(newRepliable)
        }}>
          <ReplyIcon color={'error'} style={{ fontSize: '16px' }}/>
        </IconButton>
      </Tooltip>
  }

  const deleteReplyButton = (reply, note) => {
    if(reply.authorId === parseInt(authData.id)){
      return <Tooltip title={<h5>Delete Reply</h5>}>
        <IconButton onClick={() => { deleteNoteReply(note, reply) }}>
          <DeleteIcon color={'error'} style={{ fontSize: '16px' }}/>
        </IconButton>
      </Tooltip>
    }
  }

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "820px",
            maxHeight: "560px"
          },
        },
      }}
      open={unlockDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle sx={{m: 0, p: 2}}>
        {'Notes'}
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose()
          }}
          color={"warning"}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            backgroundColor: "#e93b1b",
            color: 'black',
          }}
        >
          <CloseIcon sx={{ fontSize: '9px' }}/>
        </IconButton>
      </DialogTitle>
      {
        (notes instanceof Array) && notes.length > 0 &&
        <DialogContent sx={{  }} dividers>
          <Grid container rowSpacing={1}>
            {
              notes.map((note, index) => {
                return <Grid item key={index} xs={12}>
                  <Box sx={{ p: 1 }}>
                    {
                      cardNote(note, index)
                    }
                  </Box>
                </Grid>
              })
            }
          </Grid>
        </DialogContent>
      }

      <DialogContent sx={{ overflowY: 'visible' }}>
        <Grid container rowSpacing={1}>
          <Grid item xs={1}>
            <Avatar sx={{ backgroundColor: 'green' }}>
              {authData?.first_name.charAt(0)}
            </Avatar>
          </Grid>
          <Grid item xs={11}>
            <Textarea
              aria-label="minimum height"
              minRows={3}
              placeholder="Click here to add a note..."
              style={{ width: '100%' }}
              value={newNote}
              onChange={(event) => {
                setNewNote(event.target.value)
              }}
            />
            <FormGroup>
              <FormControlLabel control={<Switch defaultChecked={false} onChange={(value) => {
                setVisibleToDealers(value.target.checked)
              }} color="error"/>} label="Visible to Dealers" />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size='small' style={{ backgroundColor: "#e93b1b", color: 'white', marginRight: '2%', marginTop: '-5%' }}
                onClick={() => {
                  addNote(newNote)
                  setNewNote('')
                }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const StyledTextArea = styled(Textarea)({
  '&.Mui-focused fieldset': {
    borderColor: 'black',
  },
  // '& .MuiOutlinedInput-root': {
  //   '& fieldset': {
  //     borderColor: 'white',
  //   },
    // '&:hover fieldset': {
    //   borderColor: 'white',
    // },
})


import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { message, Spin } from "antd";
import LeaseApplicationList from "./LeaseApplicationList";
import { PUT } from '../../util/network';
import moment from "moment";
import {
    fetchLeaseApplications,
    updatePageState,
    updateLockingInfo,
    clearLockingStatus,
    submitApplication,
    updateLockingUser,
    exportLeaseApplicationsList,
    clearFilterState,
    updateLoadingState,
} from '../../reducers/LeaseApplications'
import { useSelector, useDispatch } from "react-redux";

export default function LeaseApplicationsListRenderer(props) {
    const { handleNoAuth } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const [transferedAdmin, setTransferedAdmin] = useState({});
    const [unlockTransferModalDialog, setUnlockTransferModalDialog] = useState(false);
    const [transferParamsData, setTransferParamsData] = useState({})
    const [unlockAdminUsersModalDialog, setUnlockAdminUsersModalDialog] = useState(false);
    const [exportModal, setExportModal] = useState(false);
    const [submitAppModal, setSubmitAppModal] = useState(false);
    const [submitAppMessage, setSubmitAppMessage] = useState(null);
    const [isTimer, setIsTimer] = useState(false);
    const [isExportComplete, setIsExportComplete] = useState(true);

    const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;
    let auth_token = window.localStorage.getItem('auth_token');
    const losPath = (path) => {
        return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
    }
    let loggedInUserId = null;
    if (authData !== null) {
        loggedInUserId = authData.id;
    }
    const [unlockDialog, setUnlockDialog] = useState(false);
    const [unlockParamsData, setUnlockParamsData] = useState({})

    const sortModel = useSelector((state) => state.leaseApplications.sortModel);
    const pageState = useSelector((state) => state.leaseApplications.pageState);
    const filterState = useSelector((state) => state.leaseApplications.filterState);
    const filterFlag = useSelector((state) => state.leaseApplications.filterFlag);
    const isNewAppTimer = useSelector((state) => state.leaseApplications.isNewAppTimer);
    const dataGridFilterFlag = useSelector((state) => state.leaseApplications.dataGridFilterFlag);
    const dataGridFilter = useSelector((state) => state.leaseApplications.dataGridFilter);
    const clearIconState = useSelector((state) => state.leaseApplications.clearIconState);

    const leaseApplicationsData = useSelector((state) => state.leaseApplications.leaseApplicationLists);
    const leaseApp = useSelector((state) => state.leaseApplications);
    const data = leaseApplicationsData?.data;
    const filteredData = leaseApplicationsData.filteredData;
    const filterTotal = leaseApplicationsData.filterTotal;
    const adminUsers = leaseApplicationsData.admin_users || []
    let isLoading = leaseApp.isLoading;
    let isExporting = leaseApp.isExporting;
    const getPecentage = (numerator, denominator) => {
        if (isNaN(numerator) && isNaN(denominator)) {
            return 0;
        }
        return Math.round((numerator / denominator) * 100)
    }
    const approvedPercentage = getPecentage(leaseApplicationsData.approved, leaseApplicationsData.total);
    const lookToBookPercentage = getPecentage(leaseApplicationsData.funded, leaseApplicationsData.submitted);
    const convertionPercentage = getPecentage(leaseApplicationsData.funded, leaseApplicationsData.total);
    const declinedPercentage = getPecentage(leaseApplicationsData.declined, leaseApplicationsData.total);

    const [slcRow, setSlcRow] = React.useState(null);
    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSlcRow(row);
    };

    const handleTransferOwnershipClick = () => {
        setUnlockAdminUsersModalDialog(true);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const handleRefresh = () => {
        setIsTimer(false)
        dispatch(fetchLeaseApplications({ pageState: pageState, filterState: filterState, dataGridFilterFlag: dataGridFilterFlag, dataGridFilter: dataGridFilter, sortModel: sortModel, clearIconState: clearIconState }));
    }

    const updateFilterSorting = (model) => {
        setIsTimer(false)
        dispatch(fetchLeaseApplications({ pageState: pageState, filterState: filterState, dataGridFilterFlag: dataGridFilterFlag, dataGridFilter: dataGridFilter, sortModel: model, clearIconState: clearIconState }))
    }

    const [applicationLockedBy, setApplicationlockedBy] = useState({});

    const lockApplication = async (params) => {
        let leaseApplication = params;
        const identifier = leaseApplication.id;
        setLoading(true)
        try {
            const response = await PUT(`/api/v1/lease_applications/lock-lease-application`, { id: identifier });
            if (response) {
                dispatch(updateLockingInfo({
                    status: response.data.lock_info.locked,
                    lockedById: response.data.lock_info.lockedById,
                    lockedByUser: response.data.lock_info.lockedByUser,
                }));
                message.success(response.data.message);
                history.push('/lease-applications/' + identifier);
            }
        } catch (e) {
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history)
            } else {
                message.error(e.response.data.message)
            }
        }
        setLoading(false)
    }

    const unlockApplication = async () => {
        const leaseApplication = data.find(app => app.id === unlockParamsData.id)
        const identifier = leaseApplication.id;
        handleClose();
        setLoading(true)
        try {
            if (unlockParamsData.lockedStatus.lockedById !== loggedInUserId) {
                const response = await PUT(`/api/v1/lease_applications/transfer-lease-application`, { id: identifier, transfered_admin_user_id: loggedInUserId })
                if (response) {
                    dispatch(updateLockingInfo({
                        status: response.data.lock_info.locked,
                        lockedById: response.data.lock_info.lockedById,
                        lockedByUser: response.data.lock_info.lockedByUser,
                    }));
                    message.success('Lease Application successfully unlocked and locked again by ', (authData.first_name || '') + ' ' + (authData.last_name || ''));
                    history.push('/lease-applications/' + identifier);
                }
            }
            else if (unlockParamsData.lockedStatus.lockedById === loggedInUserId) {
                const response = await PUT(`/api/v1/lease_applications/unlock-lease-application`, { id: identifier })
                message.success(response.data.message);
                dispatch(clearLockingStatus({ id: identifier, lock_info: { status: false, lockedById: null, lockedByUser: '' } }));
                dispatch(updateLockingInfo({ status: false, lockedById: null, lockedByUser: '' }));
            }
        } catch (e) {
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history)
            } else {
                message.error('Error while unlocking lease application')
            }
        }
        setLoading(false)
    }

    const transferApplication = async () => {
        const leaseApplication = data.find(app => app.id === transferParamsData.id)
        const identifier = leaseApplication.id;
        handleCloseTransferModal();
        setLoading(true)
        try {
            const response = await PUT(`/api/v1/lease_applications/transfer-lease-application`, { id: identifier, transfered_admin_user_id: transferedAdmin.id })
            if (response) {
                message.success(response.data.message);
                dispatch(updateLockingUser({ id: identifier, transferedAdmin: transferedAdmin }));
                dispatch(updateLockingInfo({
                    status: response.data.lock_info.locked,
                    lockedById: response.data.lock_info.lockedById,
                    lockedByUser: response.data.lock_info.lockedByUser,
                    lockedByUserFirstName: response.data.lock_info.lockedByUserFirstName,
                }));
            }
        } catch (e) {
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history)
            } else {
                message.error('Error while transfering lease application')
            }
        }
        setLoading(false)
    }

    const handleClose = () => {
        setUnlockDialog(false);
    };

    const handleClickOpen = (params) => {
        setUnlockDialog(true);
        setUnlockParamsData(params)
    };

    const handleExportModalOpen = () => {
        setExportModal(true)
    }
    const handleExportModalClose = () => {
        setExportModal(false)
    }

    const handleTransferOwnerModal = (params, admin_user) => {
        setTransferedAdmin(admin_user);
        setTransferParamsData(params);
        setUnlockTransferModalDialog(true);
    };

    const handleCloseTransferModal = () => {
        setUnlockTransferModalDialog(false);
    };

    const handleCloseAdminUsersModal = () => {
        setUnlockAdminUsersModalDialog(false);
    };

    const handleCloseSubmitAppModal = () => {
        setSubmitAppModal(false);
    };


    const setSearchDate = useCallback((event) => {
        let name = event.target.value;
        if (name === "all") {
            dispatch(updatePageState({
                date: name,
                start: null,
                end: null,
                page: 0,
                search: ''
            }))
        } else if (name === "year") {
            dispatch(updatePageState({
                date: name,
                start: moment().startOf(name).format('YYYY-MM-DD hh:mm:ss'),
                end: moment().endOf(name).format('YYYY-MM-DD hh:mm:ss'),
                page: 0,
                search: ''
            }))
        } else if (name === "current_month") {
            dispatch(updatePageState({
                date: name,
                start: moment().startOf('month').format('YYYY-MM-DD hh:mm:ss'),
                end: moment().endOf('month').format('YYYY-MM-DD hh:mm:ss'),
                page: 0,
                search: ''
            }))
        } else {
            dispatch(updatePageState({
                date: event.target.value,
                start: moment().subtract(1, name).startOf(name).format('YYYY-MM-DD hh:mm:ss'),
                end: moment().subtract(1, name).endOf(name).format('YYYY-MM-DD hh:mm:ss'),
                page: 0,
                search: ''
            }))
        }
        dispatch(clearFilterState({}))
    }, [dispatch, updatePageState])

    const actionColors = (params) => {
        let applicationStatus = params.row.lockedStatus
        if (applicationStatus.status && applicationStatus.lockedById === loggedInUserId) {
            return 'success'
        }
        else if (applicationStatus.status && applicationStatus.lockedById !== loggedInUserId) {
            return 'error'
        }
        else {
            return 'primary'
        }
    }

    const exportLeaseApplication = async () => {
        setIsExportComplete(false)
        try {
            setIsTimer(false)
            setLoading(true)
            message.success("lease Application CSV will be downloaded in a minutes")
            setExportModal(false)
            let res = null;
            dispatch(updateLoadingState(true))

            if (filterFlag == true) {
                res = await exportLeaseApplicationsList("filterState", filterState)
            } else {
                res = await exportLeaseApplicationsList("pageState", pageState)
            }
            
            if(res?.data)
            {
                let url = losPath(res.data)
                // window.open(url, '_blank');
                let link = document.createElement('a');
                link.href = url;
                link.target = '_blank'
                // link.setAttribute('download', 'lease_applications_exported.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
                setIsExportComplete(true)
            }
           
            dispatch(updateLoadingState(false))
            setLoading(false)
        } catch (e) {
            setIsExportComplete(true)
            
            setLoading(false)
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history)
            } else {
                message.error('Error while Exporting lease application')
            }
        }

    }

    const submitToSpeedLeasing = async (id) => {
        try {
            let res = await submitApplication(id)
            let message = res.data.message.alert || res.data.message.notice
            setSubmitAppMessage("Application ID:" + id + "." + message)
            setSubmitAppModal(true)
        } catch (e) {
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history)
            }
        }
    }

    useEffect(() => {
        dispatch(fetchLeaseApplications({ pageState: pageState, filterState: filterState, dataGridFilterFlag: dataGridFilterFlag, dataGridFilter: dataGridFilter, sortModel: sortModel, clearIconState: clearIconState }))
        const interval = setInterval(() => {
            if (!isExportComplete || loading) { clearInterval(interval.currentTarget); setIsTimer(false) } else {
                setIsTimer(true)
                dispatch(fetchLeaseApplications({ pageState: pageState, filterState: filterState, dataGridFilterFlag: dataGridFilterFlag, dataGridFilter: dataGridFilter, sortModel: sortModel, clearIconState: clearIconState }))
            }
        }, 30000);
        return () => clearInterval(interval);
    }, [pageState, filterFlag, dataGridFilterFlag]);

    return (
        <Spin spinning={isTimer === true && !isExporting ? false : isLoading || loading || isExporting }>
            <LeaseApplicationList
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                unlockApplication={unlockApplication}
                lockApplication={lockApplication}
                handleClose2={handleClose2}
                handleClick={handleClick}
                data={data ?? null}
                loading={loading}
                anchorEl={anchorEl}
                unlockDialog={unlockDialog}
                unlockParamsData={unlockParamsData}
                pageState={pageState}
                approvedPercentage={approvedPercentage || 0}
                declinedPercentage={declinedPercentage || 0}
                lookToBookPercentage={lookToBookPercentage || 0}
                convertionPercentage={convertionPercentage || 0}
                dealerOptions={leaseApplicationsData.dealers || []}
                totalSalePrice={leaseApplicationsData.total_sales_price_cents}
                total={leaseApplicationsData.total}
                filterTotal={filterTotal}
                funded={leaseApplicationsData.funded}
                approved={leaseApplicationsData.approved || 0}
                declined={leaseApplicationsData.declined || 0}
                submitted={leaseApplicationsData.submitted}
                setSearchDate={setSearchDate}
                setAnchorEl={setAnchorEl}
                slcRow={slcRow}
                setApplicationlockedBy={setApplicationlockedBy}
                applicationLockedBy={applicationLockedBy}
                filterDefaultData={leaseApplicationsData.filter_default_data}
                actionColors={actionColors}
                handleTransferOwnershipClick={handleTransferOwnershipClick}
                unlockTransferModalDialog={unlockTransferModalDialog}
                transferedAdmin={transferedAdmin}
                handleCloseTransferModal={handleCloseTransferModal}
                transferApplication={transferApplication}
                adminUsers={adminUsers}
                unlockAdminUsersModalDialog={unlockAdminUsersModalDialog}
                handleCloseAdminUsersModal={handleCloseAdminUsersModal}
                handleTransferOwnerModal={handleTransferOwnerModal}
                filterState={filterState}
                filterFlag={filterFlag}
                filteredData={filteredData}
                isLoading={isLoading}
                isTimer={isTimer}
                isNewAppTimer={isNewAppTimer}
                handleExportModalClose={handleExportModalClose}
                handleExportModalOpen={handleExportModalOpen}
                authData={authData}
                exportModal={exportModal}
                exportLeaseApplication={exportLeaseApplication}
                submitToSpeedLeasing={submitToSpeedLeasing}
                handleCloseSubmitAppModal={handleCloseSubmitAppModal}
                submitAppModal={submitAppModal}
                sortModel={sortModel}
                updateFilterSorting={updateFilterSorting}
                SubmitAppMessage={submitAppMessage}
                handleRefresh={handleRefresh}
            />
        </Spin>
    )
}
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST } from '../util/network';

export const fetchLeaseApplications = createAsyncThunk('leaseApplications/getApplications', async (params) => {
  let response = null;
  if (params.pageState !== undefined && params.filterState !== undefined && params.dataGridFilter !== undefined) {
    let encodedParam = Object.entries(params.pageState).map(([key, val]) => (key !== 'dealer') && `${key}=${val}`).join('&');
    if(params?.clearIconState){
      let encodedFilterParam = Object.entries(params.filterState).map(([key, val]) => `${key}=${val}`).join('&');
      response = await GET(`/api/v1/lease_applications/filter?filterState=${params?.clearIconState}&` + encodedParam + '&' + encodedFilterParam);
    } else {
      let encodedDataGridFilterParam = Object.entries(params.dataGridFilter).map(([key, val]) => {
        if (key === 'document_statuses' || key === 'credit_statuses'){
          return `${key}=${encodeURIComponent(JSON.stringify(val))}`
        } else {
          return `${key}=${val}`
        }
      }).join('&');
      response = await GET(`/api/v1/lease_applications?dataGridFilterFlag=${params?.dataGridFilterFlag}&` + encodedParam + '&' + encodedDataGridFilterParam);
    }
  } else if (params.pageState !== undefined) {
    let encodedParam = Object.entries(params.pageState).map(([key, val]) => (key !== 'dealer') && `${key}=${val}`).join('&');
    response = await GET(`/api/v1/lease_applications?` + encodedParam);
  } else if (params.filterState !== undefined) {
    let encodedParam = Object.entries(params.pageState).map(([key, val]) => (key !== 'dealer') && `${key}=${val}`).join('&');
    let encodedFilterParam = Object.entries(params.filterState).map(([key, val]) => `${key}=${val}`).join('&');
    response = await GET(`/api/v1/lease_applications/filter?filterState=${params?.clearIconState}&` + encodedParam + '&' + encodedFilterParam);
  } else if (params.dataGridFilterFlag){
    let encodedParam = Object.entries(params.pageState).map(([key, val]) => (key !== 'dealer') && `${key}=${val}`).join('&');
    let encodedDataGridFilterParam = Object.entries(params.dataGridFilter).map(([key, val]) => {
      if (key === 'document_statuses' || key === 'credit_statuses'){
        return `${key}=${encodeURIComponent(JSON.stringify(val))}`
      } else {
        return `${key}=${val}`
      }
    }).join('&');
    response = await GET(`/api/v1/lease_applications?dataGridFilterFlag=${params?.dataGridFilterFlag}&` + encodedParam + '&' + encodedDataGridFilterParam);
  } else {
    response = await GET(`/api/v1/lease_applications`);
  }

  if (params.isTimer) {
    return { data: response.data, sortModel: params.sortModel, isTimer: params.isTimer }
  } else {
    return { data: response.data, sortModel: params.sortModel, isTimer: false }
  }
})


export const fetchFilteredLeaseApplications = createAsyncThunk('leaseApplications/filter', async (params) => {
  let response = null;
  if (params.filterState !== undefined) {
    let encodedParam = Object.entries(params.filterState).map(([key, val]) => `${key}=${val}`).join('&');
    response = await GET(`/api/v1/lease_applications/filter?` + encodedParam);
  }

  if (params.isTimer) {
    return { data: response.data, sortModel: params.sortModel, isTimer: params.isTimer }
  } else {
    return { data: response.data, sortModel: params.sortModel, isTimer: false }
  }

})

export const exportLeaseApplicationsList = async (stateMode, params) => {
  try {
    let response = null;
    if (params !== undefined) {
      let encodedParam = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
      response = await GET(`/api/v1/lease_applications/export?stateMode=${stateMode}&` + encodedParam)
    }
    return response
  } catch (e) {
    console.log(e)
  }
}

export const fetchLeaseApplicationDetails = async (params) => {
  try {
    let encodedParam = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
    const response = await GET(`/api/v1/lease_applications/details?` + encodedParam)
    return response
  } catch (e) {
    console.log(e)
  }
};

export const fetchPinnedTabs = async () => {
  try {
    const response = await GET(`/api/v1/admin-users/get-pinned-tabs`)
    return response
  } catch (e) {
    console.log(e)
  }
};

export const submitApplication = async (id) => {
  try {
    const response = await POST(`/api/v1/lease_applications/submit-to-speedleaseing?id=` + id)
    return response
  } catch (e) {
    console.log(e)
  }
}

export const insertIncomeVerification = async (params) => {
  try {
    const response = await POST(`/api/v1/income-verifications`, params)
    return response
  } catch (e) {
    console.log(e)
  }
};

const initialState = {
  leaseApplicationLists: {},
  leaseApplicationDetails: {},
  isNewAppTimer: false,
  pageState: {
    total: 0,
    page: 0,
    limit: 50,
    date: "all",
    dealer: null,
    dealerid: '',
    search: "",
    start: null,
    end: null,
    filter: false
  },
  filterState: {
    dealerUser: "",
    dealer: "",
    dealerRepresentative: "",
    dealerState: "",
    stipulation: "",

    assetMake: "",
    calculatorStatus: "",
    assetMileageTier: "",
    assetCreditTier: '',
    assetVin: "",

    lesseeFullName: '',
    lesseeFirstName: '',
    lesseeLastName: "",
    lesseeSsn: "",

    colesseeFullName: "",
    colesseeFirstName: "",
    colesseeLastName: "",
    colesseeSsn: "",

    welcomeCallStatus: "",
    hasCoapplicant: "",
    applicantState: "",

    applicationDateFrom: null,
    applicationDateTo: null,
    fundingDelayFrom: null,
    fundingDelayTo: null,
    fundingApprovedFrom: null,
    fundingApprovedTo: null,
    fundedDateFrom: null,
    fundedDateTo: null,
    documentDateFrom: null,
    documentDateTo: null,
    documentReceivedFrom: null,
    documentReceivedTo: null,

    dealerRepresentativeObj: null,
    dealershipObj: null,
    dealerUseObj: null,
    stipulationObj: null,
    welcomeCallObj: null,
    lesseeLicenseNumber: "",
  },
  filterFlag: false,
  clearIconState: false,
  dataGridFilterFlag: false,
  isExporting:false,
  dataGridFilter: {
    dealership_name: "",
    dealership_compare_operator: "",
    lessee_name: "",
    lessee_compare_operator: "",
    colessee_name: "",
    colessee_compare_operator: "",
    document_statuses: [],
    credit_statuses: [],
  },
  sortModel: [
    {
      field: 'UpdatedAt',
      sort: 'desc',
    },
  ],
  pinnedTabs: {},
}

export const leaseApplicationsSlice = createSlice({
  name: "leaseApplications",
  initialState: initialState,
  reducers: {
    getDetails: (state, action) => {
      state.leaseApplicationDetails = action.payload
      return state
    },
    setApplicationDetails: (state, action) => {
      state.applicationDetails = action.payload;
      return state
    },
    setPinnedTabs: (state, action) => {
      state.pinnedTabs = action.payload
      return state
    },
    updateLockingInfo: (state, action) => {
      let payload = action.payload;
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          lockedStatus: {
            ...state.leaseApplicationDetails.lockedStatus,
            status: payload.status,
            lockedById: payload.lockedById,
            lockedByUser: payload.lockedByUser,
            lockedByUserFirstName: payload.lockedByUserFirstName
          }
        }
      };
    },
    makeAppDetailsEmpty: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: action.payload
      };
    },
    clearLockingStatus: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id) {
          app.lockedStatus.status = false;
          app.lockedStatus.lockedById = null;
          app.lockedStatus.lockedByUser = null;
          app.lockedStatus.lockedByUserFirstName = null;
        }
      });
    },
    lockCurrentUser: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id) {
          const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;
          if (authData !== null) {
            app.lockedStatus.status = true;
            app.lockedStatus.lockedById = authData.id;
            app.lockedStatus.lockedByUser = (authData.first_name || '') + ' ' + (authData.last_name || '');
            app.lockedStatus.lockedByUserFirstName = authData.first_name || '';
          }
        }
      });
    },
    updateLockingUser: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id) {
          let transferedAdmin = action.payload.transferedAdmin;
          app.lockedStatus.lockedById = transferedAdmin.id;
          app.lockedStatus.lockedByUser = transferedAdmin.name;
          app.lockedStatus.lockedByUserFirstName = transferedAdmin.firstName;
        }
      });
    },
    updateAttachments: (state, action) => {
      state.leaseApplicationDetails.leaseApplicationAttachments.map((app) => {
        if (app.id === action.payload.id) {
          let payload = action.payload;
          app.description = payload.description
        }
      });
    },
    insertLeaseAttachment: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          leaseApplicationAttachments: [
            action.payload,
            ...state.leaseApplicationDetails.leaseApplicationAttachments
          ]
        }
      };
    },
    deleteLeaseAttachment: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          leaseApplicationAttachments: [
            ...state.leaseApplicationDetails.leaseApplicationAttachments.filter(item =>
              item.id !== action.payload.id
            )
          ]
        }
      };
    },
    insertLesseeIncomeVerification: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          lessee: {
            ...state.leaseApplicationDetails.lessee,
            incomeVerifications: [
              action.payload,
              ...state.leaseApplicationDetails.lessee.incomeVerifications
            ]
          }
        }
      };
    },

    setLeaseAppExpiry: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          expired: action.payload
        }
      };
    },

    saveStipulation: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          leaseApplicationStipulations: [
            action.payload,
            ...state.leaseApplicationDetails.leaseApplicationStipulations,
          ]
        }
      };
    },
    deleteLesseeIncomeVerification: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          lessee: {
            ...state.leaseApplicationDetails.lessee,
            incomeVerifications: [
              ...state.leaseApplicationDetails.lessee.incomeVerifications.filter(item =>
                item.id !== action.payload.id
              )
            ]
          }
        }
      };
    },
    insertFundingDelay: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          fundingDelays: [
            ...state.leaseApplicationDetails.fundingDelays,
            action.payload,
          ]
        }
      };
    },
    updateFundingDelay: (state, action) => {
      let payload = action.payload;
      state.leaseApplicationDetails.fundingDelays.map((delay) => {
        if (delay.id === payload.id) {
          delay.notes = payload.notes;
          delay.status = payload.status;
        }
      });
    },
    updateDocumentStatus: (state, action) => {
      let payload = action.payload;
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          documentStatus: payload.documentStatus,
          documentsRequestedDate: payload.documentsRequestedDate,
          documentsIssuedDate: payload.documentsIssuedDate,
          leasePackageReceivedDate: payload.leasePackageReceivedDate,
          fundingDelayOn: payload.fundingDelayOn,
          fundingApprovedOn: payload.fundingApprovedOn,
          fundedOn: payload.fundedOn,
          lessee: {
            ...state.leaseApplicationDetails.lessee,
            emailAddress: payload.lessee_attributes?.emailAddress
          }
        }
      };
    },
    clearSsnChanged: (state, action) => {
      let payload = action.payload;
      if (payload.type === 'lessee') {
        return {
          ...state,
          leaseApplicationDetails: {
            ...state.leaseApplicationDetails,
            lessee: {
              ...state.leaseApplicationDetails.lessee,
              ssnChanged: false
            }
          }
        };
      }
      else {
        return {
          ...state,
          leaseApplicationDetails: {
            ...state.leaseApplicationDetails,
            colessee: {
              ...state.leaseApplicationDetails.colessee,
              ssnChanged: false
            }
          }
        };
      }
    },
    insertComment: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          comments: [
            ...state.leaseApplicationDetails.comments,
            action.payload,
          ]
        }
      };
    },
    updateVehiclePossession: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          vehiclePossession: action.payload.vehiclePossession
        }
      }
    },
    updateReference: (state, action) => {
      let payload = action.payload;
      if ('id' in payload) {
        state.leaseApplicationDetails.references.map((reference) => {
          if (reference.id === payload.id) {
            reference.firstName = payload.firstName;
            reference.lastName = payload.lastName;
            reference.city = payload.city;
            reference.state = payload.state;
            reference.phoneNumber = payload.phoneNumber;
          }
        });
      }
      else {
        return {
          ...state,
          leaseApplicationDetails: {
            ...state.leaseApplicationDetails,
            references: [
              ...state.leaseApplicationDetails.references,
              action.payload,
            ]
          }
        };
      }
    },
    updateGpsUnit: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          leaseApplicationGpsUnit: action.payload
        }
      }
    },
    updateShortFund: (state, action) => {
      let payload = action.payload;
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          dealership: {
            ...state.leaseApplicationDetails.dealership,
            isDealershipSubjectToClawback: payload.isDealershipSubjectToClawback,
            thisDealDealershipClawbackAmount: payload.thisDealDealershipClawbackAmount
          }
        }
      }
    },
    updatePageState: (state, action) => {
      return {
        ...state,
        pageState: {
          ...state.pageState, ...action.payload
        },
        isNewAppTimer: false,
      }
    },

    updateLoadingState: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
        isExporting: action.payload
      }
    },

    updateFilterState: (state, action) => {
      return {
        ...state,
        filterState: {
          ...state.filterState, ...action.payload
        },
        filterFlag: true,
        isNewAppTimer: false,
      }
    },
    updateFilterIconState: (state) => {
      state.clearIconState = true;
      return state
    },
    clearFilterIconState: (state) => {
      state.clearIconState = false;
      return state
    },
    updateFilterFlagState: (state, action) => {
      let payload = action.payload;
      return {
        ...state,
        filterFlag: action.payload,
        isNewAppTimer: true,
      }
    },
    clearFilterState: (state, action) => {
      state.filterState = initialState.filterState;
      state.filterFlag = !state.filterFlag;
      state.clearIconState = false;
      state.isNewAppTimer = false;
      return state
    },
    clearDataGridFilters: (state, action) => {
      state.dataGridFilter = initialState.dataGridFilter;
      state.dataGridFilterFlag = false;
      state.isNewAppTimer = false;
      return state
    },
    updateDataGridFilters: (state, action) => {
      return {
        ...state,
        dataGridFilter: {
          ...state.dataGridFilter, ...action.payload
        },
      }
    },
    updateDataGridFilterFlag: (state, action) => {
      state.dataGridFilterFlag = !state.dataGridFilterFlag;
      // state.dataGridFilterFlag = true;
      return state
    },
    clearUnseenNotesCount: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          unseenNotesCount: 0
        }
      }
    },
    insertCommentReply: (state, action) => {
      state.leaseApplicationDetails.comments.map((comment) => {
        if(comment.id === action.payload.commentId){
          const replies = comment.replies
          comment.replies = [action.payload.response, ...replies]
        }
      })
    },
    updateComment: (state, action) => {
      state.leaseApplicationDetails.comments.map((comment) => {
        if(comment.id === action.payload.commentId){
          comment.body = action.payload.body
        }
      })
    },
    deleteComment: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          comments: [
            ...state.leaseApplicationDetails.comments.filter(item => item.id !== action.payload.id)
          ]
        }
      };
    },
    deleteCommentReply: (state, action) => {
      state.leaseApplicationDetails.comments.map((comment) => {
        if(comment.id === action.payload.id){
          comment.replies = [...comment.replies.filter(reply => reply.id !== action.payload.replyId)]
        }
      })
    },
    updateCommentVisibleToDealers: (state, action) => {
      state.leaseApplicationDetails.comments.map((comment) => {
        if(comment.id === action.payload.id){
          comment.visibleToDealers = action.payload.visibleToDealers
        }
      })
    },
    insertNote: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id) {
          app.comments.unshift(action.payload.note)
        }
      })
    },
    editNoteFromState: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id) {
          const newComments = [...app.comments]
          const index = app.comments.findIndex(comment => comment.id === action.payload.noteId)
          const copy = { ...newComments[index] }
          copy.body = action.payload.body
          newComments[index] = copy
          app.comments = [...newComments]
        }
      })
    },
    deleteNoteFromState: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id) {
          const commentsAfterDelete = app.comments.filter(comment => comment.id !== action.payload.noteId)
          app.comments = [...commentsAfterDelete]
        }
      })
    },
    clearUnseenNotesCountFromList: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id) {
          app.unseenNotesCount = 0
        }
      })
    },
    insertNoteReply: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id){
          const comments = app.comments
          const comment = comments.find(comment => comment.id === action.payload.commentId)
          if(comment){
            const replies = comment.replies
            comment.replies = [action.payload.response, ...replies]
            const index = app.comments.findIndex(comment => comment.id === action.payload.commentId)
            const newComments = [...app.comments]
            newComments[index] = comment
            app.comments = [...newComments]
          }
        }
      })
    },
    deleteReplyFromNote: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id){
          const comments = app.comments
          const comment = comments.find(comment => comment.id === action.payload.commentId)
          if(comment){
            comment.replies = [...comment.replies.filter(reply => reply.id !== action.payload.replyId)]
          }
        }
      })
    },
    updateNoteVisibleToDealers: (state, action) => {
      state.leaseApplicationLists.data.map((app) => {
        if (app.id === action.payload.id){
          const comments = app.comments
          const comment = comments.find(comment => comment.id === action.payload.commentId)
          if(comment){
            comment.visibleToDealers = action.payload.visibleToDealers
          }
        }
      })
    },
    clearUnseenAttachmentsCount: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          unseenAttachmentsCount: 0
        }
      }
    },
    setColesseAfterRemove: (state, action) => {
      return {
        ...state,
        leaseApplicationDetails: {
          ...state.leaseApplicationDetails,
          colessee: action.payload.colessee,
          deletedColessees: action.payload.deletedColessees,
          colesseeCreditSummary: action.payload.colesseeCreditSummary,
          creditReports: action.payload.creditReports,
        },
      };
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchLeaseApplications.fulfilled, (state, action) => {

      const previousFetchedData = state.leaseApplicationLists?.data;
      const newFetchedData = action?.payload?.data?.data;
      if (action.payload.isTimer === true && (JSON.stringify(previousFetchedData) !== JSON.stringify(newFetchedData))) {

        const newSubmittedAppsLists = newFetchedData.filter(e => {
          return !previousFetchedData.some(item => item.id === e.id);
        });

        if (newSubmittedAppsLists.length > 0) {
          let newAppIds = newSubmittedAppsLists?.map((item) => item.id)
          action.payload.data.data.map((app) => {
            if (newAppIds.includes(app.id)) {
              app.isNewApp = true;
            }
          });
          state.isNewAppTimer = true
        }
      } else {
        state.isNewAppTimer = false
      }

      state.leaseApplicationLists = action.payload.data
      state.sortModel = action.payload.sortModel
      state.filterFlag = false
      state.isLoading = false
      return state
    }),
    builder.addCase(fetchLeaseApplications.pending, (state, action) => {
      state.isLoading = true;
    }),
    builder.addCase(fetchLeaseApplications.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.filterFlag = false
      state.error = action.payload;
    });
    builder.addCase(fetchFilteredLeaseApplications.fulfilled, (state, action) => {
      const previousFetchedData = state.leaseApplicationLists?.filteredData;
      const newFetchedData = action?.payload?.data?.data;
      console.log('previousFetchedData', previousFetchedData)
      console.log('newFetchedData', previousFetchedData)
      if (action.payload.isTimer === true && (JSON.stringify(previousFetchedData) !== JSON.stringify(newFetchedData))) {
        const newSubmittedAppsLists = newFetchedData.filter(e => {
          return !previousFetchedData.some(item => item.id === e.id);
        });

        if (newSubmittedAppsLists.length > 0) {
          let newAppIds = newSubmittedAppsLists?.map((item) => item.id)
          action.payload.data.data.map((app) => {
            if (newAppIds.includes(app.id)) {
              app.isNewApp = true;
            }
          });
          state.isNewAppTimer = true
        }
      } else {
        state.isNewAppTimer = false
      }

      state.leaseApplicationLists.filteredData = action.payload.data.data
      state.leaseApplicationLists.filterTotal = action.payload.data.filterTotal
      state.isLoading = false;
      state.sortModel = action.payload.sortModel
      state.filterFlag = true
      return state
    }),
      builder.addCase(fetchFilteredLeaseApplications.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchFilteredLeaseApplications.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.filterFlag = false
        state.error = action.payload;
      });
  }
});


export const { updateAttachments, updateLoadingState, updateLockingInfo, makeAppDetailsEmpty, updatePageState, updateFilterState, updateFilterFlagState, clearFilterState, clearDataGridFilters, updateDataGridFilters, updateDataGridFilterFlag, getDetails,setApplicationDetails, setPinnedTabs, clearLockingStatus,
  updateLockingUser, insertLesseeIncomeVerification, deleteLesseeIncomeVerification,
  insertFundingDelay, updateFundingDelay, insertStipulation, updateStipulationNotesAtachment, updateStipulationStatus, insertComment, lockCurrentUser,
  updateVehiclePossession, updateReference, updateGpsUnit, updateShortFund, insertLeaseAttachment, deleteLeaseAttachment, updateDocumentStatus, clearSsnChanged, saveStipulation,
  clearUnseenNotesCount, insertCommentReply, updateComment, deleteComment, deleteCommentReply, updateCommentVisibleToDealers, setLeaseAppExpiry, insertNote, editNoteFromState,
  deleteNoteFromState, clearUnseenNotesCountFromList, insertNoteReply, deleteReplyFromNote, updateNoteVisibleToDealers, clearUnseenAttachmentsCount, updateFilterIconState, clearFilterIconState, setColesseAfterRemove } = leaseApplicationsSlice.actions;

export default leaseApplicationsSlice.reducer;

import React, { useState } from "react";
import { Input, Table, Button, Divider, Modal, Col, Row, PageHeader } from "antd";
import DocusignReportsSummary from './DocusignReportsSummary'
import moment from 'moment'
import { fetchEnvelopes } from '../../reducers/Docusign'
import Store from '../../Store';
import { useSelector } from "react-redux";


export default function DocusignReportsTable({ data, ...props }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [docusignReceipients, setDocusignReceipients] = useState([]);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const { Search } = Input;
  const { handleNoAuth } = props;

  const tableData = useSelector((state) => state.docusignHistories);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const show_receipients = (record) => {
    setDocusignReceipients(record.docusign_history)
    showModal(true)
  }
  
  const receipentColumns = [
    {
      title: 'Name',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Email',
      dataIndex: 'user_email',
      key: 'user_email',
    },
    {
      title: 'Role',
      dataIndex: 'user_role',
      key: 'user_role',
    },
    {
      title: 'Docusign Status',
      dataIndex: 'user_status',
      key: 'user_status',
    },
  ];

  const envelopeReportColumns = [
    {
      title: 'Envelope Id',
      dataIndex: 'envelope_id',
      key: 'envelope_id',
      onFilter: (value, record) => record.envelope_id.indexOf(value) === 0,
      sorter: (a, b) => a.envelope_id.localeCompare(b.envelope_id),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Envelope Status',
      dataIndex: 'envelope_status',
      key: 'envelope_status',
      onFilter: (value, record) => record.envelope_status.indexOf(value) === 0,
      sorter: (a, b) => a.envelope_status.length - b.envelope_status.length,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'App ID',
      dataIndex: 'application_identifier',
      key: 'application_identifier',
      onFilter: (value, record) => record.application_identifier.indexOf(value) === 0,
      sorter: (a, b) => a.application_identifier - b.application_identifier,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Lease Application Id',
      dataIndex: 'lease_application_id',
      key: 'lease_application_id',
      onFilter: (value, record) => record.lease_application_id.indexOf(value) === 0,
      sorter: (a, b) => a.lease_application_id - b.lease_application_id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => moment(created_at).format('MMMM Do YYYY, h:mm:ss'),
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (updated_at) => moment(updated_at).format('MMMM Do YYYY, h:mm:ss'),
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Envelope History',
      key: 'actions',
      render(record) {
        return (
          <>
            <Button type="primary" onClick={() => {
              show_receipients(record)
            }}>
              Receipients Status
            </Button>

          </>
        )
      }
    }
  ];

  const onSearch = (value) => {
    Store.dispatch(fetchEnvelopes({ search: value }))
  }

  return (
    <>
      <Modal width={800} title="Envelope Receipients" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Table
          className='slc-table-receipients'
          rowKey={docusignReceipients.key}
          columns={receipentColumns}
          dataSource={docusignReceipients}
          pagination={false}
          size="large"
        />
      </Modal>
      <PageHeader
        className="site-page-header"
        title="Docusign Reports"
      />
      <DocusignReportsSummary docusign_summary_reports={tableData?.docusign_summary_reports} />
      <Divider />
      <Row gutter={24}>
        <Col span={6} style={{ marginLeft: 'auto' }} >
          <Search
            placeholder="Search Docusign Envelopes"
            allowClear
            enterButton="Search"
            size="large"
            // onChange={onChange}
            onSearch={onSearch}
          />
        </Col>
      </Row>

      <Divider />
      <Table
        className='slc-content-tablekjhkjh'
        bordered
        rowKey={Math.random()}
        dataSource={tableData?.docusign_reports}
        columns={envelopeReportColumns}
        onChange={(event) => setCurrent(event.current)}
        pagination={{
          current: current,
          default_current: defaultCurrent,
          pageSize: pageSize,
          showTotal: () => `Total ${tableData?.docusign_reports?.length} Envelopes`,
          total: tableData?.docusign_reports?.length,
          showSizeChanger: true,
          onShowSizeChange: (current, size) => setPageSize(size)
        }}
      />
    </>

  );
}
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Layout, Row, Col, Menu, Collapse, message, Divider, Form, Tag, Badge } from "antd";
import Icon, { DeleteOutlined, RocketOutlined, ExportOutlined, BulbOutlined, ProfileOutlined, TeamOutlined, BankOutlined, MedicineBoxOutlined, IdcardOutlined, PhoneOutlined, HourglassOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import { GET, PUT, POST } from '../../util/network';
import logger from '../../util/logger';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import { AttachmentSvg, BallotSvg, CoLesseeSvg, CreditSvg, GpsSvg, LesseeSvg, NotePenSvg, HistorySvg } from '../../components/layouts/Svg';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import Summary from './Summary/Summary';
import Notes from './notes/Notes';
import Lessee from './Lessee';
import CoLessee from './CoLessee';
import DeletedColessees from "./DeletedColessees";
import CoLesseeEmployment from './CoLesseeEmployment';
import CoLesseeVerifications from './CoLesseeVerifications';
import CoLesseeEmploymentVerifications from './CoLesseeEmploymentVerifications';
import GpsUnit from './gpsUnit/GpsUnit.js';
import LesseeEmployment from './LesseeEmployment';
import LesseeVerifications from './LesseeVerifications';
import LesseeEmploymentVerifications from './LesseeEmploymentVerifications';
import WelcomeCall from './welcomeCall/WelcomeCall';
import Workflow from './Workflow';
import FundingDelay from './fundingDelay/FundingDelay';
import BankingInformation from './BankingInformation';
import InsuranceInformation from './InsuranceInformation';
import DealershipShortfund from './DealershipShortfund';
import References from './References/References';
import LeaseDocumentRequests from './LeaseDocumentRequests';
import Credit from './credits/Credit';
import LeaseStipulation from './leaseStipulations/LeaseStipulation';
import './LeaseApplication.css'
import Attachments from "./Attachments";
import DocuSign from "./DocuSign/index.js";
import PinCollapse from "./components/PinCollapse";
import Audits from "./Audits/Audits";
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';
import { useSelector, useDispatch } from "react-redux";
import {getDetails, makeAppDetailsEmpty, setPinnedTabs, clearUnseenNotesCount, fetchLeaseApplicationDetails } from '../../reducers/LeaseApplications'
import NotificationEmails from './notificationEmails/NotificationEmails';
import { NotificationOutlined } from "@ant-design/icons";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;

const exportStyles = { style: { display: "block" } }
const powerOfAttorneyStyles = { style: { display: "block" } }
const dividerStyles = { style: { display: "block" } }

export default function LeaseApplication({ data, appNumber, setLoading, pinnedCollapse, ...props }) {
  const { allowedActions, creditStatusOptions, handleNoAuth, poaPrequisites, declineReasonTypeOptions, disableFlag } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const welcomeCallActions = GetResourceActions('WelcomeCall', allowedActions);
  const allowedGetWelcomeCall = IsActionAllowed('get', welcomeCallActions);
  const documentStatus = data.documentStatus;
  let hash = window.location.hash || null;

  let Menus = [
    {
      label: "Summary",
      iconType: <BulbOutlined />,
      Component: Summary,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Notes",
      iconType: <Icon component={NotePenSvg} />,
      Component: Notes,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Workflow",
      iconType: <RocketOutlined />,
      Component: Workflow,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Credit",
      iconType: <Icon component={CreditSvg} />,
      Component: Credit,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Deleted Colessees",
      iconType: <DeleteOutlined />,
      panelLabel: "Deleted Colessees",
      Component: DeletedColessees,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Lessee",
      iconType: <Icon component={LesseeSvg} />,
      Component: Lessee,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Lessee Verifications",
      Component: LesseeVerifications,
      menuType: "child",
      isAllowedToShow: true,
    },
    {
      label: "Lessee Employment",
      Component: LesseeEmployment,
      menuType: "child",
      isAllowedToShow: true,
    },
    {
      label: "Lessee Employment Verifications",
      Component: LesseeEmploymentVerifications,
      menuType: "child",
      isAllowedToShow: true,
    },
    {
      label: "Co-Lessee",
      iconType: <Icon component={CoLesseeSvg} />,
      Component: CoLessee,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Co-Lessee Verifications",
      Component: CoLesseeVerifications,
      menuType: "child",
      isAllowedToShow: true,
    },
    {
      label: "Co-Lessee Employment",
      Component: CoLesseeEmployment,
      menuType: "child",
      isAllowedToShow: true,
    },
    {
      label: "Co-Lessee Employment Verifications",
      Component: CoLesseeEmploymentVerifications,
      menuType: "child",
      isAllowedToShow: true,
    },
    {
      label: "Stipulations",
      iconType: <Icon component={BallotSvg} />,
      Component: LeaseStipulation,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Lease Documents",
      panelLabel: "Lease Document Requests",
      iconType: <ProfileOutlined />,
      Component: LeaseDocumentRequests,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "GPS Unit",
      iconType: <Icon component={GpsSvg} />,
      Component: GpsUnit,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Attachments",
      panelLabel: "Attachments",
      iconType: <Icon component={AttachmentSvg} />,
      Component: Attachments,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Notification Emails",
      panelLabel: "Notification Emails",
      iconType: <NotificationOutlined />,
      Component: NotificationEmails,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Docusigns",
      panelLabel: "Docusigns",
      iconType: <Icon component={AttachmentSvg} />,
      Component: DocuSign,
      menuType: "parent",
      isAllowedToShow: (documentStatus === 'documents_issued' || documentStatus === 'lease_package_received' || documentStatus === 'funding_delay') ? true : false,
    },
    {
      label: "Welcome Call",
      iconType: <PhoneOutlined />,
      Component: WelcomeCall,
      menuType: "parent",
      isAllowedToShow: allowedGetWelcomeCall,
    },
    {
      label: "Funding Delays",
      iconType: <HourglassOutlined />,
      Component: FundingDelay,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Banking",
      iconType: <BankOutlined />,
      Component: BankingInformation,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Insurance",
      iconType: <MedicineBoxOutlined />,
      Component: InsuranceInformation,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "References",
      iconType: <TeamOutlined />,
      Component: References,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Shortfund",
      iconType: <ArrowsAltOutlined />,
      Component: DealershipShortfund,
      menuType: "parent",
      isAllowedToShow: true,
    },
    {
      label: "Audits",
      iconType: <ArrowsAltOutlined />,
      Component: Audits,
      menuType: "parent",
      isAllowedToShow: true,
    }
  ].map((item, index) => { item['menuKey'] = index + 1; return item });

  const [activeMenu, setActiveMenu] = useState([1]);
  const auth_token = window.localStorage.getItem('auth_token');
  const [lesseeForm] = Form.useForm();
  const [colesseeForm] = Form.useForm();
  const activeMenuList = Menus.filter(menu => menu.isAllowedToShow);

  useEffect(() => {
    lesseeForm.name = 'lessee'
    colesseeForm.name = 'colessee'
      ;
    setActiveMenu(pinnedCollapse);
    handleUrlHash();
  }, []);

  const saveApplicationDetails = async (newData, form) => {
    setLoading(true);

    try {
      const leaseAppId = data?.id
      let response = null

      if (appNumber === null && leaseAppId !== undefined) {
        response = await PUT(`/api/v1/lease_applications/details?id=${leaseAppId}`, newData);
      } else {
        response = await PUT(`/api/v1/lease_applications/details?application_identifier=${appNumber}`, newData);
      }
      dispatch(getDetails(response?.data?.leaseApplication));
      message.success("Data saved successfully");
      form && form.resetFields();
    } catch (e) {
      logger.error("Application Details Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else if (e.response.status === 406 && (e.response.data?.message !== undefined || e.response.data?.message !== null)) {
        message.error(e.response.data?.message);
      } else {
        message.error('Error while saving your data');
      }
    }
    setLoading(false);
  };

  const handleExportAccess = async () => {
    setLoading(true);
    try {
      let payload_filter = {
        filter: {
          application_identifier_contains: appNumber
        }
      }
      await POST(`/api/v1/lease_application_reports/export_access_db`, payload_filter);
      message.success("An Access database export will be emailed to you shortly.");
    } catch (e) {
      logger.error("Export Access Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while exporting Access');
      }
    }
    setLoading(false);
  }

  const handleExportCsv = async () => {
    setLoading(true);
    try {
      await GET(`/api/v1/lease_application_reports/export_csv?application_identifier=${appNumber}`);
      message.success("A CSV export will be emailed to you shortly.");
    } catch (e) {
      logger.error("Export CSV Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while exporting CSV');
      }
    }
    setLoading(false);
  }

  const handlePoaApplicant = async () => {
    setLoading(true);
    try {
      await GET(`/api/v1/lease_application_reports/export_poa?application_identifier=${appNumber}&type=lessee`);
      message.success("A Power-of-Attorney document will be emailed to you shortly.");
    } catch (e) {
      logger.error("Export POA Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while exporting POA');
      }
    }
    setLoading(false);
  }

  const handlePoaCoApplicant = async () => {
    setLoading(true);
    try {
      await GET(`/api/v1/lease_application_reports/export_poa?application_identifier=${appNumber}&type=colessee`);
      message.success("A Power-of-Attorney document will be emailed to you shortly.");
    } catch (e) {
      logger.error("Export POA Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while exporting POA');
      }
    }
    setLoading(false);
  }

  const toggleCollapse = (label) => {
    const { menuKey } = Menus.filter(menus => menus.label === label)[0]
    setActiveMenu([menuKey])
  }


  const visitNotesByCurrentUser = async () => {
    if(data.unseenNotesCount > 0){
      try {
        await PUT(`/api/v1/comments/x/lease-applications/${data.id}/visit-notes-for-admins`, {})
        const newData = {...data}
        newData.unseenNotesCount = 0
        dispatch(clearUnseenNotesCount())
      } catch (e) {
        logger.error("Visiting Notes Error", e)
        setLoading(false)
        message.error("Error while visiting notes")
      }
    }
  }

  const dataaa = useSelector((state) => state.leaseApplications.leaseApplicationDetails);
  const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if (authData !== null) {
    loggedInUserId = authData.id;
  }

  const getApplicationDetails = async (params) => {
    const response = await fetchLeaseApplicationDetails({ id: data?.id });
    if (response) {
      if (dataaa?.creditReports?.length < response?.data?.leaseApplication?.creditReports?.length || 
        dataaa?.documentStatus !=  response?.data?.leaseApplication?.documentStatus || 
        dataaa?.leaseApplicationAttachments?.length < response?.data?.leaseApplication?.leaseApplicationAttachments?.length){
          dispatch(getDetails(response.data.leaseApplication));
      }
      if (params.isTimer == true && dataaa?.lockedStatus?.lockedById !== null && (dataaa.lockedStatus.lockedById !== response.data.leaseApplication.lockedStatus.lockedById)) {
        setLoading(false)
        if (response.data.leaseApplication.lockedStatus.status === true) {
          alert(`Your application is now locked with another admin. User Name: ${response.data.leaseApplication.lockedStatus.lockedByUser}`)
        } else {
          alert(`Your application is now unlocked By other admin, The app is now free. Please start working again.`)
        }
        dispatch(makeAppDetailsEmpty({}));
        dispatch(getDetails(response.data.leaseApplication));
        dispatch(setPinnedTabs(response.data.leaseApplication.pinnedTabs));
        setLoading(false)
      }
    }
  };

  const handleUrlHash = () => {
    if(hash !== null)
    {
      let panelHash = hash.match(/#(.*?)-p$/)
      if(panelHash && panelHash[1])
      {
        let menu = Menus.find((menuItem) => menuItem.label === panelHash[1].replace(/%20/g, ' ')) || null;
        if (menu !== null)
        {
          let x = document.getElementById('item-'+menu?.menuKey);
          x.click();
          if(menu?.menuKey == 26)
          {
            x.click();
          }
          setActiveMenu([menu?.menuKey,...pinnedCollapse])
        }
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getApplicationDetails({ isTimer: true })
    }, 30000);
    return () => clearInterval(interval);
  }, [])

  return (
    <Layout>
      <MainHeader activePage="leaseApplication" />
      <Layout>
        <Sider {...SiderProps} >
          <Menu
            defaultSelectedKeys={['1']}
            selectedKeys={[activeMenu.toString()]}
            onSelect={({ key }) => { if (!key.includes("sub")) { setActiveMenu([key]); } }}
          >
            {
              activeMenuList.map(({ label, iconType, menuType, menuKey }) => {
                return menuType === 'child' ? null : (
                  <Menu.Item
                    key={(menuKey).toString()}
                  >
                    <a href={`#${label}-p`} id= {'item-'+menuKey}>
                      {iconType}
                      {label}
                    </a>
                  </Menu.Item>
                )
              })
            }
            <Menu.Item key="divider" {...dividerStyles} >
              <Divider />
            </Menu.Item>
            <SubMenu
              key="sub1"
              {...exportStyles}
              title={
                <span>
                  <ExportOutlined />
                  <span>Export</span>
                </span>
              }
            >
              <Menu.Item key="sub11" onClick={(e) => { handleExportAccess(e) }}>Access</Menu.Item>
              <Menu.Item key="sub12" onClick={(e) => { handleExportCsv(e) }}>CSV</Menu.Item>
            </SubMenu>
            {poaPrequisites &&
              <SubMenu
                key="sub2"
                {...powerOfAttorneyStyles}
                title={
                  <span>
                    <IdcardOutlined />
                    <span>Power of Attorney</span>
                  </span>
                }
              >
                <Menu.Item key="sub21" onClick={(e) => { handlePoaApplicant(e) }}>Applicant</Menu.Item>
                {(data && data.colessee && (data.colessee.ssn !== "")) && <Menu.Item key="sub22" onClick={(e) => { handlePoaCoApplicant(e) }} >Co-Applicant</Menu.Item>}
              </SubMenu>
            }
          </Menu>
        </Sider>

        <Layout className="scroll-main-content" id='content-area-layout'> {
          data &&
          <MainBreadcrumb items={
            [
              { text: "Home", link_type: "linkto", link: "/dashboard" },
              { text: "Lease Applications", link_type: "linkto", link: "/lease-applications-list" },
              { text: `${appNumber || 'N/A'}`, link_type: "ahref", link: void (0) },
              data.expired && { text: <Tag color="red">Expired</Tag> }
            ]}
          />}
          <p>
            <a href={`${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=/admins/lease_applications/${data.id}`}>Legacy Page</a>
          </p>

          <Content id='main-content'>
            <Row gutter={[0, 24]} >
              <Col span={24} >
                <Collapse defaultActiveKey={pinnedCollapse} accordion={false} onChange={(key) => {
                  key !== undefined && setActiveMenu(key);
                  if(Array.isArray(key) && key.includes('2')){
                    visitNotesByCurrentUser()
                  }
                }} activeKey={activeMenu}>
                  {
                    activeMenuList.map(({ label, Component, panelLabel, menuKey, collapseRef }, index) => {
                      return (
                      <Panel className='slc-panel' header={
                        <Row type="flex" gutter={10}>
                          <Col>{ panelLabel || label }</Col>
                          {
                            ((panelLabel || label) === 'Notes') &&
                            <Col>
                              <Badge
                                count={data.unseenNotesCount}
                                style={{
                                  backgroundColor: '#e93b1b',
                                  color: '#white',
                                }}
                              />
                            </Col>
                          }
                      </Row> } key={menuKey} ref={collapseRef} id={`${label}-p`} extra={
                            <div onClick={e => e.stopPropagation()}>
                              <PinCollapse key={`pin-btn-${index}`} pinnedCollapse={pinnedCollapse}  menuKey={menuKey} defaultPin={pinnedCollapse.includes(menuKey)} />
                            </div>
                          }>
                          <Component
                            setLoading={setLoading}
                            appNumber={appNumber}
                            data={data}
                            disableFlag={disableFlag}
                            creditStatusOptions={creditStatusOptions}
                            declineReasonTypeOptions={declineReasonTypeOptions}
                            save={saveApplicationDetails}
                            lesseeForm={lesseeForm}
                            colesseeForm={colesseeForm}
                            allowedActions={allowedActions}
                            toggleCollapse={toggleCollapse}
                            handleNoAuth={handleNoAuth}
                          />
                        </Panel>
                      )
                    })
                  }
                </Collapse>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </Layout>
  );
}
import React from 'react';
import { useState, useEffect } from 'react';
import logger from '../../util/logger';
import { GET, POST, PUT } from "../../util/network";
// import { useDebouncedCallback } from 'use-debounce';
import { useSelector } from "react-redux";
import { Row, Col, Button, Form, Input, Typography, Layout, message, Spin, Alert } from 'antd';
import '../../components/layouts/styles/Calculator.css'
import CalculatorHead from '../../components/layouts/CalculatorHead';
import CustomerAndBikeInformation from "./Components/Calculator/CustomerAndBikeInformation";
import SalesEconomics from "./Components/Calculator/SalesEconomics";
import PaymentTerms from "./Components/Calculator/PaymentTerms";
import CalculatorHeader from "./Components/Calculator/CalculatorHeader";
import { Card, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
const { Title } = Typography;
const { Content } = Layout;


const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

export const Calculator = (props) => {
  const { calculateWithoutApp } = props
  const dataFromState = useSelector((state) => state?.leaseApplications?.applicationDetails)
  const data = calculateWithoutApp === true ? { leaseCalculator: { cashDownPayment: 0 }} : dataFromState;
  const [lesseeForm] = Form.useForm();

  const [calculatorData, setCalculatorData] = useState({})

  const [activeStatesOptions, setActiveStatesOptions] = useState([])
  const [taxJurisdictionLabel, setTaxJurisdictionLabel] = useState("Customer County/Tax Jurisdiction")
  const [taxJurisdictionOptions, setTaxJurisdictionOptions] = useState([])

  const [makesOptions, setMakesOptions] = useState([])
  const [yearsOptions, setYearsOptions] = useState([])
  const [modelsOptions, setModelsOptions] = useState([])
  const [mileageRangeOptions, setMileageRangeOptions] = useState([])
  const [creditTierOptions, setCreditTierOptions] = useState(data?.leaseCalculator?.creditTiers || [])

  const [showMakeState, setShowMakeState] = useState(null)
  const [showYearState, setShowYearState] = useState(null)
  const [showModelState, setShowModelState] = useState(null)
  const [showMileageRangeState, setShowMileageRangeState] = useState(null)
  const [showCreditTierState, setShowCreditTierState] = useState(null)

  const [cashDownPaymentDanger, setCashDownPaymentDanger] = useState('black')
  const [backendProductsColor, setBackendProductsColor] = useState('black')
  const [calculatorAttributes, setCalculatorAttributes] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isNoDocument, setIsNoDocument] = useState(true)
  const [maximumTermLength, setMaximumTermLength] = useState()
  const [taxLink, setTaxLink] = useState(false)
  const [customerMonthlyPaymentDanger, setCustomerMonthlyPaymentDanger] = useState('black')
  const [creditTierDisableFlag, setCreditTierDisableFlag] = useState(false)
  const [gpsCostColor, setGpsCostColor] = useState('black')
  const [open, setOpen] = useState(false);


  const [alerts, setAlerts] = useState([]);

  const addAlert = (type, message) => {
    setAlerts([...alerts, { type, message }]);
  };
  const removeAlert = (message) => {
    const updatedAlerts = [...alerts];
    let filteredAlerts = updatedAlerts.filter(item => item.message !== message);
    setAlerts(filteredAlerts);
  };
  // const debouncedHandleOnFieldsChange = useDebouncedCallback(
  //   (changedValues, allValues) => {
  //     handleOnValuesChange(changedValues, allValues);
  //   },
  //   1000
  // )

  const GeorgiaTaxLink = () => {
    return (
      <div>
        <a href="https://eservices.drives.ga.gov/?Link=TAVTEst" target="_blank" rel="noreferrer"><u>Tax Amount from Georgia Drives</u></a>
      </div>
    )
  }

  const handleMakes = (value) => {
    getYears(value)
  }

  const handleYear = (value) => {
    let year = value
    let make = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetMake'])
    getModels(make, year)
  }

  const handleModel = (value) => {
    let model = value
    let make = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetMake'])
    let year = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetYear'])
    if (make !== null && year !== null && model !== null) {
      getCreditTiers(make, year, model)
    }
  }
  const hideBikeSelectOptions = () => {
    setShowMakeState(null)
    setShowYearState(null)
    setShowModelState(null)
    setShowMileageRangeState(null)
    setShowCreditTierState(null)
  }

  const handleNewUsedStateChange = () => {
    hideBikeSelectOptions()
    setShowMakeState({ "open": true })
  }

  const handleMakesStateChange = () => {
    lesseeForm.setFieldsValue({
      leaseCalculatorAttributes: {
        assetYear: "",
        assetModel: "",
      }
    })
    hideBikeSelectOptions()
    setTimeout(() => {
      setShowYearState({ "open": true })
    }, 500)
  }

  const handleYearStateChange = () => {
    hideBikeSelectOptions()
    lesseeForm.setFieldsValue({
      leaseCalculatorAttributes: {
        assetModel: "",
      }
    })
    setTimeout(() => {
      setShowModelState({ "open": true })
    }, 500);
  }

  const handleModelStateChange = (value) => {
    hideBikeSelectOptions()
    setShowMileageRangeState({ "open": true })
    const assetModel = modelsOptions.find(model => model.value === value)
    const termLength = (assetModel && assetModel?.maximumTermLength) || 60
    setMaximumTermLength(termLength)
    lesseeForm.setFieldsValue({
      leaseCalculatorAttributes: {
        term: termLength,
      },
    })
  }

  const handleMileageRangeStateChange = () => {
    hideBikeSelectOptions()
    setShowCreditTierState({ "open": true })
  }
  const alertMsg = (val1, val2, message) => {
    let hasAlready = alerts.some(x => x.message == message)
    if (val1 < val2) {
      if(!hasAlready){
        addAlert('error', message)
      }
    } else {
      if (hasAlready) {
        removeAlert(message)
      }
    }
  }

  const cashDownPaymentError = () =>{
    let cashDownPayment = parseInt(lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'cashDownPayment']))
    let minimumRequired = parseInt(calculatorData.minimumRequired)
    alertMsg(cashDownPayment, minimumRequired, "Down Payment must meet or exceed Minimum Required")
  }

  const backendTotalError = (backendProductsSum) =>{
    let backendMaxAdvance = parseInt(calculatorData.backendMaxAdvance)
    alertMsg(backendMaxAdvance, backendProductsSum, "Backend total must not exceed Backend Maximum")
  }

  const handleCashDownPaymentChange = (value) => {
    let color = typeof value === 'number' &&
      typeof calculatorData.minimumRequired === 'string' &&
      parseInt(String(value)) < parseInt(calculatorData.minimumRequired) ? 'red' : 'black'
    setCashDownPaymentDanger(color)
    cashDownPaymentError()
  }

  const handleCustomerMonthlyPaymentChange = (value) => {
    if (value === 0) {
      return 'black'
    }
    let color = (typeof value === 'number' && typeof calculatorData.totalMonthlyPayment === 'string' &&
      parseFloat(calculatorData.totalMonthlyPayment) > value) ? 'red' : 'black'

    setCustomerMonthlyPaymentDanger(color)
  }

  const handleBackendProductsChange = (values) => {
    let backendProductsSum = 0;
    backendProductsSum += typeof values?.guaranteedAutoProtectionCost === 'number' ? values.guaranteedAutoProtectionCost : 0
    backendProductsSum += typeof values?.prepaidMaintenanceCost === 'number' ? values.prepaidMaintenanceCost : 0
    backendProductsSum += typeof values?.extendedServiceContractCost === 'number' ? values.extendedServiceContractCost : 0
    backendProductsSum += typeof values?.tireAndWheelContractCost === 'number' ? values.tireAndWheelContractCost : 0
    backendProductsSum += typeof values?.gpsCost === 'number' ? values.gpsCost : 0

    let color = typeof calculatorData.backendMaxAdvance === 'string' &&
      backendProductsSum > parseInt(calculatorData.backendMaxAdvance) ? 'red' : 'black'
    setBackendProductsColor(color)
    let gpsCostColor = 'black';
    let gpsCost = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'gpsCost'])
    if (typeof gpsCost === 'number' && gpsCost !== 0 && (gpsCost < 195 || gpsCost > 500)){
      gpsCostColor = 'red';
    }
    gpsCostColor = gpsCostColor === 'red' ? gpsCostColor : color
    setGpsCostColor(gpsCostColor)
    backendTotalError(backendProductsSum)
  }

  const closeDialog = () => {
    setOpen(false);
    setGpsCostColor('black');
  }

  const gpsValueCorrection = (values) => {
    let gpsCost = values?.leaseCalculatorAttributes?.gpsCost;
    if (typeof gpsCost === 'number' && gpsCost !== 0 && (gpsCost < 195 || gpsCost > 500)){
      setOpen(true);
      values.leaseCalculatorAttributes.gpsCost = 0
      lesseeForm.setFieldsValue({
        leaseCalculatorAttributes: {
          gpsCost: 0
        },
      });
      return values;
    } else {
      return values;
    }
  }

  const getMakes = async () => {
    try {
      let result = await GET('/api/v1/bike-information/makes-name-options');
      setMakesOptions(formatOptions(result.data.makes || []))
      setMileageRangeOptions(formatOptions((result.data.mileage_range || [])))
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const getYears = async (make) => {
    try {
      let result = await GET(`/api/v1/bike-information/years-options?make=${make}`);
      setYearsOptions(formatOptions(result.data.years || []))
      const creditTierValues = result.data.credit_tiers.map((ob) => ob.value.trim())
      if(data?.leaseCalculator?.creditTierId && data?.leaseCalculator.creditTierId !== null){
        const value = data?.leaseCalculator?.creditTiers[0].value.trim()
        if(!creditTierValues.includes(value))
        {
          lesseeForm.setFieldsValue({
            leaseCalculatorAttributes: {
              creditTierId: '',
            },
          })
        }
      }
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const getModels = async (make, year) => {
    try {
      let result = await GET(`/api/v1/bike-information/models-options?make=${make}&year=${year}&calculator_id=${data?.leaseCalculator?.id}`);
      setModelsOptions(formatOptions((result.data.models || []), 'collection', 'assetModel'))
      let modelValue = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetModel'])
      if (modelValue !== null) {
        const assetModels = formatOptions((result.data.models || []), 'collection', 'assetModel')
        const assetModel = assetModels.find(model => model.value === modelValue)
        const termLength = (assetModel && assetModel?.maximumTermLength) || 60
        setMaximumTermLength(termLength)
      }
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const getCreditTiers = async (make, year , model) => {
    try {
      let result = await GET(`/api/v1/bike-information/credit-tier-options?make=${make}&year=${year}&model=${model}&calculator_id=${data?.leaseCalculator?.id}`);
      const creditTiers = result.data.credit_tiers
      const creditTierValues = result.data.credit_tiers.map((ob) => ob.value.trim())
      if(data?.leaseCalculator?.creditTierId && data?.leaseCalculator.creditTierId !== null){
        const value = data?.leaseCalculator?.creditTiers[0].value.trim()
        if(creditTierValues.includes(value))
        {
          const index = creditTiers.findIndex((ob) => ob.value === value)
          lesseeForm.setFieldsValue({
            leaseCalculatorAttributes: {
              creditTierId: creditTiers[index].id,
            },
          })
        }
        else
        {
          lesseeForm.setFieldsValue({
            leaseCalculatorAttributes: {
              creditTierId: '',
            },
          })
          setCreditTierDisableFlag(false)
        }
      }
      setCreditTierOptions(result.data.credit_tiers || [])
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const formatOptions = (options, type, attributeType) => {
    let newOptions= [];
    if (type === 'collection' && attributeType === 'assetModel') {

      options.map((value) => { newOptions.push({ value: value[1], label: value[0], maximumTermLength: value[2]['data-maximum-term-length'] }) })
    }
    else if (type === 'collection') {
      options.map((value) => { newOptions.push({ value: value[1], label: value[0] }) })
    } else {
      options.map((value) => { newOptions.push({ value: value, label: value }) })
    }
    return newOptions
  }

  const handleDealershipStateChange = (value, option) => {
    if (option.value === "georgia") {
      setTaxLink(true)
    } else {
      setTaxLink(false)
    }
    getTaxJurisdiction(value)
    setTaxJurisdictionLabel(option.taxLabel)
    getMakes()
    lesseeForm.setFieldsValue({
      leaseCalculatorAttributes: {
        taxJurisdiction: null,
      },
    })
  }

  const getTaxJurisdiction = async (usState) => {
    try {
      await GET(`/api/v1/calculators/tax-jurisdiction-select-option?us_state=${usState}`).then(response => {
        setTaxJurisdictionOptions(response.data.tax_jurisdiction_select_option)
      }).catch(error => {
        logger.error("getTaxJurisdiction.Request Error", error);
      });
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const setDealershipState = async () => {
    try {
      await GET('/api/v1/calculators/active-state-select-option').then(response => {
        setActiveStatesOptions(response.data.active_state_select_option)
      }).catch(error => {
        logger.error("setDealershipState.Request Error", error);
      });
    } catch (e) {
      logger.error("setDealershipState Error", e);
    }
  }

  const setCalculatorFromResult = (responseObj) => {
    let calculations = responseObj.data.calculations
    let calData= {
      nadaRental: calculations.nada_retail_value || 0,
      nadaRough: calculations.nada_rough_value || 0,
      nadaAdjustPercentage: calculations.nada_adjustment_percentage || 0,
      nadaOrizinal: calculations.nada_retail_value_original || 0,
      preTaxPayment: calculations.pre_tax_payments_sum || 0,
      purchaseOption: calculations.customer_purchase_option || 0, // NEEDS TRACING
      upfrontTax: calculations.upfront_tax || 0,
      totalBikePrice: calculations.total_sales_price || 0, // NEEDS TRACING
      netTradeInAllowance: calculations.net_trade_in_allowance || 0,
      totalCapCostReduction: 0, // NO EXACT MATCH
      netDueOnMtorcycle: 0, // NO EXACT MATCH
      acquisitionFee: calculations.acquisition_fee || 0,
      totalCapCost: calculations.adjusted_capitalized_cost || 0, // NEEDS TRACING
      totalGrossCapCost: calculations.gross_capitalized_cost || 0, // NEEDS TRACING
      baseMonthlyPayment: calculations.base_monthly_payment || 0,
      monthlySaleTax: calculations.monthly_sales_tax || 0,
      totalMonthlyPayment: calculations.total_monthly_payment || 0,
      firstMonthlyPayment: calculations.total_monthly_payment || 0, // NEEDS TRACING
      refundableSecurityDeposit: calculations.refundable_security_deposit || 0,
      additionalCashDown: 0, // NEEDS TRACING
      totalCashAtSignIn: calculations.total_cash_at_signing || 0,
      cashIn: 0, // NO EXACT MATCH
      bikeMinimum: 0, // NO EXACT MATCH
      totalDealerParticipation: 0, // NO EXACT MATCH
      totalTransactionPrice: 0, // NO EXACT MATCH
      minusTradeIn: 0, // NO EXACT MATCH
      minusDownPayment: 0, // NO EXACT MATCH
      minusFirstMonthlyPayment: 0, // NO EXACT MATCH
      minuseSecurityDeposit: 0, // NO EXACT MATCH
      cashOnDeliveryBike: 0, // NO EXACT MATCH
      plusDealerParticipation: 0, // NO EXACT MATCH
      remitToDealer: calculations.remit_to_dealer || 0,
      frontEndMaxAdvance: calculations.frontend_max_advance || 0,
      backendMaxAdvance: calculations.backend_max_advance || 0,
      minimumRequired: calculations.cash_down_minimum || 0,
      backendTotal: calculations.backend_total || 0,
      cashDownPayment: calculations.cash_down_payment || 0,
      adjustedCapitalizedCost: calculations.adjusted_capitalized_cost|| 0
    }
    setCalculatorData(calData)
  }

  const submitCalculator = async (values, save) => {
    try {
      if (save) {
        let new_values = {};
        delete Object.assign(new_values, values, { ['lease_calculator']: values['leaseCalculatorAttributes'] })['leaseCalculatorAttributes'];
        setLoading(true);
        await POST('/api/v1/calculators/save', new_values).then(response => {
          setLoading(false);
          message.success("Data saved successfully")
        }).catch(() => {
          setLoading(false);
          message.error("Error while saving data")
        })
      }
      else {
        await POST('/api/v1/calculators', values).then(response => {
          setCalculatorFromResult(response)
        }).catch(() => {
        })
      }
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const handleSubmit = async (values) => {
    let obj = {
      nadaRetailValue: calculatorData.nadaRental
    }
    values = { ...values.leaseCalculatorAttributes, ...obj };
    values = { leaseCalculatorAttributes: { ...values } }
    values = gpsValueCorrection(values);
    submitCalculator(values, true)
  }

  const handleOnValuesChange = async (changedValues, allValues) => {
    let values = {}

    allValues.map((data) => {
      values[`${data.name[1]}`] = data.value
    })

    setCalculatorAttributes(values);
    handleBackendProductsChange(values);
    submitCalculator({ lease_calculator: values }, false)
  }

  useEffect(() => {
    setDealershipState()
    let usState = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'usState'])
    if(usState && usState !== undefined) {
      getTaxJurisdiction(usState)
    }

    let taxJurisdictionType = data?.leaseCalculator?.stateTaxJurisdictionType
    if (typeof taxJurisdictionType === 'string') {
      setTaxJurisdictionLabel(taxJurisdictionType)
    }
    getMakes()
    getYearsAndModels()
    getYearsAndModelsAndCreditTiers()
    setNoDocumentsState()
    // californiaTaxJurisdictionFieldSet()
    clearUnnecessaryAttributesAndCalculate(usState)
  }, []);

  const getYearsAndModels = () => {
    let make = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetMake'])
    let year = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetYear'])
    if (make !== null) {
      getYears(make)
      if (year !== null) {
        getModels(make, year)
      }
    }
  }

  const getYearsAndModelsAndCreditTiers = () => {
    let make = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetMake'])
    let year = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetYear'])
    let model = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetModel'])
    if (make !== null && year !== null && model !== null) {
      getCreditTiers(make, year, model)
    }
  }

  const setNoDocumentsState = () => {
    if (calculateWithoutApp) {
      setIsNoDocument(false)
    } else {
        setIsNoDocument(true)
    }
  }

  const californiaTaxJurisdictionFieldSet = () => {
    if (data?.leaseCalculator?.usState == "california") {
      if (typeof data?.lessee?.homeAddress?.newCity?.city === "string" && typeof data?.lessee?.homeAddress?.newCity?.county === "string") {
        lesseeForm.setFieldsValue({
          leaseCalculatorAttributes: {
            taxJurisdiction: californiaTaxJurisdiction()
          },
        })
      }
    }
  }

  const californiaTaxJurisdiction = () => {
    return `${data?.lessee?.homeAddress?.newCity?.city?.charAt(0).toUpperCase()}${data?.lessee?.homeAddress?.newCity?.city?.slice(1).toLowerCase()} -
          ${data?.lessee?.homeAddress?.newCity?.county?.charAt(0).toUpperCase()}${data?.lessee?.homeAddress?.newCity?.county?.slice(1).toLowerCase()}`
  }

  const clearUnnecessaryAttributesAndCalculate = async (state) => {
    let creditTiers = data?.leaseCalculator?.creditTiers
    if (creditTiers) {
      let newData = { ...data }
      if (typeof newData?.leaseCalculator !== 'undefined' && typeof state === 'string') {
        const { creditTiers, stateTaxJurisdictionType, maxAllowablePayment, ...rest } = newData.leaseCalculator;
        newData.leaseCalculator = rest;
        submitCalculator(newData, false)
      }
    }
  }

  useEffect(() => {
    if (calculatorAttributes === null) {
      handleBackendProductsChange(data?.leaseCalculator)
      handleMaximumTermLength()
    }
    let cashDownPayment = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'cashDownPayment'])
    handleCashDownPaymentChange(cashDownPayment)
    handleCustomerMonthlyPaymentChange(data?.leaseCalculator?.maxAllowablePayment)
  }, [calculatorData]);

  const handleMaximumTermLength = () => {
    let modelValue = lesseeForm.getFieldValue(['leaseCalculatorAttributes', 'assetModel'])
    if (modelValue !== null) {
      const assetModel = modelsOptions.find(model => model.value === modelValue)
      const termLength = (assetModel && assetModel?.maximumTermLength) || 60
      lesseeForm.setFieldsValue({
        leaseCalculatorAttributes: {
          term:  data?.leaseCalculator?.term || termLength
        },
      })
    }
  }


  const convertUsd = (value) => {
    return (value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }

  const openInNewTab = () => {
    window.open('https://secure.passtimeusa.com/onlineordering/codesite/SpeedLeasing.aspx?otag=SLF', '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <CalculatorHead calculateWithoutApp={calculateWithoutApp === true ? true : false} />
      <Spin
        spinning={loading}
        size="large"
        tip="Loading..."
      >
        <div>
          <CalculatorHeader data={data} calculateWithoutApp={calculateWithoutApp} openInNewTab={openInNewTab} />
          <Form
            form={lesseeForm}
            onFinish={handleSubmit}
            onFieldsChange={(changedValues, allValues) => handleOnValuesChange(changedValues, allValues)}
            initialValues={{
              leaseCalculatorAttributes: {
                id: data?.leaseCalculator?.id,
                usState: data?.leaseCalculator?.usState,
                taxJurisdiction: data?.leaseCalculator?.taxJurisdiction,
                newUsed: data?.leaseCalculator?.newUsed,
                assetMake: data?.leaseCalculator?.assetMake,
                assetYear: data?.leaseCalculator?.assetYear.toString(),
                assetModel: data?.leaseCalculator?.assetModel,
                mileageTier: data?.leaseCalculator?.mileageTier,
                creditTierId: data?.leaseCalculator?.creditTierId,
                dealerSalesPrice: data?.leaseCalculator?.dealerSalesPrice,
                dealerFreightAndSetup: data?.leaseCalculator?.dealerFreightAndSetup,
                titleLicenseAndLienFee: data?.leaseCalculator?.titleLicenseAndLienFee,
                dealerDocumentationFee: data?.leaseCalculator?.dealerDocumentationFee,
                guaranteedAutoProtectionCost: data?.leaseCalculator?.guaranteedAutoProtectionCost,
                prepaidMaintenanceCost: data?.leaseCalculator?.prepaidMaintenanceCost,
                extendedServiceContractCost: data?.leaseCalculator?.extendedServiceContractCost,
                tireAndWheelContractCost: data?.leaseCalculator?.tireAndWheelContractCost,
                gpsCost: data?.leaseCalculator?.gpsCost,
                grossTradeInAllowance: data?.leaseCalculator?.grossTradeInAllowance,
                tradeInPayoff: data?.leaseCalculator?.tradeInPayoff,
                cashDownPayment: data?.leaseCalculator?.cashDownPayment,
                gaTavtValue: data?.leaseCalculator?.gaTavtValue,
              }
            }}
            className="form-calculator"
            {...layout}
          >
            {
              data?.leaseCalculator?.id &&
              <Form.Item
                style={{ display: 'none' }}
                name={['leaseCalculatorAttributes', 'id']}
              >
                <Input />
              </Form.Item>
            }
            <Content className="content-3">
              <Row gutter={16} style={{marginBottom: "10px", marginTop: "10px"}}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div>
                    {alerts.map((alert, index) => (
                      <Alert
                        key={index}
                        message={alert.message}
                        type={alert.type}
                      />
                    ))}
                  </div>
                </Col>
              </Row>
              
              <div className="site-card-wrapper">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <CustomerAndBikeInformation
                      handleDealershipStateChange={handleDealershipStateChange}
                      activeStatesOptions={activeStatesOptions}
                      taxJurisdictionLabel={taxJurisdictionLabel}
                      taxJurisdictionOptions={taxJurisdictionOptions}
                      handleNewUsedStateChange={handleNewUsedStateChange}
                      hideBikeSelectOptions={hideBikeSelectOptions}
                      handleMakesStateChange={handleMakesStateChange}
                      handleMakes={handleMakes}
                      showMakeState={showMakeState}
                      makesOptions={makesOptions}
                      handleYear={handleYear}
                      handleYearStateChange={handleYearStateChange}
                      showYearState={showYearState}
                      yearsOptions={yearsOptions}
                      handleModelStateChange={handleModelStateChange}
                      showModelState={showModelState}
                      modelsOptions={modelsOptions}
                      handleMileageRangeStateChange={handleMileageRangeStateChange}
                      showMileageRangeState={showMileageRangeState}
                      mileageRangeOptions={mileageRangeOptions}
                      showCreditTierState={showCreditTierState}
                      creditTierOptions={creditTierOptions}
                      data={data}
                      maximumTermLength={maximumTermLength}
                      calculatorData={calculatorData}
                      convertUsd={convertUsd}
                      backendProductsColor={backendProductsColor}
                      GeorgiaTaxLink={GeorgiaTaxLink}
                      taxLink={taxLink}
                      lesseeForm={lesseeForm}
                      creditTierDisableFlag={creditTierDisableFlag}
                      calculateWithoutApp={calculateWithoutApp}
                      handleModel={handleModel}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <SalesEconomics data={data} calculatorData={calculatorData} convertUsd={convertUsd} backendProductsColor={backendProductsColor} gpsCostColor={gpsCostColor}/>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <PaymentTerms
                      calculatorData={calculatorData}
                      convertUsd={convertUsd}
                      cashDownPaymentDanger={cashDownPaymentDanger}
                      handleCashDownPaymentChange={handleCashDownPaymentChange}
                      maxAllowablePayment={data?.leaseCalculator?.maxAllowablePayment}
                      customerMonthlyPaymentDanger={customerMonthlyPaymentDanger}
                    />
                    <div style={{ marginTop: '30px', textAlign: 'right' }}>
                      {
                        !data?.leaseCalculator?.locked && (<Button style={{ backgroundColor: "#e93b1b", color: 'white' }} htmlType="submit">Save Lease Calculator</Button>)
                      }
                    </div>
                    <Dialog
                        open={open}
                        onClose={closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle style={{color: '#fa4b65', paddingTop: '50px', paddingLeft: '50px', paddingRight: '50px'}}>
                        {"If including a fee for PassTime InTouch Services, fee must be between $195.00 and $500.00."}
                      </DialogTitle>
                      <DialogActions style={{paddingRight: '50px', paddingBottom: '50px'}}>
                        <Button onClick={closeDialog}
                                style={{backgroundColor: '#5c94ed', color: '#ffffff', borderRadius: '10%'}}
                        >OK</Button>
                      </DialogActions>
                    </Dialog>
                  </Col>
                </Row>

                {
                  calculateWithoutApp && ( 
                  <div style={{textAlign: 'center', position: "relative"}}>
                    <h5 style={{color: "#e93b1b"}}> Questions? Please contact Dealer Support at <b>1-844-221-0102</b> (option 1 for Dealer) or <a href="mailto:support@speedleasing.com">support@speedleasing.com</a> </h5>
                  </div>)
                }

                <Row>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Card style={{ margin: "10px", padding: "10px" }}>
                         <Title level={4} className="calculator-headers">Funding Basis</Title>

                      <Row className="bot-spacer-1" style={{ lineHeight: 1.2 }}>
                        <Col span={12}>
                        NADA Retail Value
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                          <span>{calculatorData.nadaRental && convertUsd(Number(calculatorData.nadaRental))}</span>
                        </Col>
                      </Row>
                      <Row className="bot-spacer-1" style={{ lineHeight: 1.2 }}>
                        <Col span={24}>
                        (NADA Adjustment: {calculatorData.nadaAdjustPercentage}%, Original: {calculatorData.nadaOrizinal})
                        </Col>
                      </Row>
                      <Row className="bot-spacer-1" style={{ lineHeight: 2 }}>
                        <Col span={16}>
                        NADA Rough Value
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                          <span>{calculatorData.nadaRough && convertUsd(Number(calculatorData.nadaRough))}</span>
                        </Col>
                      </Row>
                      <Row className="bot-spacer-1" style={{ lineHeight: 2 }}>
                        <Col span={16}>
                        Residual Value (Purchase Option)
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                          <span>{calculatorData.purchaseOption && convertUsd(Number(calculatorData.purchaseOption))}</span>
                        </Col>
                      </Row>
                      <Row className="bot-spacer-1" style={{ lineHeight: 2 }}>
                        <Col span={16}>
                        Adj. Capitalized Cost
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                          <span>{calculatorData.totalCapCost && convertUsd(Number(calculatorData.totalCapCost))}</span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={16}>
                    {
                      typeof data !== 'undefined' && data.displayStipulations && Array.isArray(data.stipulations) && (data.stipulations.length > 0) &&
                      <Card style={{ margin: "10px", padding: "10px" }}>
                        <Title className="calculator-headers" level={4}>STIPULATIONS STILL REQUIRED FOR THIS DEAL INCLUDE</Title>
                        {
                          data?.leaseApplicationStipulations?.map((leaseApplicationStipulations, index) => {
                            return (leaseApplicationStipulations.status !== 'Cleared' && leaseApplicationStipulations.status !== 'Not Required') ? (<Row className={'bot-spacer-1'} style={{ lineHeight: 2 }}>
                              <Col span={24} style={{ color: '#1890ff' }}>
                                {leaseApplicationStipulations.stipulation}{leaseApplicationStipulations.notes}
                              </Col>
                            </Row>) : <></>
                          })
                        }
                      </Card>
                    }
                  </Col>
                </Row>
              </div>
            </Content>
          </Form>
        </div>
      </Spin>
    </>
  )
}
export default Calculator

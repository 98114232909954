import React from 'react'
import { Row, Col, Form, Input, Radio, Button, Typography } from 'antd';
const { Text } = Typography
const { TextArea } = Input;
export default function FooterInfo(props) {
    const { data, formatDate, generatePdf, displayGeneratePdfButton } = props;

    return (
        <>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text strong level={4} className="vcc-text">
                        Great! Please be aware your payments are not contingent upon the vehicle’s operation.
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text strong level={4} className="vcc-text">
                        You are always responsible for to make the monthly payment each month until the account is closed.
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text strong level={4} className="vcc-text">
                        If you have any questions in the future, our Customer Service phone number is 844-390-0717.
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text strong level={4} className="vcc-text">
                        I appreciate you taking the time to speak with me. Enjoy your ride.
                    </Text>
                </Col>
            </Row>

            <Row gutter={[8, 8]} className="vcc-header-row">
                <Col span={24}>
                    <Text level={4} className="vcc-header-title">Notes</Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Form.Item
                        name={["notes"]}
                        className='vcc-form-item'
                    >
                        <TextArea
                            autoSize={{ minRows: 5, maxRows: 10 }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-header-row">
                <Col span={24}>
                    <Form.Item>
                        <Button htmlType="submit" type="primary" size="large">Submit</Button>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 8]} className="vcc-header-row">
                <Col span={24}>
                    <Text level={4} className="vcc-header-title">Tracking</Text>
                </Col>
            </Row>
            <Row gutter={[8, 32]} >
                <Col span={6}>
                    <Text type="secondary">Completed On</Text>
                    <Row>
                        <Text level={4} className="vcc-info">{formatDate(data.updatedAt)}</Text>
                    </Row>
                </Col>
                <Col span={6}>
                    <Text type="secondary">Completed By</Text>
                    <Row>
                        <Text level={4} className="vcc-info">{data.completedBy}</Text>
                    </Row>
                </Col>
            </Row>
            {displayGeneratePdfButton &&
                <Row gutter={[8, 8]} className="vcc-header-row">
                    <Col span={6}>
                        <Button type="primary" size="large" onClick={(e) => { generatePdf(e) }}>Generate PDF</Button>
                    </Col>
                </Row>
            }
        </>
    )
}

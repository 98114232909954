import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {message, Spin} from "antd";
import DealerUserList from "./DealerUserList";
import {GetResourceActions, IsActionAllowed} from "../../util/AllowedActions";
import {
  fetchDealerusers,
  fetchDealershipOptions,
} from "../../reducers/DealerUsers";
import {Layout} from "antd";
import MainHeader from "../../components/layouts/MainHeader";
import MainFooter from "../../components/layouts/MainFooter";

export default function DealerUsersRenderer(props) {
  const {allowedActions} = props;
  const dealershipActions = GetResourceActions("Dealership", allowedActions);
  const allowedGet = IsActionAllowed("get", dealershipActions);
  const [loading, setLoading] = useState(false);

  //redux
  const dispatch = useDispatch();
  const dealerData = useSelector((state) => state.dealerUsers.dealerList);
  const pageState = useSelector((state) => state.dealerUsers.pageState);
  let isLoading = useSelector((state) => state.dealerUsers.isLoading);
  const dealershipOptions = useSelector((state) => state.dealerUsers.dealerListOptions);

  useEffect(() => {
    dispatch(fetchDealerusers({pageState: pageState}));
    if (!dealershipOptions.length > 0) {
      dispatch(fetchDealershipOptions());
    }
  }, [pageState]);

  return (
    <Layout>
      <MainHeader activePage="dealers"/>
      <Layout>
        <Spin spinning={loading === true ? true : isLoading}>
          <DealerUserList dealerData={dealerData.dealers} allowedGet={allowedGet} total={dealerData.total}
                          pageState={pageState} setLoading={setLoading}/>
        </Spin>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}

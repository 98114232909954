import React, { useState, useEffect } from 'react'
import { GET, PUT } from "../../util/network";
import MainHeader from "../../components/layouts/MainHeader";
import MainFooter from "../../components/layouts/MainFooter";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import { message, Spin } from "antd";
import LesseeView from "./LesseeView";

export default function LesseeRenderer(props) {
    const { id } = props.match.params;
    const { handleNoAuth } = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getLessee = async () => {
        setLoading(true)
        try {
          const response = await GET(`/api/v1/lessees/${id}`);
          setData(response?.data.data);
        } catch (e) {
          message.error("Error while loading application welcome call.")
        }
        setLoading(false)
      }

    useEffect(() => {
        getLessee();
    }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading..." >
        <MainHeader activePage="lesseeRelated" />
            {
                data && 
                <LesseeView
                data = {data}
                />
            }
        <MainFooter />
    </Spin>
  )
}
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css'
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import Store from './Store';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { fetchEnvelopes } from './reducers/Docusign'
import { fetchPendingWelcomeCalls } from './reducers/PendingWelcomeCalls'
import { fetchAdminusers } from './reducers/Adminusers'
import { fetchNotifications } from './reducers/Notifications'

const auth_token = window.localStorage.getItem('auth_token');
async function main() {
  
  // Store.dispatch(fetchEnvelopes())
  // Store.dispatch(fetchPendingWelcomeCalls())
  Store.dispatch(fetchAdminusers())
  const dispatchFetchNotifications = () => {
    if(auth_token){
      Store.dispatch(fetchNotifications());
    }
  };

  dispatchFetchNotifications(); // Initial dispatch

  setInterval(dispatchFetchNotifications, 30000); // Dispatch in every 30 seconds

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={Store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}
main()

serviceWorker.unregister();

import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { updateFilterState} from '../../../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";

export default function DealershipGroup(props) {
    const { filterDefaultData, filterState, newFilterState, setNewFilterState } = props;

    return (
        <>
            <ListItem >
                <StyledAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    options={filterDefaultData.dealerUser}
                    value={newFilterState.dealerUseObj}
                    getOptionLabel={option => option.name}
                    renderInput={(params) => <TextField   {...params} size="small" label="Dealer User" />}
                    onChange={(event, value) => {
                      let dealerUserId = (value?.id === "undefined" || value?.id === undefined) ? null : value?.id
                      setNewFilterState({ ...newFilterState, dealerUser: dealerUserId, dealerUseObj: value })
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOptionBox>{option.name}</StyledOptionBox>
                        </li>
                    )}
                />
            </ListItem>
            <ListItem >
                <StyledAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    options={filterDefaultData.dealership}
                    value={newFilterState.dealershipObj}
                    getOptionLabel={option => option.name}
                    renderInput={(params) => <TextField   {...params} size="small" label="Dealership" />}
                    onChange={(event, value) => {
                      let dealerId = (value?.id === "undefined" || value?.id === undefined) ? null : value?.id
                      setNewFilterState({ ...newFilterState, dealer: dealerId, dealershipObj: value })
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOptionBox>{option.name}</StyledOptionBox>
                        </li>
                    )}
                />
            </ListItem>
            <ListItem >
                <StyledAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    options={filterDefaultData.representative}
                    getOptionLabel={option => option.name}
                    value={newFilterState.dealerRepresentativeObj}
                    renderInput={(params) => <TextField   {...params} size="small" label="Representative" />}
                    onChange={(event, value) => {
                      let dealerRepresentativeId = (value?.id === "undefined" || value?.id === undefined) ? null : value?.id
                      setNewFilterState({ ...newFilterState, dealerRepresentative: dealerRepresentativeId, dealerRepresentativeObj: value })

                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOptionBox>{option.name}</StyledOptionBox>
                        </li>
                    )}
                />
            </ListItem>
            <ListItem >
                <StyledAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    value={newFilterState.dealerState}
                    options={filterDefaultData.states}
                    getOptionLabel={option => option}
                    renderInput={(params) => <TextField   {...params} size="small" label="Dealer State" />}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, dealerState: value })
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOptionBox>{option}</StyledOptionBox>
                        </li>
                    )}
                />
            </ListItem>
            <ListItem >
                <StyledAutoComplete
                    disablePortal
                    id="combo-box-demo"
                    value={newFilterState.stipulationObj}
                    options={filterDefaultData.stipulation}
                    getOptionLabel={option => option.name}
                    renderInput={(params) => <TextField   {...params} size="small" label="Stipulation" />}
                    onChange={(event, value) => {
                      let stipulationId = (value?.id === "undefined" || value?.id === undefined) ? null : value?.id
                      setNewFilterState({ ...newFilterState, stipulation: stipulationId, stipulationObj: value })
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <StyledOptionBox>{option.name}</StyledOptionBox>
                        </li>
                    )}
                />
            </ListItem>
        </>
    )
}

const StyledAutoComplete = styled(Autocomplete)({
    width: 350,
    marginLeft: 1
});

const StyledOptionBox = styled(Box)({
    "&:hover": {
        color: "#e93b1b",
        lineHeight: 1.5,
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationcolor: '#e93b1b',
        textDecorationThickness: "2px",
        fontWeight: "500"
    },
    "&:focus": {
        borderColor: "#ef4829a8 !important"
    },
});

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Form, Input, message, Row, Table, Layout, Spin } from 'antd';
import { POST, GET } from '../../../util/network';
import logger from '../../../util/logger';

export default function DocuSign({ data, handleNoAuth }) {
  const history = useHistory();
  const [visibilityToggler, setvisibilityToggler] = useState(true);
  const [docusignButtonDisabled, setdocusignButtonDisabled] = useState(false);
  const [docusignHistoriesToggler, setdocusignHistoriesToggler] = useState(false);
  const [loading, setLoading] = useState(false)
  const lessee = data ? data.lessee : []
  const colessee = data ? data.colessee : []
  const dealer = data ? data.dealer : []
  const attachments = data ? data.leaseApplicationAttachments : []
  const [latestAttachment, setLatestAttachment] = useState(attachments.length > 0 && attachments[0]);

  const { Content } = Layout

  const docusignHistoryUsers = data && data.docusignHistories.length > 0 ? data.docusignHistories[data.docusignHistories.length - 1].envelopeUsers : []
  const lesseeHistory = docusignHistoryUsers.find(item => item.role === 'lessee');
  const colesseeHistory = docusignHistoryUsers.find(item => item.role === 'colessee');
  const dealerHistory = docusignHistoryUsers.find(item => item.role === 'dealer');
  const approverHistory = docusignHistoryUsers.find(item => item.role === 'approver');
  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  let docusignHistories = Object.assign([], data?.docusignHistories);

  let dataSource = []
  for (let val of docusignHistories.reverse()) {
    dataSource.push({
      key: val.envelopeId,
      envelopeId: val.envelopeId,
      envelopeStatus: val.envelopeStatus,
      envelopeUsers: val.envelopeUsers
    });
  }
  const [dataSourceArray, setDataSourceArray] = useState(dataSource);
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
  const locked_by = data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false

  useEffect(() => {
    if (attachments.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i] && !(attachments[i].filename).includes("output_file_") || !(attachments[i].filename).includes(".pdf")) {
          setLatestAttachment(false)
          continue;
        }
        setLatestAttachment(attachments[i])
        break;
      }
    }

    if (dataSourceArray.length > 0) {
      let envelope_id = dataSourceArray[0]?.envelopeId;
      statusCheck(envelope_id)
    }
  }, [setLatestAttachment, attachments]);

  const statusCheck = async (envelope_id) => {
    try {
      const result = await GET(`/api/v1/docusign-esign/envelope_status?envelope_id=${envelope_id}`);
      const indexToUpdate = dataSourceArray.findIndex((dataSourceArray) => dataSourceArray.envelopeId === envelope_id);
      const dataSourceStatusCheck = [...dataSourceArray];
      dataSourceStatusCheck[indexToUpdate].envelopeStatus = result.data.envelope_status;
      let updatedDnvelopeUsersWithStatus = dataSourceStatusCheck[indexToUpdate].envelopeUsers.map(envelope => {
        envelope.status = result.data.recipients_status[envelope.role]
        return envelope
      });
      dataSourceStatusCheck[indexToUpdate].envelopeUsers = updatedDnvelopeUsersWithStatus
      setDataSourceArray(dataSourceStatusCheck);
    } catch (e) {
      logger.error("Error while fetching  docusign histories status", e);
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        logger.error("Error while fetching  docusign histories statu", e);
      }
    }
  }

  const approver = {
    name: 'SLC Funding Team',
    email: 'funding@speedleasing.com'
  }

  const attachment_url = latestAttachment ?
    (process.env.REACT_APP_ENV_VAR === 'Staging' || process.env.REACT_APP_ENV_VAR === 'Production' ? (latestAttachment && latestAttachment.download_send_dealership_link.download_url) : (process.env.REACT_APP_BASE_URL + (latestAttachment && latestAttachment.download_send_dealership_link.download_url)))
    : ''

  const [form] = Form.useForm();
  const leaseApplicationId = data.id ? data.id : null

  const save = async (values) => {
    setLoading(true);
    let timer = 0;
    let interval = setInterval(() => {
      timer = timer + 1;
      if (timer > 30) {
        message.success("Retrying Docusign. Please wait...");
        clearInterval(interval);
      }
    }, 1000);
    try {
      values.doc_pdf = latestAttachment.download_send_dealership_link.download_url
      const result = await POST(`/api/v1/docusign-esign/create`, values);
      clearInterval(interval);
      const envelope_id = result.data.message.envelope_id;
      const envelopeUsers = [
        {
          name: values.signer1_name,
          role: 'Lessee',
          email: values.signer1_email,
          status: 'sent'
        },
        {
          name: values.signer3_name,
          role: 'Dealer',
          email: values.signer3_email,
          status: 'created'
        },
        {
          name: values.approver_name,
          role: 'Approver',
          email: values.approver_email,
          status: 'created'
        }
      ]

      if (values.signer2_name) {
        envelopeUsers.splice(1, 0, {
          name: values.signer2_name,
          role: values.signer2_name ? 'Colessee' : '',
          email: values.signer2_email,
          status: values.signer2_name ? 'created' : ''
        });
      }

      const new_history = {
        key: envelope_id,
        envelopeId: envelope_id,
        envelopeStatus: 'sent',
        envelopeUsers: envelopeUsers
      }
      setDataSourceArray([new_history, ...dataSourceArray])
      message.success("Attachment is sent for Docusign. Please check signer emails respectively");
    } catch (e) {
      clearInterval(interval);
      logger.error("Error", e);
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else if (e && e.response && e.response.status === 409) {
        message.error(e.response.data.message);
      } else {
        message.error('Error sending attachment for Docusign');
      }
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    values = {
      ...values,
      leaseApplicationId: leaseApplicationId
    };
    await save(values);
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const expandedRowRender = (record, index, indent, expanded) => {

    const columns2 = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: '',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: '',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: '',
      },
      {
        title: 'Docusign Status',
        dataIndex: 'status',
        key: '',
      },
    ];

    let secondaryData = []
    secondaryData.push(record.envelopeUsers)
    for (let val of record.envelopeUsers) {
      secondaryData.push({
        key: val.envelopeId,
        name: val.name,
        email: val.email,
        role: capitalize(val.role),
        status: val.status
      });
    }
    return (
      <Table
        rowKey={record => record.key + Math.random().toString(36)}
        columns={columns2}
        dataSource={secondaryData}
        pagination={false}
      />
    )
  };

  const columns = [
    {

      title: 'Envelop ID',
      dataIndex: 'envelopeId',
      key: 'envelopeId'
    },
    {
      title: 'Envelope Status',
      dataIndex: 'envelopeStatus',
      key: 'envelopeId'
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render(val, row) {
        return <>
          {
            row.envelopeStatus === 'sent' &&
            <>
              <Button type="danger" style={{ margin: "5px" }} onClick={() => voidDocusign(row.key)} disabled={disableFlag} > Void</Button>
              <Button type="primary" style={{ margin: "5px" }} onClick={() => resendDocusign(row.key)} disabled={disableFlag} > Re-send </Button>
            </>
          }
        </>
      }
    },
  ];

  const voidDocusign = async (id) => {
    setLoading(true);
    try {
      const result = await POST(`/api/v1/docusign-esign/void?envelope_id=${id}`);
      const envelope_id = result.data.message.envelope_id;
      const indexToUpdate = dataSourceArray.findIndex((dataSourceArray) => dataSourceArray.envelopeId === envelope_id);
      const dataSourceVoided = [...dataSourceArray];
      dataSourceVoided[indexToUpdate].envelopeStatus = 'voided';
      setDataSourceArray(dataSourceVoided);
      message.success("The envelop is voided");
    } catch (e) {
      logger.error("Error while saving your data", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving your data');
      }
    }
    setLoading(false);
  }

  const resendDocusign = async (id) => {
    setLoading(true);
    try {
      await POST(`/api/v1/docusign-esign/resend?envelope_id=${id}`);
      message.success("The envelop is resend");
    } catch (e) {
      logger.error("Error while saving your data", e);
      if (e && e.response && e.response.status === 409) {
        message.error(e.response.data.message);
      } else if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving your data');
      }
    }
    setLoading(false);
  }

  const latestAttachmentShow = () => {
    if(latestAttachment){
      return <a target="_blank" rel="noopener noreferrer" href={attachment_url}>{latestAttachment.filename}</a>
    }
    else{
      return 'No attachment found'
    }
  }

  return data ? (
    <>
      <Content>
        <Spin
          spinning={loading}
          size="large"
          tip="Loading..."
        >
          <Form
            {...layout}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card>.
                <Row className='flex-jc-fs'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}> </Col>
                  <Button
                    type='Dashed'
                    onClick={() => {
                      setdocusignHistoriesToggler(!docusignHistoriesToggler)
                    }}
                    style={{ float: 'right' }}
                    disabled={disableFlag}
                  >
                    Docusign histories
                  </Button>
                </Row>
                <Row className='flex-jc-fs'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={{ span: 13, offset: 1 }}>
                    {visibilityToggler ? (
                      <Button
                        type='Dashed'
                        onClick={() => {
                          setvisibilityToggler(false)
                          setdocusignButtonDisabled(true)
                        }}
                        style={{ float: 'right' }}
                        disabled={disableFlag}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        type='Dashed'
                        onClick={() => {
                          form
                            .validateFields()
                            .then(() => {
                              setvisibilityToggler(true)
                              setdocusignButtonDisabled(false)
                            }).catch((e) => {
                              console.log(e)
                            });
                        }}
                        style={{ float: 'right' }}
                        disabled={disableFlag}
                      >
                        Save
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className='flex-jc-fs'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                    <Form.Item
                      label='Lessee Name'
                      name='signer1_name'
                      initialValue={docusignHistoryUsers.length > 0 ? lesseeHistory && lesseeHistory.name : (lessee.firstName + ' ' + lessee.lastName)}
                      rules={
                        [{
                          required: true,
                          message: 'This field is required'
                        }]
                      }
                    >
                      <Input disabled={visibilityToggler || disableFlag} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
                    <Form.Item
                      label='Lessee Email'
                      name='signer1_email'
                      initialValue={data.lesseeDocusignEmail}
                      rules={
                        [{
                          required: true,
                          message: 'This field is required'
                        }, {
                          type: "email",
                          message: 'This field must contain valid email'
                        },]
                      }
                    >
                      <Input disabled={visibilityToggler || disableFlag} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className='flex-jc-fs'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                    <Form.Item
                      label='Co-lessee Name'
                      name='signer2_name'
                      initialValue={docusignHistoryUsers.length > 0 ? (colesseeHistory && colesseeHistory.name || '') : ((colessee && colessee.firstName || '') + ' ' + (colessee && colessee.lastName || ''))}
                    >
                      <Input disabled={visibilityToggler || disableFlag} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
                    <Form.Item
                      label='Co-lessee Email'
                      name='signer2_email'
                      initialValue={data.colesseeDocusignEmail}
                      rules={
                        [{

                          type: "email",
                          message: 'This field must contain valid email'
                        }]
                      }
                    >
                      <Input disabled={visibilityToggler || disableFlag} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className='flex-jc-fs'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                    <Form.Item
                      label='Dealer Name'
                      name='signer3_name'
                      initialValue={docusignHistoryUsers.length > 0 ? (dealerHistory && dealerHistory.name || '') : (dealer && dealer.first_name + dealer.last_name)}
                      rules={
                        [{
                          required: true,
                          message: 'This field is required'
                        }]
                      }
                    >
                      <Input disabled={visibilityToggler || disableFlag} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
                    <Form.Item
                      label='Dealer Email'
                      name='signer3_email'
                      initialValue={data.dealerDocusignEmail}
                      rules={
                        [{
                          required: true,
                          message: 'This field is required'
                        }, {
                          type: "email",
                          message: 'This field must contain valid email'
                        }
                        ]
                      }
                    >
                      <Input disabled={visibilityToggler || disableFlag} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className='flex-jc-fs'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                    <Form.Item
                      label='Approver Name'
                      name='approver_name'
                      initialValue={docusignHistoryUsers.length > 0 ? approverHistory && approverHistory.name || '' : approver.name}
                      rules={
                        [{
                          required: true,
                          message: 'This field is required'
                        }]
                      }
                    >
                      <Input disabled={visibilityToggler || disableFlag} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
                    <Form.Item
                      label='Approver Email'
                      name='approver_email'
                      initialValue={docusignHistoryUsers.length > 0 ? approverHistory && approverHistory.email || '' : approver.email}
                      rules={
                        [{
                          required: true,
                          message: 'This field is required'
                        }, {
                          type: "email",
                          message: 'This field must contain valid email'
                        }
                        ]
                      }
                    >
                      <Input disabled={visibilityToggler || disableFlag} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className='flex-jc-fs'>
                  <Form.Item
                    label="Docusign attachment file"
                    name='doc_pdf'
                    initialValue={latestAttachment ? latestAttachment.download_send_dealership_link.download_url : ''}
                    rules={
                      [{
                        required: true,
                        message: 'Docusign attachment file is required!'
                      }]
                    }
                  >
                    <Input type="hidden" disabled={disableFlag}/>
                  </Form.Item>
                  <Col xs={24} sm={24} md={24} lg={{ span: 7, offset: 1 }} xl={6}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={16} style={{ alignSelf: `flex-end` }}>
                      {
                        latestAttachmentShow()
                      }
                    </Col>
                  </Col>
                </Row>
                <Row className='flex-jc-fs'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={docusignButtonDisabled || disableFlag}
                  >
                    Send for DocuSigns
                  </Button>
                </Row>
              </Card>
            </Col>
          </Form>


          {docusignHistoriesToggler ? (
            <Row style={{ marginTop: "50px" }}>
              <Col span={24}>
                <Table
                  className='slc-table'
                  rowKey={dataSource.key}
                  columns={columns}
                  dataSource={dataSourceArray}
                  pagination={true}
                  expandable={{
                    expandedRowRender
                  }}
                  size="small"
                />

              </Col>
            </Row>
          ) : ("")}

        </Spin>
      </Content>
    </>
  ) : null;
}

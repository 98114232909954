import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Spin, message } from "antd";
import InventoryNadaHistory from "./InventoryNadaHistory";
import { GET } from '../../util/network';
import logger from '../../util/logger';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

export default function InventoryRenderer(props) {
    const { allowedActions, handleNoAuth } = props;
    const history = useHistory();

    const inventoryActions = GetResourceActions('VehicleInventory', allowedActions);

    const allowedGet = IsActionAllowed('get', inventoryActions);
    const allowedUpdate = IsActionAllowed('update', inventoryActions);     

    const vehicleId = props.match.params.id
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const getInventoryNadaHistory = async () => {
      if (allowedGet) {
        setLoading(true);
        try {
          const response = await GET(`/api/v1/vehicle-inventory/nada-value-history?id=${vehicleId}`);
          setData(response.data);
        } catch (e) {
          logger.error("Error getting NADA value history: ", e);

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error while fetching Vehicle Inventory');
          }
        }
        setLoading(false);
      }
    };

      useEffect(() => {
        getInventoryNadaHistory();
      }, []);

      return (
        <Spin spinning={loading}>
          { 
            <InventoryNadaHistory 
              data={data} 
              getInventoryNadaHistory={getInventoryNadaHistory} 
              handleNoAuth={handleNoAuth}
              setLoading={setLoading} 
              vehicleId={vehicleId} 
            />
          }
        </Spin>
      )

}
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PUT } from '../../../util/network';
import logger from '../../../util/logger';
import { Checkbox, Row, Col, Typography, message } from 'antd';
import './FundingApprovalChecklist.css'
const { Text } = Typography;

export default ({ data, handleNoAuth }) => {
  const history = useHistory();
  
  const [noMarkupsOrErasure, setNoMarkupsOrErasure] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.noMarkupsOrErasure : false );
  const [leaseAgreementSigned, setLeaseAgreementSigned] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.leaseAgreementSigned : false );
  const [motorcycleConditionReported, setMotorcycleConditionReported] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.motorcycleConditionReported : false );
  const [creditApplicationSigned, setCreditApplicationSigned] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.creditApplicationSigned : false );
  const [fourReferencesPresent, setFourReferencesPresent] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.fourReferencesPresent : false );
  const [validDl, setValidDl] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.validDl : false );
  const [achFormCompleted, setAchFormCompleted] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.achFormCompleted : false );
  const [insuranceRequirements, setInsuranceRequirements] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.insuranceRequirements : false );
  const [palidEmailAddress, setValidEmailAddress] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.validEmailAddress : false );
  const [registrationDocumentsWithSlr, setRegistrationDocumentsWithSlr] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.registrationDocumentsWithSlc : false );
  const [odsSignedAndDated, setOdsSignedAndDated] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.odsSignedAndDated : false );
  const [proofOfAmountsDue, setProofOfAmountsDue] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.proofOfAmountsDue : false );
  const [documentationToSatisfy, setDocumentationToSatisfy] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.documentationToSatisfy : false );
  const [warrantyProductsPurchased, setWarrantyProductsPurchased] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.warrantyProductsPurchased : false );
  const [signedBos, setSignedBos] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.signedBos : false );
  const [id, setId] = useState(data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.id : null );
  
  const fundingRequirementObj = [
    { init_val: noMarkupsOrErasure, col: 'noMarkupsOrErasure', label: "All pages of lease agreement present with no markups or erasures" },
    { init_val: leaseAgreementSigned, col: 'leaseAgreementSigned', label: "Lease agreement is signed and dated by lessee(s) and dealer where specified with ORIGINAL signatures" },
    { init_val: motorcycleConditionReported, col: 'motorcycleConditionReported', label: "Motorcycle Condition Report is complete and signed and dated by lessee and dealer" },
    { init_val: creditApplicationSigned, col: 'creditApplicationSigned', label: "Credit Application is complete and signed and dated by lessee(s) with ORIGINAL signatures" },
    { init_val: fourReferencesPresent, col: 'fourReferencesPresent', label: "4 references present (full name, city, state and full phone number)" },
    { init_val: validDl, col: 'validDl', label: "Lessee has valid non-expired DL. No permits. Co-Lessee, if any, has valid state-issued ID or DL" },
    { init_val: achFormCompleted, col: 'achFormCompleted', label: "ACH Form is complete and signed and dated by lessee(s) {account holder MUST be on the lease agreement} with ORIGINAL signatures" },
    { init_val: insuranceRequirements, col: 'insuranceRequirements', label: "Proof of insurance meeting minimum requirements ($100k/$300k/$50k w/ max deductible of $1k OR $300k CSL) with SLC Trust as Loss Payee/Lessor AND Additional Interest" },
    { init_val: palidEmailAddress, col: 'validEmailAddress', label: "Valid email address" },
    { init_val: registrationDocumentsWithSlr, col: 'registrationDocumentsWithSlc', label: "Title/Registration documents with SLC Trust listed as owner. Verify lien holder, if needed in state." },
    { init_val: odsSignedAndDated, col: 'odsSignedAndDated', label: "ODS signed and dated by Lessee and Dealer" },
    { init_val: proofOfAmountsDue, col: 'proofOfAmountsDue', label: "Proof of Amounts Due at Lease Signing (copy of check, credit/debit card slip or cash receipt)" },
    { init_val: documentationToSatisfy, col: 'documentationToSatisfy', label: "Documentation to satisfy any open “Contingencies Required for Funding” from Underwriting" },
    { init_val: warrantyProductsPurchased, col: 'warrantyProductsPurchased', label: "Copies of any warranty products purchased (GAP, T&W, ESC, etc) matching lease agreement with SLC listed as Lienholder/Financial Institution. Verify bike info matches lease agreement and signed by Lessee and Dealer" },
    { init_val: signedBos, col: 'signedBos', label: "Signed BOS" }
 ];

 const onChange = (e) => {
  let values = { id: id, [e.target.id]: e.target.checked  };
  setTimeout( async () => {
      let funding_requirements = { onlineFundingApprovalChecklistAttributes: values }
      try {
        await PUT(`/api/v1/lease_applications/details?application_identifier=${data && data.applicationIdentifier}`, funding_requirements);
        message.success("Data saved successfully");
      } catch (e) {
        logger.error("Application Details Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data');
        }        
      }
  }, 100);
}

  return !data ? null : (
      <>
          {
            fundingRequirementObj.map((obj, index) => {
              return <Row gutter={[8, 8]} key={`${index}-f-req-row`} className="document-row" >
                <Col span={24}>
                    <Checkbox key={`${index}-f-req`} id={obj.col} defaultChecked={obj.init_val} className="fac-checkbox document-checkbox" onChange={onChange}> 
                      <Text level={4} className="fac-txt-val fac-checkbox-label" >{obj.label} </Text> 
                    </Checkbox>
                </Col>
              </Row>
            })
          }
      </>
    )
};
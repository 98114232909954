import React, { useState } from 'react'
import { Avatar, Col, Layout, Menu, Popover, Row, Button, List, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logo from '../../assets/SpeedLeasing-Logo-Final_Light.png';
import AllowedActions from '../../util/AllowedActions.js';
import './MainHeader.css';
import './MainSider.css';
import { useSelector } from "react-redux";
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { PUT } from '../../util/network'
import { useDispatch } from "react-redux";
import { markNotificationsAsRead, markNotificationByIdAsRead } from '../../reducers/Notifications'

const { SubMenu } = Menu;
const { Header } = Layout;

const adminUser = JSON.parse(localStorage.getItem("user_data"));

const MainHeader = (props) => {
  let auth_token = window.localStorage.getItem('auth_token');
  const allowedActionsData = AllowedActions();

  let isAdmin = false;

  if (allowedActionsData) {
    allowedActionsData.map((action) => {
      if (action.resource && action.resource == 'AdminUser') {
        isAdmin = true;
      }
    })
  }

  const losPath = (path) => {
    return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
  }

  const adminSubMenu = [
    { text: "Admin Users", link_type: "linkto", link: "/administration/admin-users", admin_restriction: false },
    { text: "Common Application Settings", link_type: "href", link: losPath("/admins/common_application_settings/1"), admin_restriction: false },
    { text: "Control Panel", link_type: "linkto", link: "/administration/control-panel", admin_restriction: false },
    { text: "Emails", link_type: "linkto", link: "/administration/email-templates", admin_restriction: false },
    { text: "Funding Delay Reasons", link_type: "href", link: losPath("/admins/funding_delay_reasons"), admin_restriction: false },
    { text: "Lease Application Blackbox", link_type: "href", link: losPath("/admins/lease_application_blackbox_requests"), admin_restriction: false },
    { text: "Lease Package Templates", link_type: "href", link: losPath("/admins/lease_package_templates"), admin_restriction: true },
    { text: "Settings", link_type: "linkto", link: "/administration/settings", admin_restriction: false },
    { text: "Sidekiq", link_type: "href", link: losPath("/sidekiq"), admin_restriction: false },
    { text: "Stipulations", link_type: "href", link: losPath("/admins/stipulations"), admin_restriction: false },
    { text: "Workflow", link_type: "linkto", link: "/administration/workflow", admin_restriction: false },
    { text: "CBC Users", link_type: "linkto", link: "/administration/cbc-users", admin_restriction: false },
  ]

  const assetSubMenu = [
    { text: 'Makes', link_type: 'href', link: losPath('/admins/makes') },
    { text: 'Model Groups', link_type: 'href', link: losPath('/admins/model_groups') },
    { text: 'Model Years', link_type: 'href', link: losPath('/admins/model_years') },
    { text: 'NADA Dummy Bikes', link_type: 'href', link: losPath('/admins/nada_dummy_bikes') },
    { text: 'Police Bike Rules', link_type: 'href', link: losPath('/admins/police_bike_rules') },
  ]

  const newAssetSubMenu = [
    { text: 'Make Category', link_type: 'linkto', link: '/new-assets/make-category/list' },
    { text: 'Makes', link_type: 'linkto', link: '/new-assets/makes/list' },
    { text: 'Model Groups', link_type: 'linkto', link: '/new-assets/model-groups/list' },
    { text: 'Model Years', link_type: 'linkto',  link: '/new-assets/model-years/list' },
  ]

  const calculatorSubMenu = [
    { text: 'Credit Tiers', link_type: 'href', link: losPath('/admins/credit_tiers') },
    { text: 'New Credit Tiers', link_type: 'linkto', link: '/calculator/credit-tiers/list' },
    { text: 'Mileage Tiers', link_type: 'href', link: losPath('/admins/mileage_tiers') },
    { text: 'Tax Jurisdictions', link_type: 'href', link: losPath('/admins/tax_jurisdictions') },
    { text: 'Tax Rules', link_type: 'href', link: losPath('/admins/tax_rules') },
  ]

  const dealerSubMenu = [
    { text: 'Dealer Representatives', link_type: 'href', link: losPath('/admins/dealer_representatives') },
    // { text: 'Dealer Users', link_type: 'href', link: losPath('/admins/dealers') },
    { text: 'Dealer Users', link_type: 'linkto', link: '/new-dealer-users' },
    { text: 'Dealerships', link_type: 'linkto', link: '/dealerships' },
    { text: 'Call logs', link_type: 'linkto', link: '/call-logs' },
    { text: 'Contacts', link_type: 'linkto', link: '/contacts' },
  ]

  const leaseApplicationRelatedSubMenu = [
    { text: 'Funding Delays', link_type: 'href', link: losPath('/admins/funding_delays') },
    // { text: "New Funding delays", link_type: "linkto", link: "/funding-delays-list" },
    { text: 'LeaseApplication Stipulations', link_type: 'href', link: losPath('/admins/lease_application_stipulations') },
    // { text: 'New LeaseApplication Stipulations', link_type: 'linkto', link: '/lease-applications-stipulation' },
    // { text: 'Lease Application Welcome Calls', link_type: 'href', link: losPath('/admins/lease_application_welcome_calls') },
    // { text: 'Lease Document Requests', link_type: 'href', link: losPath('/admins/lease_document_requests') },
    { text: 'Lease Document Requests', link_type: 'linkto', link: '/lease-document-request' },
    { text: "Docusign Reports", link_type: "linkto", link: "/admins/docusign_reports", admin_restriction: false }
  ]

  // const LesseeRelatedSubMenu = [
  //   { text: 'New Lessee', link_type: 'linkto', link: "/lessee-list" },
  // ]
  const notifications = useSelector((state) => state.notifications.notifications);
  const dispatch = useDispatch()

  const markAllNotificationsAsRead = async () => {
    try {
      await PUT(`/api/v1/notifications/mark_all_read`);
      dispatch(markNotificationsAsRead({}))
      message.success("Marked all notifications as read")
    } catch (e) {
      message.error("Error while marking notifications as read")
    }
  }

  const markNotifcationReadById = async (id) => {
    try {
      await PUT(`/api/v1/notifications/mark_as_read`, { id: id });
      dispatch(markNotificationByIdAsRead({ id: id }))
      message.success("Marked notification as read")
    } catch (e) {
      message.error("Error while marking notifications as read")
    }
  }

  const notificationLink = (notification) => {
    const url = notification.url
    const lastIndex = url.lastIndexOf('/');
    const before = url.slice(0, lastIndex);
    const after = url.slice(lastIndex + 1);
    if(before.endsWith('/admins/lease_applications') && !isNaN(after)){
      return <a style={{ color: notificationLinkColor(notification) }} href={'/lease-applications/' + after} onClick={() => markNotifcationReadById(notification.id) }>
        {notification.notification_content}
      </a>
    }
    else{
      return <a style={{ color: notificationLinkColor(notification) }} href={notification.url} onClick={() => markNotifcationReadById(notification.id) }>
        {notification.notification_content}
      </a>
    }
  }

  const notificationColor = (notification) => {
    return notification.read_at === null ? '#001529' : '#f0f0f0'
  }

  const notificationLinkColor = (notification) => {
    return notification.read_at === null ? '#4183c4' : 'black'
  }

  const notificationList = () => {
    return <div>
      <Paper style={{maxHeight: '330px', width: "450px", overflow: 'auto', backgroundColor: "#001529" }}>
        <List>
          {Array.isArray(notifications) && notifications.length > 0 && notifications.map((notification) => (
            <ListItem
              key={notification.id}
              disableGutters
              divider
              sx={{ backgroundColor: notificationColor(notification) }}
              onClick={() => { markNotifcationReadById(notification.id) }}
            >
              <ListItemText primary={
                <p style={{ marginLeft: "10px", fontSize: "15px", flex: 1 }}>{notificationLink(notification)}</p>
              }
              secondary={
                <p style={{ color: "#6a7176", marginLeft: "10px", fontSize: "15px", fontStyle: 'italic' }}>
                  {notification.subtext}
                </p>
              }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  }

  const notificationsCount = () => {
    return Array.isArray(notifications) && notifications.filter(notification => notification.read_at === null).length
  }

  const AvatarContent = (
    <div>
      <p>{adminUser.full_name}</p>
      <p>{adminUser.email}</p>
      <a href={`/admins/change-my-password`}> Change Password </a>
      <br />
      <a href={`/logout`} style={{ color: 'red' }}> Logout </a>
    </div>
  );

  return (
    <Header className="main-header-layout" id='main-menu'>
      <Row>
        <Col flex='1 1 200px'>
          <div className="logo" >
            <img className="logo-img" src={logo} />
          </ div>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={props.activePage}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="dashboard">
              <Link to="/dashboard">Home</Link>
            </Menu.Item>
            <Menu.Item key="leaseApplications">
              <a href={losPath("/admins/lease_applications")}>Lease Applications Old</a>
            </Menu.Item>
            <Menu.Item key="leaseApplication">
              <Link to="/lease-applications-list">Lease Applications</Link>
            </Menu.Item>
            <SubMenu key='dealers' title='Dealers' className={`${(props.activePage == 'dealers') && 'ant-menu-item-selected'}`}>
              {
                dealerSubMenu.map(({ text, link, link_type }, index) => {
                  return (
                    <Menu.Item key={`dealers${index}`}>
                      {link_type == "linkto" ? (
                        <Link to={link}>{text}</Link>
                      ) :
                        <a href={link}>{text}</a>
                      }
                    </Menu.Item>
                  )
                })
              }
            </SubMenu>
            <SubMenu key='assets' title='Assets' className={`${(props.activePage == 'assets') && 'ant-menu-item-selected'}`}>
              {
                assetSubMenu.map(({ text, link, link_type }, index) => {
                  return (
                    <Menu.Item key={`assets${index}`}>
                      {link_type == "linkto" ? (
                        <Link to={link}>{text}</Link>
                      ) :
                        <a href={link}>{text}</a>
                      }
                    </Menu.Item>
                  )
                })
              }
            </SubMenu>
            <SubMenu key='newAssets' title='New Assets' className={`${(props.activePage == 'newAssets') && 'ant-menu-item-selected'}`}>
              {
                newAssetSubMenu.map(({ text, link, link_type }, index) => {
                  return (
                    <Menu.Item key={`new-assets${index}`}>
                      {link_type == "linkto" ? (
                        <Link to={link}>{text}</Link>
                      ) :
                        <a href={link}>{text}</a>
                      }
                    </Menu.Item>
                  )
                })
              }
            </SubMenu>
            <SubMenu key='calculator' title='Calculator' className={`${(props.activePage == 'calculator') && 'ant-menu-item-selected'}`}>
              {
                calculatorSubMenu.map(({ text, link, link_type }, index) => {
                  return (
                    <Menu.Item key={`calculator${index}`}>
                      {link_type == "linkto" ? (
                        <Link to={link}>{text}</Link>
                      ) :
                        <a href={link}>{text}</a>
                      }
                    </Menu.Item>
                  )
                })
              }
            </SubMenu>
            <SubMenu key='leaseApplicationRelated' title='Lease Application Related' className={`${(props.activePage == 'leaseApplicationRelated') && 'ant-menu-item-selected'}`}>
              {
                leaseApplicationRelatedSubMenu.map(({ text, link, link_type }, index) => {
                  return (
                    <Menu.Item key={`leaseApplicationRelated${index}`}>
                      {link_type == "linkto" ? (
                        <Link to={link}>{text}</Link>
                      ) :
                        <a href={link}>{text}</a>
                      }
                    </Menu.Item>
                  )
                })
              }
            </SubMenu>
            {/* <SubMenu key='lesseeRelated' title='Lessee Related' className={`${(props.activePage == 'lesseeRelated') && 'ant-menu-item-selected'}`}>
              {
                LesseeRelatedSubMenu.map(({ text, link, link_type }, index) => {
                  return (
                    <Menu.Item key={`lesseeRelated${index}`}>
                      {link_type == "linkto" ? (
                        <Link to={link}>{text}</Link>
                      ) :
                        <a href={link}>{text}</a>
                      }
                    </Menu.Item>
                  )
                })
              }
            </SubMenu> */}
            <Menu.Item key="lessee">
              <a href={losPath("/admins/lessees")}>Lessee</a>
            </Menu.Item>
            <Menu.Item key="usStates">
              <a href={losPath("/admins/us_states")}>US States</a>
            </Menu.Item>
            <Menu.Item key="inventory">
              <Link to="/inventory">Inventory</Link>
            </Menu.Item>
            {isAdmin &&
              <SubMenu key="administration" title="Administration" className={`${(props.activePage == "administration") && "ant-menu-item-selected"}`}>
                {
                  adminSubMenu.map(({ text, link, link_type, admin_restriction }, index) => {
                    let toDisplay = admin_restriction ? (isAdmin) : true
                    return !toDisplay ? false : (
                      <Menu.Item key={`administration${index}`}>
                        {link_type == "linkto" ? (
                          <Link to={link}>{text}</Link>
                        ) :
                          <>
                            {text == 'Sidekiq'
                              ? <a href={link} target='_blank' rel='noreferrer'>{text}</a>
                              : <a href={link}>{text}</a>
                            }
                          </>
                        }
                      </Menu.Item>
                    )
                  })
                }
              </SubMenu>
            }

            <Menu.Item key="audits">
              <a href={losPath("/admins/audits")}>Audits</a>
            </Menu.Item>
            <Menu.Item key="bankRoutingNumbers">
              <a href={losPath("/admins/bank_routing_numbers")}>Bank Routing Numbers</a>
            </Menu.Item>
            <Menu.Item key="timerReport">
              <Link to="/timer-reports/list">Turn Timer Reports</Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col flex='0 1 100px'>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={props.activePage}
            style={{ lineHeight: '64px' }}
            triggerSubMenuAction={ 'click' }
          >
            <SubMenu
              key='notifications'
              title={<Badge badgeContent={notificationsCount()} color="error">
                <NotificationsIcon/>
              </Badge>
              }
              className={`${(props.activePage == 'notifications') && 'ant-menu-item-selected'}`}
            >
              {
                notificationList()
              }
              {
                Array.isArray(notifications) && notifications.length > 0 &&
                <Menu.Item key={"action-items"} style={{ backgroundColor: "#e93b1b", width: "450px", margin: 0 }}>
                  <Row>
                    <Col span={12}>
                      <Menu.Item key={"notifications-mark-as-read"}>
                        <Button type="text" style={{ color: "white", paddingLeft: "0px" }} onClick={() => markAllNotificationsAsRead() }>MARK ALL AS READ</Button>
                      </Menu.Item>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right'}}>
                      <Menu.Item key={"notifications-view-all"}>
                        <Link style={{ color: "white" }} to='/admins/all_notifications'>VIEW ALL</Link>
                      </Menu.Item>
                    </Col>
                  </Row>
                </Menu.Item>
              }
            </SubMenu>
          </Menu>
        </Col>
        <Col flex='0 1 50px'>
          <span style={{ float: 'right' }}>
            <Popover placement="bottomRight" content={AvatarContent} trigger="click">
              <Avatar size="large" icon={<UserOutlined />} className="ant-avatar-icon" />
            </Popover>
          </span>
        </Col>
      </Row>
    </Header>
  );
}

export default MainHeader;
import React from 'react';
import { Table } from 'antd';

export default function HistoricalRelated(props) {
  const { data, disableFlag } = props;

  const dataSource = data.historicalRelated || [];

  const columns = [
    {
      title: 'Application',
      dataIndex: 'applicationIdentifier',
      key: 'applicationIdentifier',
      render(val, row) {
        if(val && !disableFlag){
          return <a href={`/lease-applications/${row.id}`} target="_blank" rel="noreferrer">{val}</a>
        }
        else{
          return <a>{val}</a>
        }
      }
    },
    {
      title: 'Credit Status',
      dataIndex: 'creditStatus',
      key: 'creditStatus',
    },
    {
      title: 'Document Status',
      dataIndex: 'documentStatus',
      key: 'documentStatus',
    },
    {
      title: 'Lessee',
      dataIndex: 'lessee',
      key: 'lessee',
    },
    {
      title: 'Co-Lessee',
      dataIndex: 'colessee',
      key: 'colessee',
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expirationDate',
      key: 'expirationDate'
    },
    {
      title: 'Dealership',
      dataIndex: 'dealership',
      key: 'dealership'
    },
    {
      title: 'Approved Tier',
      dataIndex: 'approvedTier',
      key: 'approvedTier'
    }
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      className="slc-table"
      rowKey="id"
      size="small"
      pagination={true}
    />
  )
}

import React from "react";
import {Layout, Button} from "antd";
import '../../App.css';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import ApplicationStipulationsDatarid from './components/ApplicationStipulationsDataGrid'
import {styled} from '@mui/system';
import Typography from "@material-ui/core/Typography";
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';

export default function ApplicationStipulationsList(props) {
  const {Content} = Layout;
  const history = useHistory();
  const {data, loading, pageState, setPageState, total} = props;

  const columns = [
    {
      field: 'id', headerName: 'Id', type: 'string', flex: 0.7, minWidth: 10, filterable: false
    },
    {
      field: 'applicationIdentifier',
      headerName: 'Lease Application Id',
      type: 'action',
      flex: 1,
      filterable: false,
      renderCell: (record) => {
        return record ?
          <a href={`/lease-applications/${record.row.leaseApplicationId}`}>{record.row.applicationIdentifier}</a> : "";
      },
    },
    {
      field: 'stipulation',
      headerName: 'Stipulation',
      type: 'string',
      flex: 1.8,
      minWidth: 75,
      filterable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      flex: 1.5,
      minWidth: 50,
      filterable: false,
    },
    {
      field: 'leaseApplicationAttachmentId',
      headerName: 'LeaseApplication Attachment',
      type: 'string',
      flex: 1.5,
      minWidth: 50,
      filterable: false,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'action',
      flex: 1,
      filterable: false,
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{textTransform: "none"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/lease_applications_stipulation/${record.row.id}`,
                });
              }}
            >
              View
            </Button>
            <Button
              style={{textTransform: "none", marginLeft: "1rem"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/lease_applications_stipulation_edit/${record.row.id}`,
                });
              }}
            >
              Edit
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ]

  return (
    <Layout>
      <MainHeader activePage="leaseApplicationRelated"/>
      <Layout>
        <Layout id='content-area-layout'>
          <Content id='main-content' style={{backgroundColor: "#ffff !important"}}>
            <Box sx={{flexGrow: 1, pb: 2}}>
              <Grid container spacing={2} direction="row" justifyContent="flex-start">
                <Grid item md={6} sm={4}>
                  <Typography variant={"h4"}>Lease Application Stipulations</Typography>
                </Grid>
              </Grid>
            </Box>
            <StyledBox>
              {
                data &&
                <ApplicationStipulationsDatarid
                  columns={columns}
                  data={data}
                  loading={loading}
                  setPageState={setPageState}
                  pageState={pageState}
                  total={total}
                />
              }
            </StyledBox>
          </Content>
        </Layout>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})

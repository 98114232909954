import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Card, Checkbox, Col, Input, Form, Layout, Menu, message, Result, Row, Select, Space, Spin } from 'antd';
import Icon from '@ant-design/icons';
/*import { UserOutlined } from '@ant-design/icons';*/
import { GET, PUT, POST } from '../../util/network';
import logger from '../../util/logger';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import { LesseeSvg } from '../../components/layouts/Svg';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

const { Content, Sider } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function AdminUser(props) {
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory();

  const [activeMenu, setActiveMenu] = useState([1]);

  const adminUserActions = GetResourceActions('AdminUser', allowedActions);
  const allowedGet = IsActionAllowed('get', adminUserActions);
  const allowedUpdate = IsActionAllowed('update', adminUserActions);

  const [loading, setLoading] = useState(false);
  const [adminUser, setAdminUser] = useState(undefined);

  const { id } = props.match.params;
  let auth_token = window.localStorage.getItem('auth_token')
  const [adminUserForm] = Form.useForm();
  
  const [securityRoleOptions, setSecurityRoleOptions] = useState([]);
  const [dealershipsOptions, setDealershipsOptions] = useState([]);

  const [passwordValidateStatus, setPasswordValidateStatus] =useState(undefined);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(undefined);

  let adminUserName;

  if (adminUser) {
    adminUserName = adminUser.first_name && adminUser.last_name ? adminUser.first_name +' ' + adminUser.last_name : id;
  }  

  const getAdminUser = async () => {
    if (allowedGet) {
      setLoading(true);

      try {
        const response = await GET(`/api/v1/admin-users/${id}`);

        setAdminUser(response.data.admin_user)
      } catch (e) {
        logger.error("Error while fetching Admin User", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while fetching Admin User');
        }
      }

      setLoading(false);
    }
  };

  const getSecurityRoles = async () => {
    if (allowedGet) {
      setLoading(true);

      try {
        const response = await GET(`/api/v1/security-roles/security-roles-options`);
        setSecurityRoleOptions(response.data.security_roles_options || [])
      } catch (e) {
        logger.error("Error while fetching Security Roles", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while fetching Security Roles');
        }
      }

      setLoading(false);
    }
  };

  const getDealershipOptions = async () => {
    if (allowedGet) {
      setLoading(true);

      try {
        const response = await GET(`/api/v1/dealerships/dealerships-options`);
        setDealershipsOptions(response.data.dealerships_options || [])
      } catch (e) {
        logger.error("Error while fetching Dealerships Options", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while fetching Dealerships Options');
        }
      }

      setLoading(false);
    }
  };

  const expirePassword = async () => {
    if (allowedUpdate) {
      setLoading(true);

      try {
        await PUT(`/api/v1/admin-users/expire-password/${id}`);
        message.success('User password expired');
      } catch (e) {
        logger.error('Error while expiring password: ', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while expiring password.');
        }
      }

      setLoading(false);
    }
  };

  const expireSession = async () => {
    if (allowedUpdate) {
      setLoading(true);

      try {
        await PUT(`/api/v1/admin-users/expire-session/${id}`);
        message.success('User session expired');
      } catch (e) {
        logger.error('Error while expiring session: ', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while expiring session.');
        }
      }

      setLoading(false);
    }
  };

  const resetPassword = async () => {
    if (allowedUpdate) {
      setLoading(true);
      let params = { email: adminUser.email }
      try {
        const response = await POST(`/api/v1/reset-pass-request`, params);
        setLoading(false);
        message.success(response.data?.message || "Please follow instruction sent to the user's email");
      } catch (e) {
        logger.error('Error while resetting password: ', e);
        setLoading(false);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while resetting password!');
        }
      }
    }
  };

  const save = async (values) => {
    if (allowedUpdate) {
      setLoading(true);
      
      try {
        await PUT(`/api/v1/admin-users/${id}`, values);
        message.success("Saved successfully");
      } catch (e) {
        logger.error("Error while saving!", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving!');
        }
      }

      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    values = { ...values };
    save(values)
  }

  useEffect(() => {
    getSecurityRoles()
    getDealershipOptions()
    getAdminUser()
  }, []);
  
  return (
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Layout>
        <MainHeader activePage="administration"/>        
        <Layout>
          <Sider {...SiderProps} >
            <Menu
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu.toString()]}
              style={{ width: '100%' }}
              onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
            >
              <Menu.Item key="1" ><Icon component={LesseeSvg} />Admin User</Menu.Item>              
            </Menu>
          </Sider>

          <Layout id='content-area-layout'>
            { adminUser &&               
              <MainBreadcrumb items={
                [
                  { text: "Home", link_type: "linkto", link: "/dashboard" },
                  { text: "Administration", link_type: "ahref", link: void(0) },
                  { text: "Admin Users", link_type: "ahref", link: '/administration/admin-users' },
                  { text: `${adminUserName}`, link_type: "ahref", link: void(0) }
                ]}
              />
            }
                    
            <Content id='main-content'>
              <Row gutter={[0, 24]}>
                <Col span={24}>                  
                  {
                    !allowedGet
                      ?
                        <Result 
                          status='warning'
                          title='You are not allowed to access this page.'
                        />                      
                      :
                        adminUser &&
                          <Form 
                            form={adminUserForm} 
                            {...layout}  
                            colon={false}
                            onFinish={handleSubmit}
                            scrollToFirstError={true}
                            initialValues={{
                              id: adminUser.id,
                              first_name: adminUser.first_name,
                              last_name: adminUser.last_name,
                              email: adminUser.email,
                              last_sign_in_at: adminUser.last_sign_in_at,
                              job_title: adminUser.job_title,
                              is_active: adminUser.is_active,                              
                              security_role_ids: adminUser.security_roles.map(x => x.id),
                              dealership_ids: adminUser.dealerships.map(x => x.id),
                              is_dealer_representative: adminUser.dealer_representative && adminUser.dealer_representative.is_active,
                              is_dealership_login: adminUser.is_dealership_login,
                            }}
                            >
                            <Row gutter={[16, 16]}>
                              <Col span={24}>
                                <Card title="User Info">
                                  <Row gutter={[24, 16]}>
                                    <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                      <Form.Item 
                                        label="User ID" 
                                        name="id"
                                        hasFeedback
                                      >  
                                        <Input disabled={true}/>
                                      </Form.Item>

                                      <Form.Item 
                                        label="First Name" 
                                        name="first_name"
                                        hasFeedback
                                      >  
                                        <Input/>
                                      </Form.Item>

                                      <Form.Item 
                                        label="Email" 
                                        name="email"
                                        hasFeedback
                                      >  
                                        <Input/>
                                      </Form.Item>

                                      <Form.Item 
                                        label="Password" 
                                        name="password"
                                        hasFeedback
                                        messageVariables={{ name: "Password" }}
                                        validateStatus={passwordValidateStatus}
                                        help={passwordErrorMsg}
                                        rules={[
                                          {
                                            required: false,
                                            min: 8,
                                            max: 128
                                          },
                                          {
                                            pattern: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/g,
                                            message: `At lease 1 captal letter, 1 lower case letter and 1 special charcater`

                                          }
                                        ]}
                                        >  
                                          <Input.Password/>
                                      </Form.Item> 
                                    </Col>
                                    <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                      <Form.Item 
                                          label="Last Login" 
                                          name="last_sign_in_at"
                                          hasFeedback
                                        >  
                                          <Input disabled={true}/>
                                        </Form.Item>

                                        <Form.Item 
                                          label="Last Name" 
                                          name="last_name"
                                          hasFeedback
                                        >  
                                          <Input/>
                                        </Form.Item>

                                        <Form.Item 
                                          label="Job Title" 
                                          name="job_title"
                                          hasFeedback
                                        >  
                                          <Input/>
                                    </Form.Item>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>

                            <Row gutter={[16, 16]} className="mt-20">
                            <Col span={24}>
                              <Card title="Auto Dealership Login">
                                <Row gutter={[24, 16]}>
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                      name="is_dealership_login"
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Is Dealer Login</Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </Row>

                            <Row gutter={[16, 16]} className='mt-20'>
                              <Col span={24}>
                                <Card title="User Security">
                                  <Row gutter={[24, 16]}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                      <Form.Item 
                                        name="is_active"
                                        valuePropName="checked">
                                          <Checkbox>User is Active </Checkbox>
                                      </Form.Item>
                                      <Form.Item 
                                        label="Security Roles" 
                                        name="security_role_ids"
                                        hasFeedback
                                        >  
                                        <Select mode='multiple'>
                                          {
                                            securityRoleOptions && securityRoleOptions.map(({value, label}, index) => {
                                              return <Option key={index} value={value}>{label}</Option>
                                            })
                                          }
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  { allowedUpdate &&
                                    <Row>
                                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Row>
                                          <Button 
                                           onClick={resetPassword}
                                          >
                                            Reset Password
                                          </Button>
                                        </Row>
                                      </Col>
                                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Row id='expire-buttons' className='flex-jc-fe'>
                                          <Space>
                                            <Button
                                              onClick={expirePassword}
                                            >
                                              Expire Password
                                            </Button>
                                            <Button
                                              id='expire-session'
                                              onClick={expireSession}
                                            >
                                              Expire Session
                                            </Button>
                                          </Space>
                                        </Row>
                                      </Col>
                                    </Row>
                                  }
                                </Card>
                              </Col>
                            </Row>

                            <Row gutter={[16, 16]} className='mt-20'>
                              <Col span={24}>
                                <Card title="Dealer Representation">
                                  <Row gutter={[24, 16]}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item 
                                      name="is_dealer_representative"
                                      valuePropName="checked">
                                        <Checkbox>Is Dealer Representative</Checkbox>
                                    </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={[24, 16]}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item 
                                          label="Dealerships" 
                                          name="dealership_ids"
                                          hasFeedback
                                        >  
                                          <Select mode="multiple" >
                                            {
                                              dealershipsOptions && dealershipsOptions.map(({value, label}, index) => {
                                                return <Option key={index} value={value}>{label}</Option>
                                              })
                                            }
                                          </Select>
                                        </Form.Item>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>

                            { allowedUpdate && 
                              <Row gutter={[16, 16]} className='mt-20'>
                                <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                  <Row>
                                    <Col span={24}> 
                                      <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                          Save
                                        </Button>
                                      </Form.Item>
                                    </Col> 
                                  </Row>
                                </Col>
                              </Row>
                          }
                          </Form>
                  }
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>  
        <MainFooter/>      
      </Layout>
    </Spin> 
  );
}
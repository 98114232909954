import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message} from "antd";
import { PushpinOutlined } from '@ant-design/icons';
import { PUT } from '../../../util/network';
import logger from '../../../util/logger';
import { useDispatch } from "react-redux";
import { setPinnedTabs } from '../../../reducers/LeaseApplications'

export default function PinCollapse({ defaultPin, handleNoAuth, menuKey, pinnedCollapse, setPinnedCollapse }) {    
    const history = useHistory();
    const dispatch = useDispatch();

    const [toPin, setToPin] = useState(defaultPin)

    const handlePin = () => {
        var pin = !toPin
        setToPin(pin)
        var pinnedCollapseArray = pin ? [...pinnedCollapse, menuKey] : pinnedCollapse.filter((e)=>(e !== menuKey))
        dispatch(setPinnedTabs(pinnedCollapseArray))
        // setPinnedCollapse(pinnedCollapseArray)
        updatePinnedTabs(pinnedCollapseArray)
    }

    const updatePinnedTabs = async (pinnedCollapseArray) => {
        try {
          var payload_filter = {
            pinned_tabs: pinnedCollapseArray
          }
          await PUT(`/api/v1/admin-users/update-pinned-tabs`,payload_filter);
          message.success("Pin Updated Successfully");
        } catch (e) {
          logger.error("Error Updating pinned tabs", e);

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error updating pinned tabs');
          }
        }
      }

    return (
        <PushpinOutlined onClick={handlePin} rotate={toPin ? -45 : 45} />
    )
}
import React, { useState, useEffect, useMemo } from "react";
import { Spin } from "antd";
import LeaseApplication from "./LeaseApplication";
import { useSelector, useDispatch } from "react-redux";
import {getDetails, makeAppDetailsEmpty, setPinnedTabs, fetchLeaseApplicationDetails } from '../../reducers/LeaseApplications'

export default function LeaseApplicationRenderer(props) {
  const { allowedActions, handleNoAuth } = props;
  const { id } = props.match.params;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getApplicationDetails = async () => {
    setLoading(true)
    dispatch(makeAppDetailsEmpty({}));
    const response = await fetchLeaseApplicationDetails({ id: id });
    if(response){
      dispatch(getDetails(response.data.leaseApplication));
      dispatch(setPinnedTabs(response.data.leaseApplication.pinnedTabs));
      setLoading(false)
    }
  };

  const data = useSelector((state) => state.leaseApplications.leaseApplicationDetails);
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }

  useEffect(() => {
    // if(data.id === undefined || props.match.params.id != data.id) {
      getApplicationDetails();
    // }
  }, []);
  
  return (
    <Spin spinning={loading} style={{marginTop: '20%'}}>
      {
        data.id !== undefined &&  data?.pinnedTabs ? (
          <LeaseApplication
            allowedActions={allowedActions}
            appNumber={data?.applicationIdentifier}
            creditStatusOptions={data?.creditStatusOptions}
            declineReasonTypeOptions={data.declineReasonTypeOptions}
            data={data}
            disableFlag={loggedInUserId !== null && data.lockedStatus && data.lockedStatus.lockedById != loggedInUserId ? true : false}
            handleNoAuth={handleNoAuth}
            id={id}
            pinnedCollapse={data.pinnedTabs}
            poaPrequisites={Object.keys(data.leaseDocumentRequests).length > 0 ? true : false}
            setLoading={setLoading}
          />
        ) : ''
      }
    </Spin>
  )
}
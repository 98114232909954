import React, { useState, useEffect } from "react";
import { Table, Col, Row, Input, Popconfirm, Form, Select } from 'antd';
import logger from '../../../util/logger';
import { useDispatch } from "react-redux";
import {updateFundingDelay, updateLockingUser} from "../../../reducers/LeaseApplications";

const { Option } = Select;
const EditableCell = ({
editing,
dataIndex,
inputType,
record,
children,
disabled,
...restProps
}) => {
    const selectNode =
        <Select defaultValue={record} style={{ width: 120 }} disabled={disabled} >
            <Option value="Required">Required</Option>
            <Option value="Not Required">Not Required</Option>
            <Option value="Cleared">Cleared</Option>
        </Select>
    ;
    const inputNode = inputType === 'text' ? <Input disabled={disabled}/> : selectNode;
    return (
        <td {...restProps}>
        {editing ? (
            <Form.Item
            name={dataIndex}
            style={{
                margin: 0,
            }}
            >
            {inputNode}
            </Form.Item>
        ) : (
            children
        )}
        </td>
    );
};

// const EditableTable = () => {
export default function FundingDelays({ data: apiData, save: saveFundingDelay }) {
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
      loggedInUserId = authData.id;
  }
  const locked_by =  apiData.lockedStatus && apiData.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false
  const dispatch = useDispatch();

  const dataSource = apiData ? apiData.fundingDelays : []
  let dataSourceKey = []
  for (let i = 0; i < dataSource.length ; i++) {
      dataSourceKey.push({
          ...dataSource[i],
          key: dataSource[i].id.toString(),
      });
      }
  const [form] = Form.useForm();
  const [data, setData] = useState(dataSourceKey);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = record => record.key === editingKey;

  useEffect(() => {
      dataSourceKey = []
      for (let i = 0; i < dataSource.length ; i++) {
          dataSourceKey.push({
              ...dataSource[i],
              key: dataSource[i].id.toString(),
          });
      }
      setData(dataSourceKey)
    }, [apiData]);


    const edit = record => {
        form.setFieldsValue({
        ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async key => {
        try {
        const row = await form.validateFields();
        const newData = [...data];
        const index = newData.findIndex(item => key === item.key);

        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
            setData(newData);
            saveFundingDelay({
                funding_delays_attributes: [{
                        id: newData[index].id,
                        notes: newData[index].notes,
                        status: newData[index].status
                        }
                    ]
            })
            dispatch(
                updateFundingDelay({ id: newData[index].id, notes: newData[index].notes, status: newData[index].status })
            );
            setEditingKey('');
        } else {
            newData.push(row);
            setData(newData);
            setEditingKey('');
        }
        } catch (errInfo) {
          logger.error("Validate Failed", errInfo);
        }
    };


    const columns = [
        {
            title: 'Reasons',
            dataIndex: 'reasons',
            // key: 'reasons',
            editable: false,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            // key: 'status',
            editable: true,
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            // key: 'notes',
            editable: true,
        },
        {
            title: 'Date/Time',
            dataIndex: 'applied_on',
            // key: 'applied_on',
            editable: false,
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            render(_, record) {
                const editable = isEditing(record);
                return editable ? (
                <span>
                    <a
                    href="javascript:;"
                    onClick={() => save(record.key)}
                    style={{
                        marginRight: 8,
                    }}
                    disabled={disableFlag}
                    >
                    Save
                    </a>
                    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <a disabled={disableFlag}>Cancel</a>
                    </Popconfirm>
                </span>
                ) : (
                <a disabled={editingKey !== '' || disableFlag} onClick={() => edit(record)}>
                    Edit
                </a>
                );
            },
            },
    ];
    

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
        return col;
        }

        return {
        ...col,
        onCell: record => ({
            record,
            inputType: col.dataIndex === 'notes' ? 'text' : 'select',
            // inputType: 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            disabled: disableFlag
        }),
        };
    });

    // const dataSource = data ? data.fundingDelays : []
    return (
        <Row>
            <Col span={24}>
                <Form form={form} component={false}>
                    <Table 
                      className='slc-table'
                      components={{
                          body: {
                          cell: EditableCell,
                          },
                      }}
                      bordered
                      dataSource={data}
                      columns={mergedColumns}
                      rowClassName="editable-row"
                      pagination={{
                        onChange: cancel,
                      }}
                      rowKey="id" 
                      size="small"
                    />
                </Form>
            </Col>
        </Row>

    );
}
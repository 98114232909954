import React, { useEffect } from 'react';
import './Dashboard.css'
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import { Layout, PageHeader, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';
import capitalize from '../../util/Util';
import { fetchPendingWelcomeCalls } from '../../reducers/PendingWelcomeCalls'
import { fetchAdminusers } from '../../reducers/Adminusers'

import { useSelector, useDispatch } from "react-redux";


const { Content } = Layout;
const { Title } = Typography;

export default function Dashboard({ ...props }) {
  const { allowedActions } = props;
  const dispatch = useDispatch();

  const welcomeCallActions = GetResourceActions('WelcomeCall', allowedActions);
  const allowedGet = IsActionAllowed('get', welcomeCallActions);

  const columns = [
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      //width:'14%',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.dueDateOrg) - new Date(b.dueDateOrg)
    },
    {
      title: 'Lease Number',
      dataIndex: 'leaseNumber',
      key: 'leaseNumber',
      sorter: (a, b) => a.leaseNumber.leaseNumber - b.leaseNumber.leaseNumber,
      render(val) {
        return <Link style={{ textDecoration: 'underline' }} to={`/lease-applications/${val.id}`}>{val.leaseNumber}</Link>
      }
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => ('' + a.firstName).localeCompare(b.firstName)
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => ('' + a.lastName).localeCompare(b.lastName)
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => ('' + a.state).localeCompare(b.state)
    },
    {
      title: 'Dealership',
      dataIndex: 'dealership',
      key: 'dealership',
      sorter: (a, b) => ('' + a.dealership).localeCompare(b.dealership)
    },
    {
      title: 'Funding Approved Date',
      dataIndex: 'fundingApprovedDate',
      key: 'fundingApprovedDate',
      sorter: (a, b) => new Date(a.fundingApprovedDateOrg) - new Date(b.fundingApprovedDateOrg)
    },
    {
      title: 'Funded Date',
      dataIndex: 'fundedDate',
      key: 'fundedDate',
      sorter: (a, b) => new Date(a.fundedDateOrg) - new Date(b.fundedDateOrg)
    },
    {
      title: 'First Payment Date',
      dataIndex: 'firstPaymentDate',
      key: 'firstPaymentDate',
      sorter: (a, b) => new Date(a.firstPaymentDateOrg) - new Date(b.firstPaymentDateOrg)
    },
    {
      title: 'Representative(s) Assigned',
      dataIndex: 'representativeAssigned',
      key: 'representativeAssigned',
      sorter: (a, b) => ('' + a.representativeAssigned).localeCompare(b.representativeAssigned)
    },
    {
      title: 'Last Disposition',
      dataIndex: 'lastDispositionResult',
      key: 'lastDispositionResult',
      sorter: (a, b) => ('' + a.lastDispositionResult).localeCompare(b.lastDispositionResult)
    },
    {
      title: 'Number of Attempts',
      dataIndex: 'pendingWelcomeCallsCount',
      key: 'pendingWelcomeCallsCount',
      sorter: (a, b) => a.pendingWelcomeCallsCount - b.pendingWelcomeCallsCount,
    }
  ]

  const pendingWelcomeCallsData = useSelector((state) => state.pendingWelcomeCalls);
  const loading = pendingWelcomeCallsData.isLoading

  let dataSource = []
  if (pendingWelcomeCallsData?.pendingWelcomeCalls !== undefined) {
    dataSource = pendingWelcomeCallsData.pendingWelcomeCalls.map((item) =>
      Object.assign({}, item, {
        dueDateOrg: item.dueDate,
        dueDate: item.dueDate,
        fundingApprovedDateOrg: item.fundingApprovedOn,
        fundingApprovedDate: item.fundingApprovedOn ? dayjs(item.fundingApprovedOn).format('MM/DD/YYYY') : "",
        fundedDateOrg: item.fundedOn,
        fundedDate: item.fundedOn ? dayjs(item.fundedOn).format('MM/DD/YYYY') : "",
        firstPaymentDateOrg: item.firstPaymentDate,
        firstPaymentDate: item.firstPaymentDate ? dayjs(item.firstPaymentDate).format('MM/DD/YYYY') : "",
        leaseNumber: {
          leaseNumber: item.leaseNumber,
          id: item.id
        },
        firstName: capitalize(item.firstName),
        lastName: capitalize(item.lastName),
      })
    )
  }


  useEffect(()=>{
    dispatch(fetchPendingWelcomeCalls())
  }, [])

  return (
    <Spin spinning={loading}>
      <Layout>
        <MainHeader activePage="dashboard" />
        {
          allowedGet ? (
            <Layout id='content-area-layout'>
              <Content id='main-content'>
                <Title level={1}>Pending Welcome Calls</Title>
                {dataSource && (<Table
                  className='table-content slc-content-table'
                  rowKey={(val) => val.id}
                  dataSource={dataSource}
                  columns={columns}
                />)}

              </Content>
            </Layout>
          ) :
            <PageHeader
              className="site-page-header"
              title="Coming soon!"
            />
        }
        <MainFooter />
      </Layout>
    </Spin>
  );
}
import React from 'react'
import { Row, Col, Form, Input, Radio, Typography } from 'antd';
const { Text } = Typography

export default function LeaseTerm(props) {
    const { leaseApplication, radioButtonState, formatAddress } = props;

    return (
        <>


            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={24}>
                    <Text strong level={4} className='vcc-text'>
                    Now, we will review the terms of the lease:
                    </Text>
                </Col>
            </Row>
            {/* Lease Term */}
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={24}>
                    <Text level={4} className='vcc-text'>
                        Can you please confirm the number of months the lease is for? <Text strong>{leaseApplication.payment ? leaseApplication.payment.paymentTerm : null}</Text> months.
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item
                        name={['leaseTermConfirm']}
                        className='vcc-form-item'
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {!radioButtonState.leaseTermConfirm &&
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form.Item
                            name={['leaseTermConfirmComment']}
                            className='vcc-form-item'
                            rules={[{ required: true, message: 'Required' }]}
                        >
                            <Input  placeholder='Comments' />
                        </Form.Item>
                    </Col>
                }
            </Row>
        </>
    )
}

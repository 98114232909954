import React from "react";
import {Layout, Button} from "antd";
import '../../App.css';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import LesseesDataGrid from './components/LesseesDataGrid'
import {styled} from '@mui/system';
import Typography from "@material-ui/core/Typography";
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import LesseeFilter from './LesseeFilter'

export default function LesseesList(props) {
  const {Content} = Layout;
  const history = useHistory();

  const {data, loading, pageState, setPageState, total} = props;

  const columns = [
    {
      field: 'id', headerName: 'Id', type: 'string', flex: 0.7, minWidth: 10, filterable: false
    },
    {
      field: 'fullName', headerName: 'Full Name', type: 'string', flex: 1, minWidth: 75, filterable: false
    },
    {
      field: 'dateOfBirth', headerName: 'Date of Birth', type: 'string', flex: 1, minWidth: 50, filterable: false
    },
    {
      field: 'leaseApplicationIdentifier',
      headerName: 'Lease Application Id',
      type: 'string',
      flex: 1,
      minWidth: 75,
      filterable: false
    },
    {
      field: 'dealership', headerName: 'Dealership', type: 'string', flex: 1, minWidth: 50, filterable: false
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'action',
      flex: 1,
      filterable: false,
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{textTransform: "none"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/lessee/${record.row.id}`,
                });
              }}
            >
              View
            </Button>
            <Button
              style={{textTransform: "none", marginLeft: "1rem"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/lessee_edit/${record.row.id}`,
                });
              }}
            >
              Edit
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ]

  return (
    <Layout>
      <MainHeader activePage="lesseeRelated"/>
      <Layout>
        <Layout id='content-area-layout'>
          <Content id='main-content' style={{backgroundColor: "#ffff !important"}}>
            <Box sx={{flexGrow: 1, pb: 2}}>
              <Grid container spacing={2} direction="row" justifyContent="flex-start">
                <Grid item md={4} sm={4}>
                  <Typography variant={"h4"}>Lessees</Typography>
                </Grid>
              </Grid>
            </Box>
            <LesseeFilter pageState={pageState} setPageState={setPageState}/>
            <StyledBox>
              {
                data &&
                <LesseesDataGrid
                  columns={columns}
                  data={data}
                  loading={loading}
                  setPageState={setPageState}
                  pageState={pageState}
                  total={total}
                />
              }
            </StyledBox>
          </Content>
        </Layout>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})

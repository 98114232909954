import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Checkbox, Col, Input, Form, Layout, Menu, message, Result, Row, Select, Space, Spin } from 'antd';
import Icon from '@ant-design/icons';
import { GET, POST } from '../../util/network';
import logger from '../../util/logger';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import { AddNew } from '../../components/layouts/Svg';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

const { Content, Sider } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function NewAdminUser(props) {
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory();

  const adminUserActions = GetResourceActions("AdminUser", allowedActions);
  const allowedCreate = IsActionAllowed("create", adminUserActions);

  const [loading, setLoading] = useState(false);

  const [adminUserForm] = Form.useForm();

  const [securityRoleOptions, setSecurityRoleOptions] = useState([]);
  const [dealershipsOptions, setDealershipsOptions] = useState([]);

  const [passwordValidateStatus, setPasswordValidateStatus] = useState(undefined);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(undefined);

  const getSecurityRoles = async () => {
    if (allowedCreate) {
      setLoading(true)

      try {
        await GET(`/api/v1/security-roles/security-roles-options`).then(response => {
          setSecurityRoleOptions(response.data.security_roles_options || [])
        }).catch(error => {
          if (error.response && error.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error("Error while fetching Security Roles")
          }
        })
      } catch (e) {
        logger.error("Error while fetching Security Roles", e)
      }

      setLoading(false)
    }
  }

  const getDealershipOptions = async () => {
    if (allowedCreate) {
      setLoading(true)

      try {
        await GET(`/api/v1/dealerships/dealerships-options`).then(response => {
          setDealershipsOptions(response.data.dealerships_options || [])
        }).catch(error => {
          if (error.response && error.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error("Error while fetching Dealerships Options")
          }
        })
      } catch (e) {
        logger.error("Error while fetching Dealerships Options", e)
      }

      setLoading(false)
    }
  }

  const save = async (values) => {
    if (allowedCreate) {
      setLoading(true)

      setPasswordValidateStatus(undefined)
      setPasswordErrorMsg(undefined)

      try {
        await POST(`/api/v1/admin-users`, values).then(response => {
          message.success("Saved successfully")
          history.push("/administration/admin-users")
        }).catch(error => {
          if (error.response && error.response.status === 401) {
            handleNoAuth(history)
          } else {
            if (error.response.data && error.response.data.message) {
              Object.entries(error.response.data.message).map(([key, value]) => {
                switch (key) {
                  case 'password':
                    setPasswordValidateStatus("error")
                    setPasswordErrorMsg(`Password ${value}`)
                    break
                  default:
                    message.error(`${key[0].toUpperCase()}${key.substring(1)} ${value}`)
                    break
                }
              })
            } else {
              message.error("Error while saving!")
            }
          }
        })
      } catch (e) {
        logger.error("Error while saving!", e)
      }

      setLoading(false)
    }
  }

  const handleSubmit = async (values) => {
    values = { ...values }
    save(values)
  }

  useEffect(() => {
    getSecurityRoles()
    getDealershipOptions()
  }, [])

  return (
    <Spin
      spinning={loading}
      size="large"
      tip="Loading..."
    >
      <Layout>
        <MainHeader activePage="administration" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1"><Icon component={AddNew} />Add New</Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "Administration", link_type: "ahref", link: void(0) },
                { text: "Admin Users", link_type: "ahref", link: "/administration/admin-users" },
                { text: "Add New", link_type: "ahref", link: void(0) }
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  {
                    !allowedCreate
                      ?
                        <Result
                          status="warning"
                          title="You are not allowed to access this page."
                        />
                      :
                        <Form
                          form={adminUserForm}
                          {...layout}
                          colon={false}
                          onFinish={handleSubmit}
                        >
                          <Row gutter={[16, 16]}>
                            <Col span={24}>
                              <Card title="User Info">
                                <Row gutter={[24, 16]}>
                                  <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                    <Form.Item
                                      label="Email"
                                      name="email"
                                      hasFeedback
                                      messageVariables={{ name: "Email" }}
                                      rules={[{ required: true }]}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      label="Password"
                                      name="password"
                                      hasFeedback
                                      messageVariables={{ name: "Password" }}
                                      validateStatus={passwordValidateStatus}
                                      help={passwordErrorMsg}
                                      rules={[
                                        {
                                          required: true,
                                          min: 8,
                                          max: 128
                                        }
                                      ]}
                                    >
                                      <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                      label="Confirm Password"
                                      name="password_confirmation"
                                      messageVariables={{ name: "Password Confirmation" }}
                                      hasFeedback
                                      rules={[
                                        {
                                          required: true,
                                          min: 8,
                                          max: 128
                                        },
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                              return Promise.resolve()
                                            }
                                            return Promise.reject(new Error("Passwords do not match!"))
                                          }
                                        })
                                      ]}
                                    >
                                      <Input.Password />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                    <Form.Item
                                      label="First Name"
                                      name="first_name"
                                      hasFeedback
                                      messageVariables={{ name: "First Name" }}
                                      rules={[{ required: true }]}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      label="Last Name"
                                      name="last_name"
                                      hasFeedback
                                      messageVariables={{ name: "Last Name" }}
                                      rules={[{ required: true }]}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      label="Job Title"
                                      name="job_title"
                                      hasFeedback
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </Row>

                          <Row gutter={[16, 16]} className="mt-20">
                            <Col span={24}>
                              <Card title="Auto Dealership Login">
                                <Row gutter={[24, 16]}>
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                      name="is_dealership_login"
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Is Dealer Login</Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </Row>

                          <Row gutter={[16, 16]} className="mt-20">
                            <Col span={24}>
                              <Card title="User Security">
                                <Row gutter={[24, 16]}>
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                      name="is_active"
                                      valuePropName="checked"
                                    >
                                      <Checkbox>User is Active</Checkbox>
                                    </Form.Item>

                                    <Form.Item
                                      label="Security Roles"
                                      name="security_role_ids"
                                      hasFeedback
                                    >
                                      <Select mode="multiple">
                                        {
                                          securityRoleOptions && securityRoleOptions.map(({value, label}, index) => {
                                            return <Option key={index} value={value}>{label}</Option>
                                          })
                                        }
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </Row>

                          <Row gutter={[16, 16]} className="mt-20">
                            <Col span={24}>
                              <Card title="Dealer Representation">
                                <Row gutter={[24, 16]}>
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                      name="is_dealer_representative"
                                      valuePropName="checked"
                                    >
                                      <Checkbox>Is Dealer Representative</Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row gutter={[24, 16]}>
                                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                      label="Dealerships"
                                      name="dealership_ids"
                                      hasFeedback
                                    >
                                      <Select mode="multiple">
                                        {
                                          dealershipsOptions && dealershipsOptions.map(({value, label}, index) => {
                                            return <Option key={index} value={value}>{label}</Option>
                                          })
                                        }
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </Row>

                          <Row gutter={[16, 16]} className="mt-20">
                            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                              <Row>
                                <Col span={24}>
                                  <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                      Save
                                    </Button>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                  }
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  )
}

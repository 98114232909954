import React, { useState, useEffect } from "react";
import {Card, Row, Col, Button, message, Input, Typography} from 'antd'
import HistoricalRelated from '../HistoricalRelated'
import {GET} from "../../../util/network";
import logger from "../../../util/logger";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { fetchLeaseApplicationDetails, getDetails, clearSsnChanged } from '../../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";
import LicenseRelated from '../LicenseRelated'

const { Search } = Input;
const { Text } = Typography;

export default function HistoricalRelatedCard({ data, handleNoAuth, getApplicationDetails, setLoading, save }) {
    const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    if(authData !== null) {
        loggedInUserId = authData.id;
    }
    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = locked_by != loggedInUserId ? true : false
    const dispatch = useDispatch();
    const [searchVal, setSearchVal] = useState('')
    const [licenseSearchResults, setLicenseSearchResults] = useState([])

    useEffect(() => {
      if(searchVal.length == 0){
        handleSearch();
      }
    }, [searchVal]);

    const handleRepullLesseeCredit = async (lessee_id, type) => {
        setLoading(true);
        try {
            await GET(`/api/v1/lease_applications/repull-lessee-credit?lessee_id=${lessee_id}`);
            message.success("Credit Reports Will Be Repulled and Appear as a File Attachment Shortly");
            let values = {}
            if(type === 'lessee') {
                values.lessee_attributes = {
                    id: data.lessee.id,
                    ssn_changed: false
                }
            }
            else if (type === 'colessee'){
                values.colessee_attributes = {
                    id: data.colessee.id,
                    ssn_changed: false
                }
            }
            await save(values)
            dispatch(clearSsnChanged({ type: type }))
        } catch (e) {
            logger.error("Error processing Credit Reports", e);

            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error('Error processing credit reports');
            }
        }
        setLoading(false);
    }

    const handleSearch = async () => {
      setLoading(true);
      try {
        const response = await GET(`/api/v1/lease_applications/license-search?license_search=${searchVal}`);
        setLicenseSearchResults(response.data.data)
      } catch (e) {
        logger.error("Error processing License Search", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error processing License Search');
        }
      }
      setLoading(false);
    }


  return (
    <Card title="Historical / Related Applications" className={data?.historicalRelated?.length > 0 ? "historical-related-table-bordered" : ""}>
      <Row className='flex-jc-fs'>
          <Col span={24}>
            <Button type='primary' onClick={async () => {
                setLoading(true);
                const result = await fetchLeaseApplicationDetails({ id: data.id });
                dispatch(getDetails(result.data.leaseApplication));
                setLoading(false);
            }} style={{ float: 'right' }} disabled={disableFlag}>
                <Loading3QuartersOutlined/>Refresh
            </Button>
          </Col>
      </Row>
      <Row>
        <Col span={24}>
          <HistoricalRelated data={data} />
        </Col>
      </Row>
      <Typography.Title level={4}>
        License Related Applications
      </Typography.Title>
      <Row>
        <Col span={24}>
          <LicenseRelated data={data.historicalLicenseRelated} />
        </Col>
      </Row>
      {
        data.historicalLicenseRelated.length == 0 && <br/>
      }
      {/*<Card title="License Related Applications">*/}
        {/*<Row>*/}
        {/*    <Col span={24}>*/}
        {/*        <HistoricalRelated data={data} />*/}
        {/*    </Col>*/}
        {/*</Row>*/}
        <Row>
          <Col span={8}>
            <Search placeholder="License Search" onChange={(e) => setSearchVal(e.target.value)} onSearch={handleSearch} allowClear enterButton/>
          </Col>
          <Col span={16}>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col span={24}>
            {
              licenseSearchResults.length > 0 && <LicenseRelated data={licenseSearchResults} disableFlag={disableFlag}/>
            }
          </Col>
        </Row>
      {/*</Card>*/}
      <Row className='flex-jc-fs'>
          {
              data.creditStatus !== 'Unsubmitted' && data?.historicalRelated?.length > 0 && data.lessee.ssnChanged && data.showCbcLesseeRepull &&
            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                <Button
                  className='slc-button-link-only'
                  type="link"
                  onClick={ () => { handleRepullLesseeCredit(data.lessee.id, 'lessee') } }
                  disabled={disableFlag}
                >
                    Repull Lessee Credit
                </Button>
            </Col>
          }
          {
              data.colessee && data.creditStatus !== 'Unsubmitted' && data.historicalRelated.length > 0 && data.colessee.ssnChanged && data.showCbcColesseeRepull &&
            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                <Button
                  className='slc-button-link-only'
                  type="link"
                  onClick={ () => { handleRepullLesseeCredit(data.colessee.id, 'colessee') } }
                  disabled={disableFlag}
                >
                    Repull Co-Lessee Credit
                </Button>
            </Col>
          }
        </Row>
    </Card>
  )
}

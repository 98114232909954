import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from '../util/network';

export const fetchAdminusers = createAsyncThunk('dashboard/fetchAdminusers', async (params) => {
  let response;
  if (params.pageState !== undefined) {
    let encodedParam = Object.entries(params.pageState).map(([key, val]) => `${key}=${val}`).join('&');
    response = await GET(`/api/v1/admin-users?` + encodedParam);
  } else {
    response = await GET(`/api/v1/admin-users`);
  }
  return {data: response?.data} || {};
})

const initialState = {
  adminList: [],
  pageState: {
    total: 0,
    page: 0,
    limit: 50,
    search: "",
  },
  isLoading: false,
}

export const adminUsersSlice = createSlice({
  name: "adminUsers",
  initialState: initialState,
  reducers: {
    updatePageState: (state, action) => {
      return {
        ...state,
        pageState: {
          ...state.pageState, ...action.payload
        },
      }
    },
    updateAdminUsers: (state, action) => {
      state = action.payload.adminUsers;
      return state
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAdminusers.fulfilled, (state, action) => {
      state.isLoading = true;
      state.adminList = action.payload.data;
      state.isLoading = false;
      return state
    }),
      builder.addCase(fetchAdminusers.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchAdminusers.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload;
      });
  }
});

export const { updatePageState, updateAdminUsers } = adminUsersSlice.actions;

export default adminUsersSlice.reducer;

import React, { useEffect, useState } from 'react';

import { Spin, Layout, Menu, Row, Divider, Col, Typography, Card, Form, Input, InputNumber, Button, Radio, message } from 'antd';
import MaskedInput from 'antd-mask-input';
import moment from 'moment';
import _ from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { useHistory } from 'react-router-dom';
import { GET, PUT, POST } from '../../../util/network';
import logger from '../../../util/logger';
import MainHeader from '../../../components/layouts/MainHeader';
import MainBreadcrumb from '../../../components/layouts/MainBreadcrumb';
import MainFooter from '../../../components/layouts/MainFooter';
import { SiderProps } from '../../../components/layouts/MainSiderProps';
import { fetchLeaseApplicationDetails, getDetails } from '../../../reducers/LeaseApplications'
import { useSelector, useDispatch } from "react-redux";
import './VerificationCallChecklist.css'
import HeaderInfo from './HeaderInfo';
import IntroductoryInfo from './IntroductoryInfo';
import SocialSecurityNumber from './Questions/SocialSecurityNumber';
import DateOfBirth from './Questions/DateOfBirth';
import StreetAddress from './Questions/StreetAddress';
import PhoneNumber from './Questions/PhoneNumber';
import LeaseTerm from './Questions/LeaseTerm';
import MonthlyPayment from './Questions/MonthlyPayment';
import PaymentFrequency from './Questions/PaymentFrequency';
import Residual from './Questions/Residual';
import TestDriven from './Questions/TestDriven';
import WorkingOrder from './Questions/WorkingOrder';
import FooterInfo from './FooterInfo';

const { Content, Sider } = Layout;
const { Text } = Typography;
const { TextArea } = Input;

export default function VerificationCallChecklist(props) {
  const { handleNoAuth } = props;
  const { id } = props.match.params;
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const leaseApplication = useSelector((state) => state.leaseApplications.leaseApplicationDetails);

  const [form] = Form.useForm();

  const [secondPaymentDateDisplay, setSecondPaymentDateDisplay] = useState(undefined);
  const [emailValidateStatus, setEmailValidateStatus] = useState(undefined);
  const [emailErrorMessage, setEmailErrorMessage] = useState(undefined);
  const radioFields = [
    'bikeInWorkingOrder',
    'dueDatesMatchLesseePayDate',
    'firstPaymentDateConfirm',
    'lesseeAvailableToSpeak',
    'lesseeCanReceiveTextMessages',
    'lesseeConfirmResidualValue',
    'lesseeDateOfBirthConfirm',
    'lesseeHasTestDrivenBike',
    'lesseeSocialSecurityConfirm',
    'lesseeStreetAddressConfirm',
    'leaseTermConfirm',
    'monthlyPaymentConfirm',
    'paymentFrequencyConfirm',
    'secondPaymentDateConfirm'
  ];

  let radioInputDefault = [];

  radioFields.forEach((val) => {
    radioInputDefault.[val] = true;
  })

  const [radioButtonState, setRadioButtonState] = useState(radioInputDefault);

  const [displayGeneratePdfButton, setDisplayGeneratePdfButton] = useState(false);

  const setRadioInputFromGet = (data) => {
    radioFields.forEach((field) => {
      radioButtonState.[field] = data[field];
    });
  }

  const getOnlineVerificationChecklist = async () => {
    setLoading(true)
    try {
      const response = await GET(`/api/v1/online-verification-call-checklists/${id}`)
      setData(response.data)
      setRadioInputFromGet(response.data);
    } catch (e) {
      logger.error("Checklist Details Error", e)

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while fetching checklist information');
      }
    }
    setLoading(false)
  };

  const putOnlineVerificationChecklist = async (payload) => {
    setLoading(true)
    try {
      const response = await PUT(`/api/v1/online-verification-call-checklists/${id}`, payload)
      message.success('Data saved successfully');
      setData(response.data)
      setDisplayGeneratePdfButton(true)
    } catch (e) {
      logger.error("Checklist Details Error", e)

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while fetching checklist information');
      }
    }
    setLoading(false)
  };

  const generatePdf = async (event) => {
    setLoading(true)
    event.preventDefault()

    try {
      const response = await POST(`/api/v1/online-verification-call-checklists/${id}/generate_pdf`)

      if (response.data.url) {
        message.success(response.data.message)
        let url = response.data.url.match(/^http[s]?:\/\//) ? response.data.url : 'http://' + response.data.url
        window.open(url, "_blank")
      } else {
        message.error('Error: PDF URL not found!');
      }
    } catch (e) {
      logger.error("Request Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while getting PDF URL');
      }
    }

    setLoading(false)
  };

  const onFinish = async (values) => {
    const hasIssue = checkForIssue(values);

    const commentFields = [
      'bikeInWorkingOrderComment',
      'dueDatesMatchLesseePayDateComment',
      'firstPaymentDateConfirmComment',
      'leaseTermConfirmComment',
      'lesseeAvailableToSpeakComment',
      'lesseeCanReceiveTextMessagesComment',
      'lesseeConfirmResidualValueComment',
      'lesseeDateOfBirthConfirmComment',
      'lesseeHasTestDrivenBikeComment',
      'lesseeSocialSecurityConfirmComment',
      'lesseeStreetAddressConfirmComment',
      'monthlyPaymentConfirmComment',
      'paymentFrequencyConfirmComment',
      'secondPaymentDateConfirmComment'
    ];

    commentFields.forEach((val) => {
      values.[val] = values.[val] ? values.[val] : null;
    })

    values = {
      issue: hasIssue,
      ...values
    };

    putOnlineVerificationChecklist({ onlineVerificationCallChecklist: values })
  };

  const onFinishFailed = () => {
    message.error("Please complete all required fields")
  };

  const checkForIssue = (values) => {
    for (const value of Object.keys(values)) {
      if (values[value] == false) {
        return true;
      }
    }

    return false;
  }

  const formatAddress = (lessee) => {
    // https://lodash.com/docs/#compact
    return _.compact([lessee.street1, lessee.street2, lessee.city, lessee.state, lessee.zipcode]).join(", ")
  };

  const formatDate = (dateStr) => {
    return dateStr ? moment(dateStr).format("MMMM DD, YYYY") : null
  };

  const processPaymentFrequency = (leaseApplication) => {
    switch (leaseApplication.paymentFrequency) {
      case "full": {
        form.setFieldsValue({ paymentFrequencyType: 2 })
        setSecondPaymentDateDisplay(leaseApplication.payment ? generateSecondPaymentDate(leaseApplication.payment.firstPaymentDate) : "N/A")
        break;
      }
      case "split": {
        form.setFieldsValue({ paymentFrequencyType: 1 })
        setSecondPaymentDateDisplay(leaseApplication.payment ? formatDate(leaseApplication.payment.secondPaymentDate) : "N/A")
        break;
      }
      case "one_time": {
        form.setFieldsValue({ paymentFrequencyType: null })
        setSecondPaymentDateDisplay(null)
        break;
      }
    }
  };

  const processLeaseVerificationCallData = (leaseApplication) => {
    if (leaseApplication.leaseVerificationCall) {
      setDisplayGeneratePdfButton(leaseApplication.leaseVerificationCall.callExists)
    }
  };

  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$'
  }

  const currencyFormatter = (value, options) => {
    if (typeof value !== 'number') value = 0.0;

    options = { ...defaultOptions, ...options };
    value = value.toFixed(options.significantDigits);

    const [currency, decimal] = value.split('.');
    return `${options.symbol}${currency.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      options.thousandsSeparator
    )}${options.decimalSeparator}${decimal}`;
  }

  const generateSecondPaymentDate = (date) => {
    let dateObj = moment(date, "YYYY-MM-DD")
    let resultDateObj = moment(date, "YYYY-MM-DD")

    resultDateObj.add(1, 'months')

    if (dateObj.date() > resultDateObj.daysInMonth()) {
      resultDateObj.date(resultDateObj.daysInMonth())
    } else {
      resultDateObj.date(dateObj.date())
    }

    return formatDate(resultDateObj)
  };

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];

    if (!formFieldName) {
      return false;
    }

    if (!radioFields.includes(formFieldName)) {
      return false;
    }

    const newValue = changedValues[formFieldName];

    setRadioButtonState({
      ...radioButtonState,
      [formFieldName]: newValue
    });

    resetCommentField(formFieldName, newValue)
  }

  const resetCommentField = (formFieldName, newValue) => {
    const commentElementName = formFieldName + 'Comment';
    const commentElement = document.getElementById(commentElementName);

    if (!commentElement) {
      return false;
    }

    if (newValue) {
      form.setFieldsValue({
        [commentElementName]: null
      })
    }
  }

  const validateEmail = () => {
    let input = form.getFieldValue(['lesseeEmail']);

    if (!isEmail(input)) {
      setEmailValidateStatus('error')
      setEmailErrorMessage('Please enter a valid email address.')
    } else {
      setEmailValidateStatus(undefined)
      setEmailErrorMessage(undefined)
    }
  };

  const dispatch = useDispatch();
  const getApplicationDetails = async () => {
    setLoading(true)
    const response = await fetchLeaseApplicationDetails({ id: id });
    if (response) {
      dispatch(getDetails(response.data.leaseApplication));
      setLoading(false)
    }
  };

  useEffect(() => {
    getOnlineVerificationChecklist();
    if (leaseApplication.id === undefined || props.match.params.id != leaseApplication.id) {
      getApplicationDetails();
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <Layout>
        <MainHeader activePage="leaseApplication" />
        <Layout>
          <Sider {...SiderProps} >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ width: "100%", borderRight: 0 }}
            >
              <Menu.Item key="1">
                Verification Call
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "Lease Applications", link_type: "linkto", link: "/lease_applications" },
                { text: `${data?.applicationIdentifier || id}`, link_type: "linkto", link: `/lease-applications/${id}` },
                { text: "Verification Call Checklist", link_type: "ahref", link: void (0) }
              ]}
            />
            <Content id="main-content">
              {leaseApplication && data ? <>
                <Card>
                  <Form
                    form={form}
                    labelCol={{ span: 5 }}
                    layout="horizontal"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onValuesChange={handleFormValuesChange}
                    initialValues={data && {
                      bikeInWorkingOrder: data.bikeInWorkingOrder,
                      bikeInWorkingOrderComment: data.bikeInWorkingOrderComment,
                      dueDatesMatchLesseePayDate: data.dueDatesMatchLesseePayDate,
                      dueDatesMatchLesseePayDateComment: data.dueDatesMatchLesseePayDateComment,
                      firstPaymentDateConfirm: data.firstPaymentDateConfirm,
                      firstPaymentDateConfirmComment: data.firstPaymentDateConfirmComment,
                      lesseeAvailableToSpeak: data.lesseeAvailableToSpeak,
                      lesseeAvailableToSpeakComment: data.lesseeAvailableToSpeakComment,
                      lesseeBestPhoneNumber: data.lesseeBestPhoneNumber,
                      lesseeCanReceiveTextMessages: data.lesseeCanReceiveTextMessages,
                      lesseeCanReceiveTextMessagesComment: data.lesseeCanReceiveTextMessagesComment,
                      lesseeConfirmResidualValue: data.lesseeConfirmResidualValue,
                      lesseeConfirmResidualValueComment: data.lesseeConfirmResidualValueComment,
                      lesseeDateOfBirthConfirm: data.lesseeDateOfBirthConfirm,
                      lesseeDateOfBirthConfirmComment: data.lesseeDateOfBirthConfirmComment,
                      lesseeEmail: data.lesseeEmail,
                      lesseeHasTestDrivenBike: data.lesseeHasTestDrivenBike,
                      lesseeHasTestDrivenBikeComment: data.lesseeHasTestDrivenBikeComment,
                      lesseeReportedYear: data.lesseeReportedYear,
                      lesseeReportedMake: data.lesseeReportedMake,
                      lesseeReportedModel: data.lesseeReportedModel,
                      lesseeSocialSecurityConfirm: data.lesseeSocialSecurityConfirm,
                      lesseeSocialSecurityConfirmComment: data.lesseeSocialSecurityConfirmComment,
                      lesseeStreetAddressConfirm: data.lesseeStreetAddressConfirm,
                      lesseeStreetAddressConfirmComment: data.lesseeStreetAddressConfirmComment,
                      leaseTermConfirm: data.leaseTermConfirm,
                      leaseTermConfirmComment: data.leaseTermConfirmComment,
                      monthlyPaymentConfirm: data.monthlyPaymentConfirm,
                      monthlyPaymentConfirmComment: data.monthlyPaymentConfirmComment,
                      notes: data.notes,
                      paymentFrequencyConfirm: data.paymentFrequencyConfirm,
                      paymentFrequencyConfirmComment: data.paymentFrequencyConfirmComment,
                      paymentFrequencyType: data.paymentFrequencyType,
                      secondPaymentDateConfirm: data.secondPaymentDateConfirm,
                      secondPaymentDateConfirmComment: data.secondPaymentDateConfirmComment,
                      vehicleColor: data.vehicleColor,
                      vehicleMileage: data.vehicleMileage,
                      vinNumberLastSix: data.vinNumberLastSix,
                    }}
                  >
                    <HeaderInfo
                      data={data}
                      leaseApplication={leaseApplication}
                      formatDate={formatDate}
                    />
                    <Divider dashed />
                    <IntroductoryInfo
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                    />
                    <Divider />
                    <SocialSecurityNumber
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                    />
                    <DateOfBirth
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                    />
                    <StreetAddress
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                      formatAddress={formatAddress}
                    />
                    <Divider />
                    <PhoneNumber
                      radioButtonState={radioButtonState}
                      emailValidateStatus={emailValidateStatus}
                      emailErrorMessage={emailErrorMessage}
                      MaskedInput={MaskedInput}
                      validateEmail={validateEmail}
                    />
                    <Divider />
                    <Residual
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                      InputNumber={InputNumber}
                      currencyFormatter={currencyFormatter}
                    />
                    <TestDriven
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                    />
                    <WorkingOrder
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                    />
                    <Divider />
                    <LeaseTerm
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                    />
                    <MonthlyPayment
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                    />
                    <PaymentFrequency
                      leaseApplication={leaseApplication}
                      radioButtonState={radioButtonState}
                      formatDate={formatDate}
                      secondPaymentDateDisplay={secondPaymentDateDisplay}
                      currencyFormatter={currencyFormatter}
                    />
                    <Divider />
                    <FooterInfo
                      data={data}
                      formatDate={formatDate}
                      generatePdf={generatePdf}
                      displayGeneratePdfButton={displayGeneratePdfButton}
                    />
                  </Form>
                </Card>
              </> : <></>}

            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  )
}
import { Col, Row, Typography } from "antd";
import React from "react";
const { Title } = Typography;

export const CustomerMonthlyPaymentInfo = (props) => {
    const { calculatorData, convertUsd, maxAllowablePayment, customerMonthlyPaymentDanger } = props;
    return (
        <>
            <Title level={5} className="payment-calculator-headers"
                   style={{color: customerMonthlyPaymentDanger}}> Customer Monthly Payment </Title>
            <Row className={'bot-spacer-1'} style={{lineHeight: 2, color: customerMonthlyPaymentDanger}}>
                <Col span={12}>
                    Base Monthly Payment
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.baseMonthlyPayment && convertUsd(Number(calculatorData.baseMonthlyPayment))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1" style={{lineHeight: 2, color: customerMonthlyPaymentDanger}}>
                <Col span={12}>
                    Monthly Sales Tax
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.monthlySaleTax && convertUsd(Number(calculatorData.monthlySaleTax))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1"
                 style={{color: (customerMonthlyPaymentDanger === 'red' ? 'red' : '#1890ff'), lineHeight: 2}}>
                <Col span={12}>
                    <p> Total Monthly Payment <br/>(Max Approved Payment ${maxAllowablePayment})</p>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.totalMonthlyPayment && convertUsd(Number(calculatorData.totalMonthlyPayment))}</span>
                </Col>
            </Row>

            <Title level={5} className="payment-calculator-headers"> Capitalized Cost and Other Info </Title>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={12}>
                    Purchase Option (Residual)
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.purchaseOption && convertUsd(Number(calculatorData.purchaseOption))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={12}>
                    Adj. Capitalized Cost
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <span>{calculatorData.adjustedCapitalizedCost && convertUsd(Number(calculatorData.adjustedCapitalizedCost))}</span>
                </Col>
            </Row>
            <Row className="bot-spacer-1" style={{lineHeight: 2}}>
                <Col span={16}>
                    <p>Acquisition Fee </p>
                </Col>
                <Col span={8} style={{textAlign: 'right'}}>
                    <span>{calculatorData.acquisitionFee && convertUsd(Number(calculatorData.acquisitionFee))}</span>
                </Col>
            </Row>
        </>
    )
}

export default CustomerMonthlyPaymentInfo

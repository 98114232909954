import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Button, Form, Input, Radio, DatePicker, InputNumber, message, Select } from "antd";
import MaskedInput from 'antd-mask-input';
import { GET } from '../../util/network';
import logger from '../../util/logger';
import moment from 'moment';
import './Lessee.css';
import SsnInput from './components/SsnInput';
import isEmail from 'validator/lib/isEmail';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default ({ data, disableFlag, handleNoAuth, save }) => {
  const history = useHistory();

  const { colessee, colesseeAddedAfterSubmission } = data || {};
  const lessee = colessee
  const [personalForm] = Form.useForm();
  const [homeAddressForm] = Form.useForm();
  const [mailingAddressForm] = Form.useForm();

  const [lesseeHomeStateOptions, setLesseeHomeStateOptions] = useState([])
  const [lesseeHomeCountyOptions, setLesseeHomeCountyOptions] = useState([])
  const [lesseeHomeCityOptions, setLesseeHomeCityOptions] = useState([])
  const [lesseeHomeCityOptionsData, setLesseeHomeCityOptionsData] = useState([])

  const [lesseeMailStateOptions, setLesseeMailStateOptions] = useState([])
  const [lesseeMailCountyOptions, setLesseeMailCountyOptions] = useState([])
  const [lesseeMailCityOptions, setLesseeMailCityOptions] = useState([])
  const [lesseeMailCityOptionsData, setLesseeMailCityOptionsData] = useState([])

  const [zipHomeValidateStatus, setZipHomeValidateStatus] = useState(undefined)
  const [zipHomeErrorMessage, setZipHomeErrorMessage] = useState(undefined)

  const [zipMailValidateStatus, setZipMailValidateStatus] = useState(undefined)
  const [zipMailErrorMessage, setZipMailErrorMessage] = useState(undefined)

  const [eMailValidateStatus, setEmailValidateStatus] = useState(undefined)
  const [eMailErrorMessage, setEmailErrorMessage] = useState(undefined)
  const [motorcycleLicense, setMotorcycleLicense] = useState(false)
  const [licenseFormat, setLicenseFormat] = useState()

  const usStates = [
    { name: 'ALABAMA', abbreviation: 'AL' },
    { name: 'ALASKA', abbreviation: 'AK' },
    { name: 'ARIZONA', abbreviation: 'AZ' },
    { name: 'ARKANSAS', abbreviation: 'AR' },
    { name: 'CALIFORNIA', abbreviation: 'CA' },
    { name: 'COLORADO', abbreviation: 'CO' },
    { name: 'DELAWARE', abbreviation: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
    { name: 'FLORIDA', abbreviation: 'FL' },
    { name: 'GEORGIA', abbreviation: 'GA' },
    { name: 'ILLINOIS', abbreviation: 'IL' },
    { name: 'INDIANA', abbreviation: 'IN' },
    { name: 'KANSAS', abbreviation: 'KS' },
    { name: 'KENTUCKY', abbreviation: 'KY' },
    { name: 'LOUISIANA', abbreviation: 'LA' },
    { name: 'MAINE', abbreviation: 'ME' },
    { name: 'MARYLAND', abbreviation: 'MD' },
    { name: 'MASSACHUSETTS', abbreviation: 'MA' },
    { name: 'MICHIGAN', abbreviation: 'MI' },
    { name: 'MINNESOTA', abbreviation: 'MN' },
    { name: 'MISSISSIPPI', abbreviation: 'MS' },
    { name: 'MISSOURI', abbreviation: 'MO' },
    { name: 'MONTANA', abbreviation: 'MT' },
    { name: 'NEBRASKA', abbreviation: 'NE' },
    { name: 'NEVADA', abbreviation: 'NV' },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
    { name: 'NEW JERSEY', abbreviation: 'NJ' },
    { name: 'NEW MEXICO', abbreviation: 'NM' },
    { name: 'NEW YORK', abbreviation: 'NY' },
    { name: 'NORTH CAROLINA', abbreviation: 'NC' },
    { name: 'NORTH DAKOTA', abbreviation: 'ND' },
    { name: 'OHIO', abbreviation: 'OH' },
    { name: 'OKLAHOMA', abbreviation: 'OK' },
    { name: 'OREGON', abbreviation: 'OR' },
    { name: 'PENNSYLVANIA', abbreviation: 'PA' },
    { name: 'RHODE ISLAND', abbreviation: 'RI' },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { name: 'TENNESSEE', abbreviation: 'TN' },
    { name: 'TEXAS', abbreviation: 'TX' },
    { name: 'UTAH', abbreviation: 'UT' },
    { name: 'VERMONT', abbreviation: 'VT' },
    { name: 'VIRGINIA', abbreviation: 'VA' },
    { name: 'WASHINGTON', abbreviation: 'WA' },
    { name: 'WEST VIRGINIA', abbreviation: 'WV' },
    { name: 'WISCONSIN', abbreviation: 'WI' },
    { name: 'WYOMING', abbreviation: 'WY' },
    { name: 'CONNECTICUT', abbreviation: 'CT' },
    { name: 'IDAHO', abbreviation: 'ID' },
    { name: 'IOWA', abbreviation: 'IA' },
    { name: 'HAWAII', abbreviation: 'HI' },
  ].sort((a, b) => a.name.localeCompare(b.name))

  const stateLicenseFormat = (state) => {
    let format = /^$/
    if(state === 'ALABAMA'){ //1
      format = /^\d{7,8}$/
    }
    else if(state === 'ALASKA'){ //2
      format = /^\d{7}$/
    }
    else if(state === 'ARIZONA'){ //3
      format = /^[a-zA-Z]\d{8}$|^\d{9}$/
    }
    else if(state === 'ARKANSAS'){ //4
      format = /^9\d{8}$/
    }
    else if(state === 'CALIFORNIA'){ //5
      format = /^[a-zA-Z]\d{7}$/
    }
    else if(state === 'COLORADO'){ //6
      format = /^\d{2}-\d{3}-\d{4}$/
    }
    else if(state === 'CONNECTICUT'){ //7
      format = /^\d{9}$/
    }
    else if(state === 'DELAWARE'){ //8
      format = /^\d{7}$/
    }
    else if(state === 'DISTRICT OF COLUMBIA'){ //9
      format = /^\d{7}$/
    }
    else if(state === 'FLORIDA'){ //10
      format = /^[a-zA-Z]\d{3}\d{3}\d{3}\d{3}$|^[a-zA-Z]\d{12}$|^[a-zA-Z]\d{3}-\d{3}-\d{2}-\d{3}-\d$|^[a-zA-Z]\d{3}-\d{3}-\d{3}-\d{3}$/
    }
    else if(state === 'GEORGIA'){ //11
      format = /^\d{7,9}$/
    }
    else if(state === 'ILLINOIS'){ //12
      format = /^[a-zA-Z]\d{3}-\d{4}-\d{4}$|^[a-zA-Z]\d{11}$/
    }
    else if(state === 'INDIANA'){ //13
      format = /^\d{4}-\d{2}-\d{4}$/
    }
    else if(state === 'KANSAS'){ //14
      format = /^[a-zA-Z]\d{2}-\d{2}-\d{4}$|^\d{9}$/
    }
    else if(state === 'KENTUCKY'){ //15
      format = /^[a-zA-Z]\d{2}-\d{3}-\d{3}$/
    }
    else if(state === 'MAINE'){ //16
      format = /^\d{7}$|^\d{7}[a-zA-Z]$|^\d{8}$/
    }
    else if(state === 'LOUSIANA'){ //17
      format = /^\d{9}$/
    }
    else if(state === 'MARYLAND'){ //18
      format = /^[a-zA-Z]\d{12}$|^[a-zA-Z]-\d{3}-\d{3}-\d{3}-\d{3}$/
    }
    else if(state === 'MASSACHUSETTS'){ //19
      format = /^[a-zA-Z]\d{8}$|^\d{9}$/
    }
    else if(state === 'MICHIGAN'){ //20
      format = /^[a-zA-Z]\d{12}$|^[a-zA-Z]\s\d{3}\s\d{3}\s\d{3}\s\d{3}$/
    }
    else if(state === 'MINNESOTA'){ //21
      format = /^[a-zA-Z]\d{12}$/
    }
    else if(state === 'MISSISSIPPI'){ //22
      format = /^\d{3}-\d{2}-\d{4}$/
    }
    else if(state === 'MISSOURI'){ //23
      format = /^[a-zA-Z]\d{9}$|^\d{3}[a-zA-Z]\d{6}$|^[a-zA-Z]\d{6}R$|^\d{8}[a-zA-Z]{2}$|^\d{9}[a-zA-Z]$|^\d{9}$/
    }
    else if(state === 'MONTANA'){ //24
      format = /^(([0][1-9]|[1][0-2])\d{3}([1-9][0-9]{3})41([0][1-9]|[1][0-9]|[2][0-9]|[3][0-1]))$|^\d{9}$/
    }
    else if(state === 'NEBRASKA'){ //25
      format = /^[a-zA-Z]\d{6,8}$/
    }
    else if(state === 'NEVADA'){ //26
      format = /^\d{9,10}$|^\d{12}$/
    }
    else if(state === 'NEW HAMPSHIRE'){ //27
      format = /^([0][1-9]|[1][0-2])[a-zA-Z]{3}\d{2}(0[1-9]|[1-2][0-9]|3[0-1])\d$/
    }
    else if(state === 'NEW JERSEY'){ //28
      format = /^[a-zA-Z]\d{14}$|^[a-zA-Z]\d{4}\s\d{5}\s\d{5}$/
    }
    else if(state === 'NEW MEXICO'){ //29
      format = /^\d{8,9}$/
    }
    else if(state === 'NEW YORK'){ //30
      format = /^\d{3}\s\d{3}\s\d{3}$|^\d{9}$/
    }
    else if(state === 'NORTH CAROLINA'){ //31
      format = /^\d{12}$/
    }
    else if(state === 'NORTH DAKOTA'){ //32
      format = /^[a-zA-Z]{3}-\d{2}-\d{4}$/
    }
    else if(state === 'OHIO'){ //33
      format = /^[a-zA-Z]{1}[0-9]{4,8}$|^[a-zA-Z]{2}[0-9]{3,7}$|^[0-9]{8}$/
    }
    else if(state === 'OKLAHOMA'){ //34
      format = /^[a-zA-Z]\d{9}$|^\d{9}$/
    }
    else if(state === 'OREGON'){ //35
      format = /^\d{7}$/
    }
    else if(state === 'PENNSYLVANIA'){ //36
      format = /^\d{2}\s\d{3}\s\d{3}$/
    }
    else if(state === 'RHODE ISLAND'){ //37
      format = /^[1-9]{2}\d{5}$/
    }
    else if(state === 'SOUTH CAROLINA'){ //38
      format = /^\d{9}$/
    }
    else if(state === 'SOUTH DAKOTA'){ //39
      format = /^\d{8}$/
    }
    else if(state === 'TENNESSEE'){ //40
      format = /^\d{7,9}$/
    }
    else if(state === 'TEXAS'){ //41
      format = /^\d{8}$/
    }
    else if(state === 'UTAH'){ //42
      format = /^\d{9}$/
    }
    else if(state === 'VERMONT'){ //43
      format = /^\d{8}$|^\d{7}[a-zA-Z]$/
    }
    else if(state === 'VIRGINIA'){ //44
      format = /^[a-zA-Z]\d{2}-\d{2}-\d{4}$|^[a-zA-Z]\d{8}$|^\d{9}$|/
    }
    else if(state === 'WASHINGTON'){ //45
      format = /^[a-zA-Z]{3}\*\*[a-zA-Z]{2}\d{3}[a-zA-Z]\d$/
    }
    else if(state === 'WEST VIRGINIA'){ //46
      format = /^\d{7}$|^[a-zA-Z]\d{6}$|^[a-zA-Z]{2}\d{5}$/
    }
    else if(state === 'WISCONSIN'){ //47
      format = /^[a-zA-Z]\d{3}-\d{4}-\d{4}-\d{2}$/
    }
    else if(state === 'WYOMING'){ //48
      format = /^\d{6}-\d{3}$/
    }
    else if(state === 'IDAHO'){ //49
      format = /^[a-zA-Z]{2}\d{6}[a-zA-Z]$|^\d{9}$/
    }
    else if(state === 'IOWA'){ //50
      format = /^\d{3}[a-zA-Z]{2}\d{4}$|^\d{9}$/
    }
    else if(state === 'HAWAII'){ //51
      format = /^[a-zA-Z]\d{8}$|^\d{9}$/
    }
    return format;
  }

  const handleLesseeHomeZipcodeBlur = async () => {
    let zipcode = homeAddressForm.getFieldValue(['colesseeAttributes', 'homeAddressAttributes', 'zipcode'])

    try {
      await GET(`/api/v1/address/city-details?zipcode=${zipcode}`).then(response => {
        if (response.data.is_state_active_on_calculator) {
          setLesseeHomeStateOptions(formatCityDetailsData({ data: (response.data.state || []), type: 'state' }))
          setLesseeHomeCountyOptions(formatCityDetailsData({ data: (response.data.county || []), type: 'county' }))
          setLesseeHomeCityOptions(formatCityDetailsData({ data: (response.data.city || []), type: 'city', opts: { countyStateId: lessee?.homeAddress?.county } }))
          setLesseeHomeCityOptionsData(formatCityDetailsData({ data: (response.data.city || []), type: 'city' }))
        }

        if (!response.data.is_state_active_on_calculator || (response.data.city.length < 1 || response.data.city == undefined)) {
          setZipHomeValidateStatus("error")
          setZipHomeErrorMessage("Speed Leasing currently does not lease to residents of this state.")
        } else {
          setZipHomeValidateStatus(undefined)
          setZipHomeErrorMessage(undefined)

          let isSameValue = parseInt(lessee.homeAddress.zipcode) !== parseInt(zipcode)
          let isEmpty = zipcode !== null
          if (isSameValue && isEmpty) {
            homeAddressForm.setFieldsValue({
              colesseeAttributes: {
                homeAddressAttributes: {
                  county: undefined,
                  state: undefined,
                  cityId: undefined
                }
              }
            })
          }
        }
      }).catch(error => {
        logger.error("Request Error", error)
      })
    } catch (e) {
      logger.error("Request Error", e)
    }
  }

  const handleLesseeMailZipcodeBlur = async () => {
    let zipcode = mailingAddressForm.getFieldValue(['colesseeAttributes', 'mailingAddressAttributes', 'zipcode'])

    try {
      await GET(`/api/v1/address/city-details?zipcode=${zipcode}`).then(response => {
        if (response.data.is_state_active_on_calculator) {
          setLesseeMailStateOptions(formatCityDetailsData({ data: (response.data.state || []), type: 'state' }))
          setLesseeMailCountyOptions(formatCityDetailsData({ data: (response.data.county || []), type: 'county' }))
          setLesseeMailCityOptions(formatCityDetailsData({ data: (response.data.city || []), type: 'city', opts: { countyStateId: lessee?.mailingAddress?.county } }))
          setLesseeMailCityOptionsData(formatCityDetailsData({ data: (response.data.city || []), type: 'city' }))
        }

        if (!response.data.is_state_active_on_calculator || response.data.city.length < 1 || response.data.city == undefined) {
          setZipMailValidateStatus("error")
          setZipMailErrorMessage("Speed Leasing currently does not lease to residents of this state.")
        } else {
          setZipMailValidateStatus(undefined)
          setZipMailErrorMessage(undefined)

          let isSameValue = lessee.mailingAddress != null ? (parseInt(lessee.mailingAddress.zipcode) !== parseInt(zipcode)) : false
          let isEmpty = (zipcode !== null)
          if (isSameValue && isEmpty) {
            mailingAddressForm.setFieldsValue({
              colesseeAttributes: {
                mailingAddressAttributes: {
                  county: undefined,
                  state: undefined,
                  cityId: undefined
                }
              }
            })
          }
        }
      }).catch(error => {
        logger.error("Request Error", error)
      })
    } catch (e) {
      logger.error("Request Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error handling ZIP code');
      }
    }
  }

  const formatOptions = ({ options, type }) => {
    let newOptions = []
    if (type == 'collection') {
      options.map((value) => { newOptions.push({ value: value[1], label: value[0] }) })
    } else {
      options.map((value) => { newOptions.push({ value: value, label: value }) })
    }
    return newOptions
  }

  const formatCityDetailsData = ({ data, type, opts }) => {
    switch (type) {
      case 'city': {
        if (opts?.countyStateId) {
          return data.map(item => {
            return {
              value: item['id'],
              label: item['name'],
              parentId: item['countyId']
            }
          }).filter(item => item.parentId === parseInt(opts.countyStateId))
        } else {
          return data.map(item => {
            return {
              value: item['id'],
              label: item['name'],
              parentId: item['countyId']
            }
          })
        }
      }
      default: {
        return data.map(item => {
          return {
            value: item['id'],
            label: item['abbreviation'] ? item['abbreviation'] : item['name']
          }
        })
      }
    }
  }

  const handleHomeCountyStateChange = (countyStateId) => {
    if (countyStateId) {
      homeAddressForm.setFieldsValue({ colesseeAttributes: { homeAddressAttributes: { cityId: null } } })
      setLesseeHomeCityOptions(lesseeHomeCityOptionsData.filter(obj => obj.parentId === parseInt(countyStateId)))
    }
  }

  const handleMailingCountyStateChange = (countyStateId) => {
    if (countyStateId) {
      mailingAddressForm.setFieldsValue({ colesseeAttributes: { mailingAddressAttributes: { cityId: null } } })
      setLesseeMailCityOptions(lesseeMailCityOptionsData.filter(obj => obj.parentId === parseInt(countyStateId)))
    }
  }

  const handleLesseeEmailChange = () => {
    const email = personalForm.getFieldValue(['colesseeAttributes', 'emailAddress']);

    if (!isEmail(email)) {
      setEmailValidateStatus('error');
      setEmailErrorMessage('Please enter a valid email address.');
    } else {
      setEmailValidateStatus(undefined);
      setEmailErrorMessage(undefined);
    }
  }

  useEffect(() => {
    if (lessee && lessee.homeAddress && lessee.homeAddress.zipcode) {
      handleLesseeHomeZipcodeBlur()
    }
    if (lessee && lessee.mailingAddress && lessee.mailingAddress.zipcode) {
      handleLesseeMailZipcodeBlur()
    }
    if (lessee?.driverLicense) {
      setMotorcycleLicense(lessee?.driverLicense)
    }
    if (lessee?.licenseState) {
      setLicenseFormat(stateLicenseFormat(lessee?.licenseState))
    }
  }, []);


  const handleSubmit = async (values) => {
    values = { ...values };
    save(values)
  }

  return data ? (
    lessee &&  colesseeAddedAfterSubmission == false
      ?
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>

          <Card className='h-100' title="Personal">

            <Form
              form={personalForm}
              {...layout}
              colon={false}
              initialValues={{
                colesseeAttributes: {
                  id: lessee?.id,
                  relationshipToLesseeId: lessee?.relationshipToLesseeId,
                  firstName: lessee?.firstName,
                  middleName: lessee?.middleName,
                  lastName: lessee?.lastName,
                  suffix: lessee?.suffix,
                  dateOfBirth: lessee && moment(lessee?.dateOfBirth),
                  ssn: lessee?.ssn,
                  driversLicenseIdNumber: lessee?.driversLicenseIdNumber,
                  homeMobile: lessee?.homeMobile,
                  mobilePhoneNumber: lessee?.mobilePhoneNumber,
                  atAddressYears: lessee?.atAddressYears,
                  atAddressMonths: lessee?.atAddressMonths,
                  monthlyMortgage: lessee?.monthlyMortgage,
                  homeOwnership: lessee?.homeOwnership,
                  emailAddress: lessee?.emailAddress,
                  isDriving: lessee?.isDriving === 1 ? true : false,
                  driverLicense: lessee?.driverLicense,
                  licenseState: lessee?.licenseState,
                  motorcycleEndorsement: lessee?.motorcycleEndorsement,
                  homeAddressAttributes: {
                    street1: lessee?.homeAddress?.street1,
                    street2: lessee?.homeAddress?.street2,
                    zipcode: lessee?.homeAddress?.zipcode,
                    county: lessee?.homeAddress?.county,
                    state: lessee?.homeAddress?.stateId,
                    cityId: lessee?.homeAddress?.cityId,
                  }
                }
              }}
              onFinish={handleSubmit}
            >
              {
                lessee && <Form.Item style={{ display: 'none' }} name={['colesseeAttributes', 'id']} > <Input /> </Form.Item>
              }
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Relationship to Lessee</label>}
                    name={['colesseeAttributes', 'relationshipToLesseeId']}
                    hasFeedback
                    rules={[{ required: true, message: 'Relationship to Lessee required!' }]}
                  >
                    <Select disabled={disableFlag}>
                      {
                         data?.relationshipToLesseeOptions?.map(({ value, label }, index) => {
                          return <Option key={index} value={`${value}`}>{label}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}> First Name</label>}
                    name={['colesseeAttributes', 'firstName']}
                    hasFeedback
                    rules={[{ required: true, message: 'First Name is required!' }]}
                  >
                    <Input disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Middle Name" name={['colesseeAttributes', 'middleName']}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Last Name</label>}
                    name={['colesseeAttributes', 'lastName']}
                    hasFeedback
                    rules={[{ required: true, message: 'Last Name is required!' }]}
                  >
                    <Input disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Suffix (i.e. JR, SR, II, III, IV, etc.)"
                    name={['colesseeAttributes', 'suffix']}
                    hasFeedback
                  >
                    <Input disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Date of Birth (yyy-mm-dd)</label>}
                    name={['colesseeAttributes', 'dateOfBirth']}
                    hasFeedback
                    rules={[{ required: true, message: 'Date of Birth is required!' }]}
                  >
                    <DatePicker disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={<label style={{ color: "red" }}>Social Security Number</label>}
                name={['colesseeAttributes', 'ssn']}
                rules={[{ required: true, message: 'Social Security Number is required!' }]}
              >
                <Input type="hidden" disabled={disableFlag} />
              </Form.Item>

              <Row>
                <Col span={24}>
                  <Form.Item>
                    <SsnInput defaultValue={(lessee && lessee.ssn.replace(/-/g, "")) || ""} form={personalForm} lesseeType="colessee" disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Phone Number</label>}
                    name={['colesseeAttributes', 'mobilePhoneNumber']}
                    rules={[{ required: true, message: "Phone Number is required" }]}
                  >
                    <Input disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name={[`colesseeAttributes`, 'homeMobile']}
                    style={{ color: "red" }}
                    hasFeedback
                    rules={[{ required: true, message: 'Home Phone or Mobile Number is required' }]}
                  >
                    <Radio.Group disabled={disableFlag}>
                      <Radio value={1}>Mobile</Radio>
                      <Radio value={2}>Home</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Email Address</label>}
                    name={['colesseeAttributes', 'emailAddress']}
                    hasFeedback
                    validateStatus={eMailValidateStatus}
                    help={eMailErrorMessage}
                    rules={[{ required: true, message: "Email Address is required" }]}
                  >
                    <Input
                      type='email'
                      onPressEnter={handleLesseeEmailChange}
                      onBlur={handleLesseeEmailChange}
                      disabled={disableFlag}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Will they be riding the bike?</label>}
                    name={['colesseeAttributes', 'isDriving']}
                    style={{ color: "red" }}
                    hasFeedback
                    rules={[{ required: true, message: 'Will they be riding the bike is required!' }]}
                  >
                    <Radio.Group disabled={disableFlag}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Driver License?</label>}
                    name={['colesseeAttributes', 'driverLicense']}
                    style={{ color: "red" }}
                    hasFeedback
                    rules={[{ required: true, message: 'Driver License is required!' }]}
                  >
                    <Radio.Group disabled={disableFlag} onChange={(e) => setMotorcycleLicense(e.target.value)}>
                      <Radio value={"Yes"}>Yes</Radio>
                      <Radio value={"No"}>No</Radio>
                      <Radio value={"Unknown"}>Unknown</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {
                motorcycleLicense === "Yes" &&
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={<label style={{ color: "red" }}>Drivers License State</label>}
                      name={[`colesseeAttributes`, 'licenseState']}
                      rules={[{ required: true, message: "Driver's License State is required" }]}>
                      <Select showSearch placeholder="License State" className="space-up"
                              onChange={(value) =>  setLicenseFormat(stateLicenseFormat(value))}
                              disabled={disableFlag}
                      >
                        {usStates &&
                          usStates.map(({ name, abbreviation }, index) => {
                            return (
                              <Option key={index} value={`${name}`}>
                                {name}
                              </Option>
                            )
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              }
              {
                motorcycleLicense === "Yes" &&
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label={<label style={{ color: "red" }}>Drivers License Number</label>}
                      name={['colesseeAttributes', 'driversLicenseIdNumber']}
                      rules={[{ required: true, message: "Driver's License Number is required" },
                        {
                          pattern: licenseFormat,
                          message: "Must be a valid License Number"
                        }]}
                    >
                      <Input placeholder="License Number" disabled={disableFlag}/>
                    </Form.Item>
                  </Col>
                </Row>
              }
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Motorcycle Endorsement?</label>}
                    name={['colesseeAttributes', 'motorcycleEndorsement']}
                    style={{ color: "red" }}
                    hasFeedback
                    rules={[{ required: true, message: 'Motorcycle Endorsement is required!' }]}
                  >
                    <Radio.Group disabled={disableFlag}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={disableFlag}>
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title="Home Address">
            <Form
              form={homeAddressForm}
              {...layout}
              colon={false}
              initialValues={{
                colesseeAttributes: {
                  id: lessee?.id,
                  atAddressYears: lessee?.atAddressYears,
                  atAddressMonths: lessee?.atAddressMonths,
                  monthlyMortgage: lessee?.monthlyMortgage,
                  homeOwnership: lessee?.homeOwnership,
                  homeAddressAttributes: {
                    id: (lessee?.homeAddress?.id),
                    street1: (lessee?.homeAddress?.street1),
                    street2: (lessee?.homeAddress?.street2),
                    zipcode: (lessee?.homeAddress?.zipcode),
                    county: (lessee?.homeAddress?.county),
                    state: (lessee?.homeAddress?.stateId),
                    cityId: (lessee?.homeAddress?.cityId)
                  }
                }
              }}
              onFinish={handleSubmit}
            >
              {
                lessee && <Form.Item style={{ display: 'none' }} name={['colesseeAttributes', 'id']} > <Input /> </Form.Item>
              }
              {
                lessee && (lessee.homeAddress && <Form.Item style={{ display: 'none' }} name={['colesseeAttributes', 'homeAddressAttributes', 'id']} > <Input /> </Form.Item>)
              }
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Street Address (no P.O. Boxes)</label>}
                    name={['colesseeAttributes', 'homeAddressAttributes', 'street1']}
                    hasFeedback
                    rules={[{ required: true, message: 'Street Address (no P.O. Boxes) is required!' }]}
                  >
                    <Input disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Apartment / Unit" name={['colesseeAttributes', 'homeAddressAttributes', 'street2']}>
                    <Input disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>ZIP Code</label>}
                    name={['colesseeAttributes', 'homeAddressAttributes', 'zipcode']}
                    hasFeedback
                    rules={[{ required: true, message: 'ZIP Code is required!' }]}
                    validateStatus={zipHomeValidateStatus}
                    help={zipHomeErrorMessage}
                  >
                    <MaskedInput mask="11111" onPressEnter={handleLesseeHomeZipcodeBlur} onBlur={handleLesseeHomeZipcodeBlur} disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>State</label>}
                    name={['colesseeAttributes', 'homeAddressAttributes', 'state']}
                    hasFeedback
                    rules={[{ required: true, message: 'State is required!' }]}
                  >
                    <Select disabled={disableFlag}>
                      {
                        lesseeHomeStateOptions && lesseeHomeStateOptions.map(({ value, label }, index) => {
                          return <Option key={index} value={`${value}`}>{label}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>County/Parish</label>}
                    name={['colesseeAttributes', 'homeAddressAttributes', 'county']}
                    hasFeedback
                    rules={[{ required: true, message: 'County/Parish is required!' }]}
                  >
                    <Select
                      onSelect={handleHomeCountyStateChange}
                      disabled={disableFlag}
                    >
                      {
                        lesseeHomeCountyOptions && lesseeHomeCountyOptions.map(({ value, label }, index) => {
                          return <Option key={index} value={`${value}`}>{label}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>City</label>}
                    name={['colesseeAttributes', 'homeAddressAttributes', 'cityId']}
                    hasFeedback
                    rules={[{ required: true, message: 'City is required!' }]}
                  >
                    <Select disabled={disableFlag}>
                      {
                        lesseeHomeCityOptions && lesseeHomeCityOptions.map(({ value, label }, index) => {
                          return <Option key={index} value={`${value}`}>{label}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Years at Current Address</label>}
                    name={['colesseeAttributes', 'atAddressYears']}
                    hasFeedback
                    rules={[{ required: true, message: 'Years at Current Address is required!' }]}
                  >
                    <InputNumber disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Months at Current Address" name={['colesseeAttributes', 'atAddressMonths']}>
                    <InputNumber disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<label style={{ color: "red" }}>Monthly Mortgage or Rent</label>}
                    name={['colesseeAttributes', 'monthlyMortgage']}
                    hasFeedback
                    rules={[{ required: true, message: 'Years at Current Address is required!' }]}
                  >
                    <InputNumber disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name={['colesseeAttributes', 'homeOwnership']}
                    hasFeedback
                    rules={[{ required: true, message: 'Home Owenership is required!' }]}
                  >
                    <Radio.Group>
                      <Radio value={0} disabled={disableFlag}>Own</Radio>
                      <Radio value={1} disabled={disableFlag}>Rent</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={disableFlag}>
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>


        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title="Mailing Address">
            <Form
              form={mailingAddressForm}
              {...layout}
              colon={false}
              initialValues={{
                colesseeAttributes: {
                  id: lessee?.id,
                  mailingAddressAttributes: {
                    id: lessee?.mailingAddress?.id,
                    street1: lessee?.mailingAddress?.street1,
                    street2: lessee?.mailingAddress?.street2,
                    zipcode: lessee?.mailingAddress?.zipcode,
                    county: lessee?.mailingAddress?.county,
                    state: lessee?.mailingAddress?.stateId,
                    cityId: lessee?.mailingAddress?.cityId
                  }
                }
              }}
              onFinish={handleSubmit}
            >
              {
                lessee && <Form.Item style={{ display: 'none' }} name={['colesseeAttributes', 'id']} > <Input /> </Form.Item>
              }
              {
                lessee && lessee.mailingAddress && <Form.Item style={{ display: 'none' }} name={['colesseeAttributes', 'mailingAddressAttributes', 'id']} > <Input /> </Form.Item>
              }
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Street Address"
                    name={['colesseeAttributes', 'mailingAddressAttributes', 'street1']}
                    hasFeedback
                  >
                    <Input disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Apartment / Unit" name={['colesseeAttributes', 'mailingAddressAttributes', 'street2']}>
                    <Input disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="ZIP Code"
                    name={['colesseeAttributes', 'mailingAddressAttributes', 'zipcode']}
                    hasFeedback
                    validateStatus={zipMailValidateStatus}
                    help={zipMailErrorMessage}
                  >
                    <MaskedInput mask="11111" onPressEnter={handleLesseeMailZipcodeBlur} onBlur={handleLesseeMailZipcodeBlur} disabled={disableFlag} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="State"
                    name={['colesseeAttributes', 'mailingAddressAttributes', 'state']}
                    hasFeedback
                  // rules={[{ required: true, message: 'State is required!' }]}
                  >
                    <Select disabled={disableFlag}>
                      {
                        lesseeMailStateOptions && lesseeMailStateOptions.map(({ value, label }, index) => {
                          return <Option key={index} value={`${value}`}>{label}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="County/Parish"
                    name={['colesseeAttributes', 'mailingAddressAttributes', 'county']}
                    hasFeedback
                  >
                    <Select
                      onSelect={handleMailingCountyStateChange}
                      disabled={disableFlag}
                    >
                      {
                        lesseeMailCountyOptions && lesseeMailCountyOptions.map(({ value, label }, index) => {
                          return <Option key={index} value={`${value}`}>{label}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="City"
                    name={['colesseeAttributes', 'mailingAddressAttributes', 'cityId']}
                    hasFeedback
                  >
                    <Select disabled={disableFlag}>
                      {
                        lesseeMailCityOptions && lesseeMailCityOptions.map(({ value, label }, index) => {
                          return <Option key={index} value={`${value}`}>{label}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={disableFlag}>
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

      </Row>
      :
      'N/A'
  ) : null;
};

import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useHistory } from 'react-router-dom';

export default function LockUnlock(props) {
    const history = useHistory();
    const { applicationLockedBy, loggedInUserId, handleClickOpen, slcRow, lockApplication, handleTransferOwnershipClick, setAnchorEl } = props;
    let appStatus = applicationLockedBy.status
    if (appStatus && applicationLockedBy.lockedById !== loggedInUserId) {
        return <>
            <MenuItem onClick={() => {
                setAnchorEl(null)
                handleClickOpen(slcRow)
            }
            } disableRipple >
                <LockOpenIcon sx={{ marginRight: 1 }} />
                Unlock {applicationLockedBy.lockedByUser}
            </MenuItem>
        </>
    }
    else if (appStatus && applicationLockedBy.lockedById === loggedInUserId) {
        return <> <MenuItem onClick={() => {
            history.push('/lease-applications/' + slcRow.id);
        }
        } disableRipple >
            <PlayArrowIcon sx={{ marginRight: 1 }} />
            Continue Work
        </MenuItem>
            <MenuItem onClick={() => {
                setAnchorEl(null)
                handleClickOpen(slcRow)
            }
            } disableRipple >
                <LockOpenIcon sx={{ marginRight: 1 }} />
                Unlock {applicationLockedBy.lockedByUser}
            </MenuItem>
            <MenuItem onClick={(e) => {
                setAnchorEl(null)
                handleTransferOwnershipClick();
            }
            } disableRipple >
                <LockOpenIcon sx={{ marginRight: 1 }} />
                Transfer Ownership
            </MenuItem>
        </>
    }
    else {
        return <> <MenuItem onClick={() => {
            lockApplication(slcRow)
        }
        } disableRipple >
            <PlayArrowIcon sx={{ marginRight: 1 }} />
            Start Work
        </MenuItem>
        </>
    }
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { PoweroffOutlined, ImportOutlined } from '@ant-design/icons';
import './styles/CalculatorHead.css';
import Logo from './Logo';

const { Header } = Layout

const CalculatorHead = (props) => {
  const { calculateWithoutApp } = props
  return (
    <Header>
    <div className='wrapper'>
      <Logo/>

      <div className="return-to-home icon-wrapper">
          {/* <Link className="steps-exit-btn" to ={``} style={{ color: '#e93b1b' }}><ImportOutlined />Back To Home</Link> */}
      </div>
      {
          calculateWithoutApp === false && <div className="icon-wrapper">
            <Link to={`/lease-applications-list`} className="steps-exit-btn" style={{float:"right", color: '#e93b1b'}}>
              <PoweroffOutlined />
              EXIT
            </Link>
          </div>
      }
    </div>
  </Header>
  )
}
export default CalculatorHead
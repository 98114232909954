import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { PUT } from '../../../util/network';
import logger from '../../../util/logger';
import { Form, Radio, message } from "antd";
import { useDispatch } from "react-redux";

export default function LeaseStipulationListStatus({ handleNoAuth, val, row, disabled }) {
    const history = useHistory();
    // console.log(row);
    const appNumber = row.applicationIdentifier;
    const [status, setStatus] = useState(val)
    const disableFlag = disabled;

    const onChangeStatus = (e) => {
      setStatus(e.target.value)
      let newData = {
        leaseApplicationStipulationsAttributes : {
          id: row.id,
          status: e.target.value
        }
      }
      saveApplicationDetails(newData)
    }

    const saveApplicationDetails = async (newData, form) => {
      try {
        await PUT(`/api/v1/lease_applications/details?application_identifier=${appNumber}`, newData);
        message.success("Status saved successfully");
        form && form.resetFields();
      } catch (e) {
        logger.error("Application Details Error", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data');
        }
      }
    };

    return (
      <Form.Item key={`status-${row.id}`} style={{marginBottom: 0}} >
        <Radio.Group value={status} onChange={onChangeStatus}>
          <Radio value="Required" disabled={disableFlag}>Required</Radio>
          <Radio value="Not Required" disabled={disableFlag}>Not Required</Radio>
          <Radio value="Cleared" disabled={disableFlag}>Cleared</Radio>
        </Radio.Group>
      </Form.Item>

    );
}
import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Form,
  Layout,
  Menu,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import {GET, PUT} from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import {SiderProps} from "../../components/layouts/MainSiderProps";
import {GetResourceActions, IsActionAllowed} from "../../util/AllowedActions";
import {AddNew} from "../../components/layouts/Svg";
import Icon from "@ant-design/icons";

const {Content, Sider} = Layout;
const {Option} = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function UpdateApplicationStipulation(props) {
  const {id} = props.match.params;
  const {allowedActions, handleNoAuth} = props;
  const history = useHistory();
  const adminUserActions = GetResourceActions("AdminUser", allowedActions);
  const allowedCreate = IsActionAllowed("create", adminUserActions);
  const [modelGroupForm] = Form.useForm();
  const [data, setData] = useState(null);
  const [stipulationOptions, setStipulationOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStipulations = async () => {
    setLoading(true)
    try {
      const response = await GET(`/api/v1/lease-application-stipulations/${id}`);
      setData(response?.data.data);
    } catch (e) {
      message.error("Error while loading application welcome call.")
    }
    setLoading(false)
  }

  const getStipulationOptions = async () => {
    {
      setLoading(true);
      try {
        await GET(`/api/v1/stipulations`)
          .then((response) => {
            setStipulationOptions(response.data.stipulations || []);
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              handleNoAuth(history);
            } else {
              message.error("Error while fetching stipulation Options");
            }
          });
      } catch (e) {
        logger.error("Error while fetching stipulation Options", e);
      }

      setLoading(false);
    }
  };

  const save = async (values) => {
    {
      setLoading(true);
      try {
        await PUT(`/api/v1/lease-application-stipulations/${id}`, values)
          .then((response) => {
            message.success("Saved successfully");
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              message.error("Error while saving!");
              handleNoAuth(history);
            } else {
              message.error("Error while saving!");
            }
          });
      } catch (e) {
        logger.error("Error while saving!", e);
      }

      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    values = {...values};
    let res = {
      stipulation: values
    }
    save(res);
  };

  const status = [
    {
      index: 1,
      value: "Required",
    },
    {
      index: 2,
      value: "Not Required",
    },
    {
      index: 3,
      value: "Cleared",
    },
  ];

  useEffect(() => {
    getStipulations();
    getStipulationOptions();
  }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      {data &&
        <Layout>
          <MainHeader activePage="leaseApplicationRelated"/>
          <Layout>
            <Sider {...SiderProps}>
              <Menu
                defaultSelectedKeys={["1"]}
                selectedKeys={["1"]}
                style={{width: "100%"}}
              >
                <Menu.Item key="1">
                  <Icon component={AddNew}/>
                  Edit Application Stipulation
                </Menu.Item>
              </Menu>
            </Sider>

            <Layout id="content-area-layout">
              <MainBreadcrumb
                items={[
                  {text: "Home", link_type: "linkto", link: "/dashboard"},
                  {text: "Lease Application Related", link_type: "ahref", link: void 0},
                  {
                    text: "Application Stipulations",
                    link_type: "ahref",
                    link: "/lease-applications-stipulation",
                  },
                  {text: "Edit", link_type: "ahref", link: void 0},
                ]}
              />

              <Content id="main-content">
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <>
                      <Form
                        form={modelGroupForm}
                        {...layout}
                        colon={false}
                        onFinish={handleSubmit}
                        initialValues={{
                          stipulation_id: data.stipulationId,
                          status: data.status,
                          notes: data.notes
                        }}
                      >
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <Card title="Lease Application Stipulation edit">
                              <Row gutter={[24, 16]}>
                                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                  <Form.Item
                                    label="Stipulation"
                                    name="stipulation_id"
                                    hasFeedback
                                    messageVariables={{name: "Reason"}}
                                    rules={[{required: true}]}
                                  >
                                    <Select>
                                      {stipulationOptions &&
                                        stipulationOptions.map(({id, description}, index) => {
                                          return (
                                            <Option key={index} value={id}>
                                              {description}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>

                                  <Form.Item
                                    label="Status"
                                    name="status"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Status",
                                    }}
                                    rules={[{required: true}]}
                                  >
                                    <Select>
                                      {status &&
                                        status.map(({value}, index) => {
                                          return (
                                            <Option key={index} value={value}>
                                              {value}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>

                                  <Form.Item
                                    label="Notes"
                                    name="notes"
                                    hasFeedback
                                    messageVariables={{
                                      name: "notes",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>

                        <Row gutter={[16, 16]} className="mt-20">
                          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                            <Row>
                              <Col span={24}>
                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    Update
                                  </Button>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
          <MainFooter/>
        </Layout>
      }
    </Spin>
  );
}

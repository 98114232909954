import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Table, Row, Col, Button, Typography, message } from 'antd';
import { POST } from '../../../util/network';
import logger from '../../../util/logger';
import CustomInputText from '../components/CustomInputText';
import '../components/ApprovalEvents.css'
import moment from 'moment';
import { fetchDealershipNotes, AddDealershipNotes, setDealershipNotes, deleteDealershipNotes , removeDealershipNotes } from './../../../reducers/DealershipNotes';
import { useSelector, useDispatch } from "react-redux";

const { Text } = Typography;
export default function DealershipNotes(props) {

    const { type, handleNoAuth, dealershipId } = props
    const history = useHistory();
    const dispatch = useDispatch();
    const { dealershipNotes } = useSelector((state) => state.DealershipNotes);
    const formatDate = (dateStr) => {
        return dateStr ? moment(dateStr).format("MMMM DD, YYYY hh:mm:ss") : null
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Admin Name',
            dataIndex: 'first_name',
            key: 'first_name',
            render: (text, record, index) =>
                <span >
                    <p> {record.first_name ? record.first_name : "" + " " + record.last_name ? record.last_name : ""}</p>
                </span>
        },

        {
            title: 'Date and Time',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record, index) =>
                <span >
                    <p> {formatDate(record.created_at)}</p>
                </span>

        },
        {
            title: 'Action',
            dataIndex: 'dealership_id',
            key: 'dealership_id',
            render: (text, record, index) =>
                <span >
                    <Button type="danger" onClick={() => deleteNote(record.id)} >Delete</Button>
                </span>
        }
    ];
    const getNotes = () => {
        const ele = document.getElementById(`${type}-notes`);
        if (ele && ele.value) {
            return ele.value;
        }
        return null;
    }
    const AddNotes = async () => {
        const notes = getNotes();
        const errMessage = `Error while saving ${type}`;
        try {
           let response = await dispatch(AddDealershipNotes({ type: type, notes: notes, dealershipId: dealershipId }));
            if (response) {
                dispatch(setDealershipNotes({ type: type, dealershipNotes: response.payload.data.data }));
                message.success(` ${type} Notes Added Successfully`);
            }
        } catch (e) {
            logger.error(errMessage, e);
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error(errMessage);
            }
        }
    }

    const deleteNote = async (id) => {
        const errMessage = `Error while saving ${type}`;
        try {
            let response = await dispatch(deleteDealershipNotes({id: id}));
            if (response) {
                dispatch(removeDealershipNotes({ type: type, id: response.payload.data.id }));
                message.success(` ${type} Notes Deleted Successfully`);
            }
        } catch (e) {
            logger.error(errMessage, e);
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error(errMessage);
            }
        }
    }

    let dataSource = ""
    if (type == "Sales") {
        dataSource = dealershipNotes.salesNotes
    }

    if (type == "Underwriting") {
        dataSource = dealershipNotes.underWrittingNotes
    }

    useEffect(() => {
        dispatch(fetchDealershipNotes({ dealershipId: dealershipId }));
    }, []);

    return (
        <>
            <Row className='approval-controls' gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={20} xl={21}>
                    <CustomInputText id={`${type}-notes`} name={`${type}-notes`} label={`${type} Notes`} required={true} />
                </Col>
                <Col className='button-col' xs={24} sm={24} md={6} lg={4} xl={3}>
                    <Button type="primary" onClick={AddNotes}>Add</Button>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Table
                        className='slc-table'
                        rowKey="id"
                        dataSource={dataSource}
                        columns={columns}
                        pagination={true}
                        size="small"
                    />
                </Col>
            </Row>
        </>
    )
}

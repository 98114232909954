import React, {useState} from "react";
import {
  TextField,
  Button,
  Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Grid,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import {Paper} from "@mui/material";
import DialogModal from "../../LeaseApplications/DialogModal";
import Textarea from "@mui/joy/Textarea";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "0 auto",
    background: "white",
    color: "black",
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    marginBottom: "2px",
  },
  input: {
    width: "100%",
    marginBottom: 20,
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& .MuiInput-underline.Mui-error:before': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& .MuiAutocomplete-input': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: 'rgb(0 0 0 / 42%)'
    }
  },
  reminderList: {
    backgroundColor: "white",
    borderRadius: 4,
    marginTop: 20,
    maxHeight: 200,
    overflow: "auto",
  },
  button: {
    marginTop: "5px",
    background: "#ff3500",
    color: "#fff"
  },
}));

const SetReminder = (props) => {
  const {reminders, setReminder, deleteReminder} = props;
  const classes = useStyles();
  const [reminderId, setReminderId] = useState();
  const [open, setOpen] = useState(false);
  const [isReminderInvalid, setIsReminderInvalid] = useState(false);
  const initialReminderInfo = {note: "", reminderTime: ""};
  const [reminderInfo, setReminderInfo] = useState({...initialReminderInfo});

  const handleReminderSubmit = (e) => {
    e.preventDefault();
    setReminder(reminderInfo.note, reminderInfo.reminderTime);
    setReminderInfo({...initialReminderInfo});
  };

  const handleDeleteReminder = (id) => {
    setReminderId(id);
    setOpen(true);
  }

  const handleDateTime = (event, attribute) => {
    let value = event.target.value
    let dt = new Date(value);
    if (!isNaN(dt)) {
      if (dt > Date.now()) {
        setIsReminderInvalid(false);
      } else {
        setIsReminderInvalid(true);
      }

      setReminderInfo({
        ...reminderInfo,
        [attribute]: value,
      });
    } else {
      if (value !== '') {
        setIsReminderInvalid(true);
      } else {
        setIsReminderInvalid(false);
      }

      setReminderInfo({
        ...reminderInfo,
        [attribute]: '',
      });
    }
  };

  const closeDialog = () => {
    setOpen(false);
  }

  const completeDeletion = () => {
    deleteReminder(reminderId)
    closeDialog();
  }

  return (
    <div>
      <form onSubmit={handleReminderSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Grid xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TextField
                  id="Reminder-local"
                  label="Reminder Time"
                  type="datetime-local"
                  value={reminderInfo.reminderTime}
                  onChange={(e) => handleDateTime(e, "reminderTime")}
                  className={classes.input}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid md={6}>
            </Grid>
            {isReminderInvalid &&
              <Typography style={{color: "#FF0000"}} variant="caption">* Please provide a valid
                future
                timestamp</Typography>}
          </Grid>
          <Grid item xs={12}>
            <Textarea
              name="notes"
              placeholder="Reminder notes..."
              minRows={2}
              value={reminderInfo.note}
              onChange={(e) => setReminderInfo({...reminderInfo, note: e.target.value})}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={!reminderInfo.reminderTime || !reminderInfo.note || isReminderInvalid}
            >
              Set Reminder
            </Button>
          </Grid>
        </Grid>
      </form>
      {reminders.length > 0 && (
        <div className={classes.reminderList}>
          <Typography variant="h6" style={{marginTop: 10}} gutterBottom>
            <b>Reminder List:</b>
          </Typography>
          <Paper style={{maxHeight: 150, overflow: 'auto'}}>
            <List>
              {reminders.map((reminder) => (
                <ListItem className={classes.reminder} key={reminder?.id}>
                  <Grid md={6}>
                    <ListItemText primary={reminder?.notes}/>
                  </Grid>
                  <Grid md={4}>
                    <ListItemText primary={reminder?.followUpCall}/>
                  </Grid>
                  <Grid md={2}>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        style={{color: 'red'}}
                        onClick={() => handleDeleteReminder(reminder?.id)}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Paper>
          <DialogModal
            modalState={open}
            modalClose={closeDialog}
            submitFunction={completeDeletion}
            contentData={""}
            title={`Are you sure you want to delete this remainder?`}
          />
        </div>
      )}
    </div>
  );
};

export default SetReminder;

import React, { useState } from "react";
import {
    Form,
    Select,
    Col,
    Row,
    Button,
} from "antd";

import _ from 'lodash';

const Option = Select.Option;

export default function Credit({ data, disableFlag, creditStatusOptions, declineReasonTypeOptions, save }) {
    const [form] = Form.useForm();

    const [creditStatus,] = useState(data.creditStatusValue);

    const declineReasons = useState(data.declineReasons.map(declineReason => declineReason.description))
    const [requiredeclineReasons, setRequiredeclineReasons] = useState(false)
    // declineReasons possible data types => undefined, [], [<data>]
    const declineReasonPresence = (creditStatus, declineReasons) => {
        if (creditStatus.toLowerCase() === "declined") {
            if (declineReasons === undefined) {
                return false
            } else {
                return declineReasons.length > 0
            }
        } else {
            return false
        }
    }

    const [displayDeclineReasons, setDisplayDeclineReasons] = useState(declineReasonPresence(data.creditStatus, data.declineReasons))

    const handleSubmit = async (value) => {
        if (value.creditStatus.toLowerCase() == "declined") {
            save({
                creditStatus: value.creditStatus,
                decline_reasons_attributes:
                    getDeclineReasons(_.uniq(value.declineReasons)).concat(destroyExistingDeclineReasons(data.declineReasons))
            })
        } else {
            form.setFieldsValue({ declineReasons: [] })
            save({ creditStatus: value.creditStatus })
        }
    }

    const getDeclineReasons = (declineReasons) => {
        return declineReasons.map(item => {
            return { description: item }
        })
    }

    const destroyExistingDeclineReasons = (declineReasons) => {
        return declineReasons.map(item => {
            return { id: item.id, description: item, _destroy: true }
        })
    }

    const handleUnhide = (value) => {
        if (value === 'declined') {
            setDisplayDeclineReasons(true)
            setRequiredeclineReasons(true)
        } else {
            form.setFieldsValue({ declineReasons: [] })
            setDisplayDeclineReasons(false)
            setRequiredeclineReasons(false)
        }
    }

    const handleOnChangeDeclineReasons = (value) => {
       if(value.length > 0){
           setRequiredeclineReasons(false)
       }
       else{
           setRequiredeclineReasons(true)
       }
    }

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    return !data ? null : (
        <Form
            {...layout}
            form={form}
            initialValues={{
                creditStatus: creditStatus,
                // declineReasons: declineReasons
            }}
            onFinish={handleSubmit}
        >
            <Row gutter={[32, 0]}>
                <Col span={24}>
                    <Form.Item label="Credit Status" name="creditStatus">
                        <Select style={{ width: "100%" }} disabled={disableFlag} onChange={(value) => {
                            handleUnhide(value)
                        }}
                        >
                            {
                                (creditStatusOptions || []).map((obj, key) => {
                                    return <Option key={key} value={obj.optionValue} disabled={obj.disabled}>{obj.optionName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Decline Reason"
                        name="declineReasons"
                        hidden={!displayDeclineReasons}
                        rules={[{ required: requiredeclineReasons, message: 'Decline Reason is required!' }]}>
                        <Select mode="multiple"
                            defaultValue={declineReasons[0]}
                            disabled={disableFlag}
                            onChange={(value) => { handleOnChangeDeclineReasons(value) }}
                        >
                            {
                                (declineReasonTypeOptions || []).map((obj, key) => {
                                    return <Option key={obj.optionValue} value={obj.optionName} disabled={obj.disabled}>{obj.optionName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button type="primary" htmlType="submit" disabled={disableFlag} >
                        Save
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST } from '../util/network';

export const fetchDealershipNotes = createAsyncThunk('dashboard/fetchDealershipNotes', async (params) => {
    try {
        const response = await GET(`/api/v1/dealerships/dealership-notes?dealership_id=${params.dealershipId}`);
        return response.data.dealershipNotes;
    } catch (e) {
        console.log(e)
    }
})

export const AddDealershipNotes = createAsyncThunk('dashboard/AddDealershipNotes', async (params) => {
    try {
        const response = await POST(`/api/v1/dealerships/create-dealership-notes`, {
            description: params.notes,
            types: params.type,
            status: true,
            dealership_id: params.dealershipId
        });
        return response;
    } catch (e) {
        console.log(e)
    }
})

export const deleteDealershipNotes = createAsyncThunk('dashboard/AddDealershipNotes', async (params) => {
    try {
        let response = await POST(`/api/v1/dealerships/inactive-dealership-notes`, {
            id: params.id
        });
        return response;
    } catch (e) {
        console.log(e)
    }
})


const initialState = {
    dealershipNotes: {}
}

export const DealershipNotesSlice = createSlice({
    name: "DealershipNotes",
    initialState: initialState,
    reducers: {
        setDealershipNotes: (state, action) => {
            let userdata = JSON.parse(window.localStorage.getItem('user_data'))
            if (action.payload.type == "Sales") {
                action.payload.dealershipNotes["first_name"] = userdata.first_name;
                action.payload.dealershipNotes["last_name"] = userdata.last_name;
                state.dealershipNotes.salesNotes.push(action.payload.dealershipNotes);
            }
            if (action.payload.type == "Underwriting") {
                action.payload.dealershipNotes["first_name"] = userdata.first_name;
                action.payload.dealershipNotes["last_name"] = userdata.last_name;
                state.dealershipNotes.underWrittingNotes.push(action.payload.dealershipNotes);
            }
        },
        removeDealershipNotes: (state, action) => {
            if (action.payload.type == "Sales") {
                return {
                    ...state,
                    dealershipNotes: {
                        ...state.dealershipNotes,
                        salesNotes: [
                            ...state.dealershipNotes.salesNotes.filter(item =>
                                item.id !== action.payload.id
                            )
                        ]
                    }
                };
            }
            if (action.payload.type == "Underwriting") {
                return {
                    ...state,
                    dealershipNotes: {
                        ...state.dealershipNotes,
                        underWrittingNotes: [
                            ...state.dealershipNotes.underWrittingNotes.filter(item =>
                                item.id !== action.payload.id
                            )
                        ]
                    }
                };
            }

        },
    },
    extraReducers(builder) {
        builder.addCase(fetchDealershipNotes.fulfilled, (state, action) => {
            state.dealershipNotes = action.payload
            state.isLoading = false;
            return state
        }),
            builder.addCase(fetchDealershipNotes.pending, (state, action) => {
                state.isLoading = true;
            }),
            builder.addCase(fetchDealershipNotes.rejected, (state, action) => {
                state.isLoading = false;
                state.success = false;
                state.error = action.payload;
            });
    }
});
export const { setDealershipNotes, removeDealershipNotes } = DealershipNotesSlice.actions;
export default DealershipNotesSlice.reducer;

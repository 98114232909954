import React, { useState } from 'react'
import { Row, Col, Card, Typography, Divider, Form, Select, Radio, Button } from "antd";
import CalculationTable from './credit_components/CalculationTable'
import CalculationTableForm from './credit_components/CalculationTableForm'
let auth_token = window.localStorage.getItem('auth_token');
const { Text, Title } = Typography;
const { Option } = Select;
const CardDescription = ({ label, children }) => {
    return <Row style={{marginBottom: 10}}><Col span={24}> <Text style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 700, fontSize: "1.1em" }}> {label} </Text> </Col> <Col span={24}> {children} </Col></Row> 
}

export default function CreditSummary({ data, save, setLoading }) {
    const lesseeCredit = data.lesseeCreditSummary
    const colesseeCredit = data.colesseeCreditSummary
    const creditAverageSummary = data.creditAverageSummary
    const creditTierOptions = creditAverageSummary.creditTiers
    let status = data.creditStatus
    let creditTier = status.toLowerCase() == "declined" ? "Assigned Credit Tier" : "Approved Credit Tier"
    const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    if(authData !== null) {
        loggedInUserId = authData.id;
    }
    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = locked_by != loggedInUserId ? true : false
    const [creditTierId, setCreditTierId] = useState(null)
    const [form] = Form.useForm()

    const lesseeOrColessee = (title) => {
        if(title === 'Lessee'){
            return 'lesseeAttributes'
        }
        else{
            return 'colesseeAttributes'
        }
    }

    const updateValue = async (value, title) => {
        if(title === 'Lessee'){
            save({ lesseeAttributes: { thinFile: value} })
        }
        else{
            save({ colesseeAttributes: { thinFile: value} })
        }
    }

    const LesseeCard = ({lessee, title}) => {
        const initialValues = title === 'Lessee' ? {
            lesseeAttributes: {
                thinFile: lessee?.thinFile,
            }
        } : {
              colesseeAttributes: {
                  thinFile: lessee?.thinFile,
              }
          }

        return (
          <Card className='h-100' title={title}>
              {
                  lessee?.name
                    ?
                    <>
                        <CardDescription label="Name"> {lessee.name || "\u00A0"} </CardDescription>
                        <CardDescription label="Equifax"> {lessee.creditScoreEquifax || "\u00A0"} </CardDescription>
                        <CardDescription label="Experian"> {lessee.creditScoreExperian || "\u00A0"}  </CardDescription>
                        <CardDescription label="TransUnion"> {lessee.creditScoreTransunion || "\u00A0"}  </CardDescription>
                        <CardDescription label="Average Credit Score"> {lessee.creditScoreAverage || "\u00A0"}  </CardDescription>
                        <CardDescription label="Recommended Credit Tier"> {lessee.recommendedCreditTier || "\u00A0"}  </CardDescription>
                        <Divider />
                        <CardDescription label="Blackbox"></CardDescription>
                        <CardDescription label="Decision"> {lessee.blackboxDecision || "\u00A0"}  </CardDescription>
                        <CardDescription label="Blackbox Credit Score"> {lessee.leadrouterCreditScore || "\u00A0"}  </CardDescription>
                        <CardDescription label="Recommended Blackbox Tier"> {lessee.recommendedBlackboxTier || "\u00A0"}  </CardDescription>
                        <CardDescription label="Is this a Thin file?">
                            <Form
                              form={form}
                              initialValues={initialValues}>
                                <Row className='flex-jc-fs'>
                                    <Form.Item
                                      name={[lesseeOrColessee(title), 'thinFile']}>
                                        <Radio.Group onChange={(e) => updateValue(e.target.value, title)} disabled={disableFlag}>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </CardDescription>
                    </>
                    :
                    'N/A'
              }
          </Card>
        )
    }

    const updateCreditTier = async () => {
      save({ leaseCalculatorAttributes: { creditTierId: creditTierId} })
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <LesseeCard lessee={lesseeCredit} title="Lessee" />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <LesseeCard lessee={colesseeCredit} title="Co-Lessee" />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <Card className='h-100' title="Application" >
                        <Title level={4}>Income and Payment</Title>
                        <CalculationTable data={data}/>
                        { creditAverageSummary.paymentLimitId &&
                            <>
                                <br />
                                <CalculationTableForm data={data}  setLoading={setLoading} />
                            </>
                        }
                        <Divider />
                        <Title level={4}>Credit</Title>
                        <CardDescription label="Decision Analyst">
                            {data?.decisionAnalyst ? data?.decisionAnalyst : 'N/A'}
                        </CardDescription>
                        <CardDescription label="Average Credit Score (all)"> {creditAverageSummary.averageCreditScore || "\u00A0"} </CardDescription> 
                        <CardDescription label="Average Blackbox Credit Score (all)"> {creditAverageSummary.blackboxAverageCreditScore || "\u00A0"} </CardDescription>
                        <CardDescription label="Recommended Credit Tier"> {creditAverageSummary.recommendedCreditTier || "\u00A0"} </CardDescription> 
                        <CardDescription label="Recommended Blackbox Tier"> {creditAverageSummary.recommendedBlackboxTier || "\u00A0"} </CardDescription> 
                        <CardDescription label={creditTier}>
                            {
                              <Form
                                form={form}
                                onFinish={updateCreditTier}
                                initialValues={{
                                    leaseCalculatorAttributes: {
                                        creditTierId: creditAverageSummary.approvedCreditTier,
                                    }
                                }}>
                                  <Form.Item
                                    name={['leaseCalculatorAttributes', 'creditTierId']}
                                    rules={[{ required: true, message: 'Credit Tier is required!' }]}
                                  >
                                      <Select showSearch disabled={disableFlag} onChange={(e) => setCreditTierId(e)}>
                                          {
                                            creditTierOptions && creditTierOptions.map((obj, index) => {
                                                return <Option key={index} value={obj.id}>{obj.label}</Option>
                                            })
                                          }
                                      </Select>
                                  </Form.Item>
                                  <Form.Item>
                                      <Button type="primary" htmlType="submit" style={{marginRight: '1em'}} disabled={disableFlag}>
                                          Save
                                      </Button>
                                  </Form.Item>
                              </Form>
                            }
                        </CardDescription>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
import React, { useState } from 'react';
import { Form, Input, InputNumber, Select, Button, Row, Col, message } from 'antd';
import { useDispatch } from "react-redux";
import {insertIncomeVerification, insertLesseeIncomeVerification } from '../../../reducers/LeaseApplications'
import {getDetails} from '../../../reducers/LeaseApplications'


const { Option } = Select;

export default function IncomeVerificationForm({ data, lessee, getApplicationDetails, layout, ...props }) {
  const { allowedUpdate } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const incomeVerificationTypeOptions = data.incomeVerificationTypeOptions || [];
  const incomeFrequencyOptions = data.incomeFrequencyOptions || [];
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false

  const submit =  async (params) => {
    const response = await insertIncomeVerification({ leaseApplicationId: data.id, lesseeId: lessee.id, incomeVerification: params});
    dispatch(getDetails(response.data.leaseApplication));
    }

  // Makes sure that whole number, with tenths and hundredths are submitted properly
  const formatGrossIncomeCents = (value) => {
    // If value have decimal
    if (value > Math.floor(value)) {
      // Ex. 349.99 -> 34999
      return Math.floor(value) + (value % 1).toFixed(2).substr(-2)
    } else {
      // Ex. 349 -> 34900
      return Math.floor(value) + "00"
    }
  }

  const [specification, setSpecification] = useState(false)
  const [incomeType, setIncomeType] = useState('1')

  const handleTypeOfDocumentChoice = (values, data)=>{
     if(data.children.toLowerCase() == "other"){
       setSpecification(true)
     }else{
       setSpecification(false)
     }
  }

  const onFinish = async (values) => {
    let type = incomeType === '1' ? 'gross' : 'net'
    let params = {...values, grossIncomeCents: formatGrossIncomeCents(values.grossIncomeCents), incomeType: type }
    submit(params)
    form.resetFields()
  }

  const handleChange = value => {
    setIncomeType(value);
  };

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select selected={incomeType} onSelect={handleChange}
        style={{
          width: 80,
        }}
        defaultValue={incomeType}
      >
        <Option value="1">Gross</Option>
        <Option value="2">Net</Option>
      </Select>
    </Form.Item>
  );

  return data && allowedUpdate ? (
    <Form form={form} onFinish={onFinish} {...layout} colon={false}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <Form.Item name={"incomeVerificationTypeId"} label={"Type of Document"} rules={[{ required: true, message: "Type of Document is required! " }]}>
            <Select size="large" onChange={handleTypeOfDocumentChoice} disabled={disableFlag}>
              {
                incomeVerificationTypeOptions && incomeVerificationTypeOptions.map((obj, index) => {
                  return <Option key={index} value={obj.optionValue}>{obj.optionName}</Option>
                })
              }
            </Select>
          </Form.Item>
          {
            specification ? (<Form.Item 
                            name={"otherType"} 
                            label={"Please Specify"} 
                            rules={[{ required: true, message: "Specification is required!" }]}>
                            <Input size="large" />
                            </Form.Item>) : null
          }
          
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <Form.Item name={"employerClient"} label={"Employer / Client"} rules={[{ required: true, message: "Employer / Client is required!" }]}>
            <Input size="large" disabled={disableFlag}/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <Form.Item name={"grossIncomeCents"} label={"Average Income"} rules={[{ required: true, message: "Gross Income is required!" }]}>
            <InputNumber disabled={disableFlag} addonAfter={suffixSelector} precision={2} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <Form.Item name={"incomeFrequencyId"} label={"Frequency"} rules={[{ required: true, message: "Frequency is required!" }]}>
            <Select size="large" disabled={disableFlag}>
              {
                incomeFrequencyOptions && incomeFrequencyOptions.map((obj, index) => {
                  return <Option key={index} value={obj.optionValue}>{obj.optionName}</Option>
                })
              }
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <Form.Item label={"\u00a0"}>
            <Button type="primary" htmlType="submit" size="large" disabled={disableFlag}>
              Add
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : null;
}

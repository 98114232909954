import React from "react";
import { useHistory } from "react-router-dom";
import { POST, PUT } from "../../../util/network";
import logger from "../../../util/logger";
import { useDispatch } from "react-redux";
import { fetchDealerusers } from "../../../reducers/DealerUsers";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Form,
  Row,
  Select,
  Space,
  message,
} from "antd";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const { Option } = Select;

export default function DealerForm({
  dealerData,
  dealershipsOptions,
  setLoading,
  allowedUpdate,
  handleNoAuth,
  isDisable,
  setIsDisable
}) {
  const history = useHistory();
  const [dealerUserForm] = Form.useForm();

  const dispatch = useDispatch();

  dealershipsOptions = [...dealershipsOptions].sort((a, b) => a.label.localeCompare(b.label))

  const save = async (values) => {
    if (allowedUpdate) {
      setLoading(true);
      try {
        if(dealerData?.id === undefined) {
          await POST(`/api/v1/dealer-users`, values);
        } else {
          await PUT(`/api/v1/dealer-users/${dealerData?.id}`, values);
        }
        dispatch(fetchDealerusers);
        message.success("Saved successfully");
        setLoading(false);
        history.push("/new-dealer-users");
      } catch (e) {
        logger.error("Error while saving!", e);
        setLoading(false);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else if (e.response?.data?.message !== null || e.response?.data?.message !== '' || e.response?.data?.message !== undefined) {
          message.error(e.response.data.message);
        } else {
          message.error("Error while saving!");
        }
      }
    }
  };

  const handleSubmit = async (values) => {
    values = { ...values };
    save(values);
  };

  const handleTriggerPassword = async () => {
    try {
      await POST(`/api/v1/sent-reset-password-mail`,
        {
          dealers: {
            email: dealerData?.email
          }
        }).then(response => {
          if (response.status == 200) {
            message.success(response.data.message)
          }
        })
    } catch (e) {
      message.error('Error')
      console.log(e)
    }
  }

  const notifyCreditDecision = dealerData ? dealerData?.notify_credit_decision : true
  const notifyFundingDecision = dealerData ? dealerData?.notify_funding_decision : true
  const roleOptions = [
    {
      value: 'sales_manager',
      label: 'Sales Manager',
    },
    {
      value: 'sales_staff',
      label: 'Sales Staff',
    },
    {
      value: 'service_manager',
      label: 'Service Manager',
    },
    {
      value: 'finance_manager',
      label: 'Finance Manager',
    },
    {
      value: 'title_clerk',
      label: 'Title Clerk',
    },
    {
      value: 'dealer_principal',
      label: 'Dealer Principal',
    },
    {
      value: 'other',
      label: 'Other',
    }
  ]

  return (
    <>
    { isDisable && (  <Row style={{ marginBottom: "1em", marginRight: '8%' }}>
        <Col span={2}>
          <Button onClick={()=>{ setIsDisable(false);}} style={{ backgroundColor: "#e93b1b", color: 'white', border: 'none' }} type="primary"> Edit Dealer</Button>
        </Col>
        <Col style={{ marginLeft: '20px' }}>
          <Button onClick={handleTriggerPassword} style={{ backgroundColor: "#e93b1b", color: 'white', border: 'none' }} type="primary"> Trigger Password Reset</Button>
        </Col>
     </Row>)}
    
      <Form
        form={dealerUserForm}
        {...layout}
        colon={false}
        onFinish={handleSubmit}
        initialValues={{
          id: dealerData?.id,
          first_name: dealerData?.first_name,
          last_name: dealerData?.last_name,
          email: dealerData?.email,
          dealership_id: dealerData?.dealership_id,
          notify_credit_decision: notifyCreditDecision,
          notify_funding_decision: notifyFundingDecision,
          internal_user: dealerData?.internal_user,
          role_name: dealerData?.role_name,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title="Dealer Info">
              <Row gutter={[24, 16]}>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    hasFeedback
                    rules={[{ required: true, message: "First name is required!" }]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    hasFeedback
                    rules={[
                      { required: true, message: "Email is required!" },
                      { required: false, type: "email", message: "The input is not valid E-mail!" }]}
                  >
                    <Input disabled={isDisable} />

                  </Form.Item>
                  <Form.Item
                    label="Role"
                    name="role_name"
                    hasFeedback
                    rules={[
                      { required: true, message: "Role name is required!" }]}
                  >
                    <Select
                      disabled={isDisable}
                      showSearch
                      filterOption={(input, option) =>
                        option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                      }>
                      {roleOptions &&
                        roleOptions.map(({ value, label }, index) => {
                          return (
                            <Option key={index} value={value}>
                              {label}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    hasFeedback
                    rules={[{ required: true, message: "Last name is required!" }]}
                  >
                    <Input disabled={isDisable} />
                  </Form.Item>

                  <Form.Item
                    label="Dealership"
                    name="dealership_id"
                    hasFeedback
                    rules={[{ required: true, message: "Dealership is required!" }]}
                  >
                    <Select
                      disabled={isDisable}
                      showSearch
                      filterOption={(input, option) =>
                        option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                      }>
                      {dealershipsOptions &&
                        dealershipsOptions.map(({ value, label }, index) => {
                          return (
                            <Option key={index} value={value}>
                              {label}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="internal_user"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={isDisable}>Internal User </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt-20">
          <Col span={24}>
            <Card title="Email Preferences">
              <Row gutter={[24, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="notify_credit_decision"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={isDisable}>Credit Decision </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="notify_funding_decision"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={isDisable}>Funding Decision </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {isDisable ? (
          ""
        ) : (
          <Row gutter={[16, 16]} className="mt-20">
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {dealerData?.id !== undefined
                        ? "Update Dealer"
                        : "Create Dealer"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
    </> 
  );
}

import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Select, Row, Col, Card, Input, Form, Button, Spin, Layout, message, Menu, Collapse, Upload, Typography, DatePicker } from 'antd';
import Icon,{ InfoCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { MotorSvg } from '../../components/layouts/Svg';
import { GET, POST } from '../../util/network';
import logger from '../../util/logger';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import moment from 'moment';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

const { Text } = Typography;
const { Content, Sider } = Layout;
const { Option } = Select;
const { Panel } = Collapse;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function NewInventory(props) {
  const { allowedActions, handleNoAuth } = props;
  const inventoryActions = GetResourceActions('VehicleInventory', allowedActions);

  const history = useHistory();

  const allowedCreate = IsActionAllowed('create', inventoryActions);   
  const allowedGet = IsActionAllowed('get', inventoryActions);   

  const [assetMake, setAssetMake] = useState(null)
  const [assetYear, setAssetYear] = useState(null)
  const [assetModel, setAssetModel] = useState(null)
  const [vehicle, setVehicle] = useState(null)
  const [loading, setLoading] = useState(null)
  const [fileList, setFileList] = useState([])

  const [inventoryStatus, setInventoryStatus] = useState(null)

  const [inventoryForm] = Form.useForm();
  const [activeMenu, setActiveMenu] = useState([1]);

  const verifyVin = async (vin) => {    
    setLoading(true);

    try {
      await GET(`/api/v1/vin_verification/verify-vin?vin=${vin}`).then(response => {
        setAssetMake(response.data.vehicleInfo.make)
        setAssetModel(response.data.vehicleInfo.model)
        setAssetYear(response.data.vehicleInfo.year)

        inventoryForm.setFieldsValue({
          assetMake: response.data.vehicleInfo.make,
          assetYear: response.data.vehicleInfo.year,
          assetModel: response.data.vehicleInfo.model
        })
      }).catch(error => {
        logger.error("Vin Verification Error", error.response)
        message.error(error.response.data.message, 10)

        setAssetMake(null)
        setAssetModel(null)
        setAssetYear(null)

        inventoryForm.setFieldsValue({
          assetMake: null,
          assetYear: null,
          assetModel: null
        })
      })
    } catch (e) {
      logger.error("Vin Verification Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('VIN verification error');
      }
    }

    setLoading(false);
  }


  const handleVerifyVin = (e) => {
    let vin = e.target.value;

    if (vin.length === 17 ) {
      verifyVin(vin)
    }
  }

  const handleSubmit = async (values) => {
    values =  {...values };
    saveVehicleInventory(values);
  }

  const uploadImages = async (id) => {
    let isEmpty = true;

    if (fileList) {
      isEmpty = !Object.keys(fileList).length;
    }

    if (allowedCreate && !isEmpty) {
      try {
        const formData = new FormData();

        formData.append('id', id);
        fileList.forEach(file => {
            formData.append('images[]', file);
        });

        await POST(`/api/v1/vehicle-inventory/upload-image`, formData);
      } catch (e) {
        logger.error("Error saving photos", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error saving images');
        }    
      }
    }
  }

  const saveVehicleInventory = async (values) => {
    if (allowedCreate) {
      try {
       const response = await POST(`/api/v1/vehicle-inventory`, { vehicleInventory: values });
       uploadImages(response.data.id);

       message.success("Save Successfully");
       history.push( `/inventory/${response.data.id}`);
      } catch (e) {
        logger.error("Request Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error saving details');
        }
      }
    }
  }
    
  const getInventoryStatuses = async () => {
    if (allowedGet) {
      try {
        const response = await GET(`/api/v1/inventory-status`);
        setInventoryStatus(response.data.inventoryStatus);
      } catch (e) {
        logger.error("Request Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error saving details');
        }
      }
    }
  }

  const uploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...fileList, file])
      return false;
    },
    fileList,
  };

  useEffect(() => {
    getInventoryStatuses()
  }, []);
  
  return (
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Layout className="layout">
        <MainHeader activePage="inventory"/>
        <Layout>
          <Sider {...SiderProps} >
            <Menu
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu.toString()]}
              style={{ width: '100%' }}
              onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
            >
              <Menu.Item key="1" > <Icon component={MotorSvg} /> Vehicle</Menu.Item>
              <Menu.Item key="2" >  <InfoCircleOutlined /> General Info</Menu.Item>
            </Menu>
          </Sider>

          <Layout id='content-area-layout'>
            <MainBreadcrumb items={
              [{ text: "Home", link_type: "linkto", link: "/dashboard" },
              { text: "Inventory", link_type: "linkto", link: "/inventory" },
              { text: "new", link_type: "ahref", link: void(0) }]
              }
            />
    
            <Content id='main-content'>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Form 
                    form={inventoryForm} 
                    {...layout}  
                    colon={false}
                    onFinish={handleSubmit}
                    scrollToFirstError={true}
                    initialValues={{ 
                      assetVin: vehicle && vehicle.assetVin,
                      newUsed: vehicle && vehicle.newUsed,                         
                      stockNumber: vehicle && vehicle.stockNumber,                         
                      assetColor: vehicle && vehicle.assetColor,                          
                      exactOdometerMileage: vehicle && vehicle.exactOdometerMileage,                         
                      inventoryStatusId: vehicle && vehicle.inventoryStatusId.toString(),
                      intakeDate: vehicle && moment(vehicle.intakeDate),
                      saleDate: vehicle && moment(vehicle.saleDate),
                      assetMake: vehicle && vehicle.assetMake,
                      assetYear: vehicle && vehicle.assetYear,
                      assetModel: vehicle && vehicle.assetModel                   
                    }}
                  > 
                    <Collapse accordion={false} onChange={(key) => { key !== undefined && setActiveMenu(key); }} activeKey={activeMenu}>
                      <Panel className='slc-panel' header="Vehicle" key="1">
                          <Row gutter={[24, 24]} >
                            <Col span={12} >
                              <Form.Item label="Upload Image">
                                <Form.Item valuePropName="fileList" noStyle>                                  
                                  <Upload.Dragger {...uploadProps}>
                                    <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                  </Upload.Dragger>
                                </Form.Item>
                              </Form.Item>
                            </Col>
                            <Col span={12} >
                              <Card>
                                <Row>
                                  <Col span={24}> 
                                    <Form.Item 
                                      label="VIN" 
                                      hasFeedback
                                      name="assetVin"
                                      rules={[{ required: true, message: 'VIN is required!' }]}
                                    >  
                                      <Input placeholder="VIN" onChange={handleVerifyVin} />
                                    </Form.Item>

                                    <Form.Item 
                                      label="assetMake" 
                                      hidden={true}
                                      hasFeedback
                                      name="assetMake"
                                    >  
                                      <Input />
                                    </Form.Item>

                                    <Form.Item 
                                      label="assetYear" 
                                      hidden={true}
                                      hasFeedback
                                      name="assetYear"
                                    >  
                                      <Input />
                                    </Form.Item>

                                    <Form.Item 
                                      label="assetModel" 
                                      hidden={true}
                                      hasFeedback
                                      name="assetModel"
                                    >  
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}> 
                                    <Form.Item 
                                      label="New/Used" 
                                      hasFeedback
                                      name="newUsed"
                                      rules={[{ required: true, message: 'New/Used is required!' }]}
                                    >  
                                      <Select 
                                        showSearch 
                                        placeholder="New/Used" >
                                        <Option key="1" value="New">New</Option>
                                        <Option key="2" value="Used">Used</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col> 
                                </Row>
                                {/* <showDescription label="Make"> TEST </showDescription>
                                <showDescription label="Make"> TEST </showDescription>
                                <showDescription label="Make"> TEST </showDescription> */}
                                <Row style={{marginBottom: 10}}><Col span={24}> <Text style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 700, fontSize: "1.1em" }}> Make </Text> </Col> <Col span={24}> {assetMake} </Col></Row> 
                                <Row style={{marginBottom: 10}}><Col span={24}> <Text style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 700, fontSize: "1.1em" }}> Year </Text> </Col> <Col span={24}> {assetYear} </Col></Row> 
                                <Row style={{marginBottom: 10}}><Col span={24}> <Text style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 700, fontSize: "1.1em" }}> Model </Text> </Col> <Col span={24}> {assetModel} </Col></Row> 
                              </Card>
                            </Col>
                          </Row>
                      </Panel>

                      <Panel className='slc-panel'header="General Info" key="2">
                        <Row gutter={[24, 24]} >
                          <Col span={12} >
                            <Card title="Vehicle">
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Stock Number" 
                                    hasFeedback
                                    name="stockNumber"
                                    rules={[{ required: true, message: 'Stock Number is required!' }]}
                                  >  
                                    <Input placeholder="Stock Number" />
                                  </Form.Item>
                                </Col>
                              </Row>
                                <Row>
                                  <Col span={24}> 
                                    <Form.Item 
                                      label="Vehicle Color" 
                                      hasFeedback
                                      name="assetColor"
                                      rules={[{ required: true, message: 'Vehicle Color is required!' }]}
                                    >  
                                      <Input placeholder="Vehicle Color" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}> 
                                    <Form.Item 
                                      label="Mileage" 
                                      hasFeedback
                                      name="exactOdometerMileage"
                                      rules={[{ required: true, message: 'Mileage is required!' }]}
                                    >  
                                      <Input placeholder="Mileage" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                            </Card>
                          </Col>
                          <Col span={12} >
                            <Card title="Life Cycle">
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Status" 
                                    hasFeedback
                                    name="inventoryStatusId"
                                    rules={[{ required: true, message: 'Status is required!' }]}
                                  > 
                                    <Select 
                                      showSearch 
                                      placeholder="Status" >
                                      {
                                        inventoryStatus && inventoryStatus.map(({id, description}, index) => {
                                          return <Option key={index} value={`${id}`}>{description}</Option>
                                        })
                                      }
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Intake Date" 
                                    hasFeedback
                                    name="intakeDate"
                                    rules={[{ required: true, message: 'Intake Date is required!' }]}
                                  >  
                                    <DatePicker />
                                  </Form.Item>
                                </Col> 
                              </Row>
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Sale Date" 
                                    hasFeedback
                                    name="saleDate"
                                  >  
                                    <DatePicker />
                                  </Form.Item>
                                </Col> 
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                    <br />
                    <Row gutter={[16, 16]}>
                      <Col span={24} style={{ textAlign: 'right' }}> 
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        </Form.Item>
                      </Col> 
                    </Row>
                  </Form>
                </Col>
              </Row>                      
            </Content>
          </Layout>
        </Layout>
        <MainFooter/>
      </Layout>
    </Spin> 
  );
}
import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {Input} from 'antd';

export default function ModelGroupFilters(props) {
  const {Search} = Input;
  const {pageState, setPageState} = props;
  const onChange = (e) => {
    if (e.target.value === '') {
      setPageState({...pageState, search: '', filter: false})
    }
  };

  return (
    <Box sx={{flexGrow: 1, pb: 2}}>
      <Grid container spacing={2} direction="row"
            justifyContent="flex-start"
            alignItems="center"
      >
        <Grid item md={4}>
          <Search placeholder="Global Search" size="large" defaultValue={pageState.search} onChange={onChange}
                  allowClear className="leaseAppListSearch" onSearch={(searchVal) => {
            setPageState({...pageState, search: searchVal, filter: true})
          }} enterButton/>
        </Grid>
      </Grid>
    </Box>
  )
}

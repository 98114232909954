import React from 'react'
import { Row, Col, Form, Input, Radio, Typography } from 'antd';
const { Text } = Typography
export default function Residual(props) {
    const { leaseApplication, radioButtonState, currencyFormatter, InputNumber } = props;

    return (
        <>

            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text strong level={4} className="vcc-text">
                        As a final step in the process, we will need to review your lease terms and bike information. Please confirm:
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text strong level={4} className="vcc-text">
                        Let’s start with the bike information:
                    </Text>
                </Col>
            </Row>

            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        Can you please confirm the year, make and model of the bike?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={4}>
                    <Form.Item
                        name={["lesseeReportedYear"]}
                        rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <InputNumber placeholder={"Year"} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["lesseeReportedMake"]}
                        rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <Input placeholder={"Make"} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={["lesseeReportedModel"]}
                        rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <Input placeholder={"Model"} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={24}>
                    <Text level={4} className='vcc-text'>
                        What color is the bike?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={6}>
                    <Form.Item
                        name={['vehicleColor']}
                        rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <Input placeholder='Color' />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={24}>
                    <Text level={4} className='vcc-text'>
                        What are the last six digits of the VIN?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={6}>
                    <Form.Item
                        name={['vinNumberLastSix']}
                        rules={[
                            { required: true, message: 'Required' },
                            { min: 6, message: 'Enter the last 6 characters' }
                        ]}
                        className='vcc-form-item'
                    >
                        <Input
                            placeholder='Last six'
                            maxLength={6}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <p style={{ fontStyle: 'italic' }}>The VIN can be found on the neck where the fork attaches to the frame</p>
                </Col>
            </Row>



            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={24}>
                    <Text level={4} className='vcc-text'>
                        What is the exact bike mileage?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={6}>
                    <Form.Item
                        name={['vehicleMileage']}
                        rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <InputNumber placeholder='Mileage' />
                    </Form.Item>
                </Col>
            </Row>

        </>
    )
}

import React, { useState, useEffect } from 'react'
import { Form, Select, Button, Col, Input, DatePicker, Row, Typography } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import momentTimezone from 'moment-timezone';
import { useDispatch } from "react-redux";
import { updateDocumentStatus } from '../../../reducers/LeaseApplications'

const { Text } = Typography
const Option = Select.Option
const getDate = function (date) {
  return date ? moment(date, ['YYYY-MM-DD', 'MM/DD/YYYY']) : undefined
}
momentTimezone.tz.setDefault("America/New_York");

export default function Document({ data, disableFlag, save }) {
  const [form] = Form.useForm()
  const [documentStatusOptions, setDocumentStatusOptions] = useState(data.documentStatusOptions)
  const [documentStatus, setDocumentStatus] = useState(data.documentStatus)
  const [initialDocumentStatus, setInitialDocumentStatus] = useState(data.documentStatus)
  const [requireFundedOn, setRequireFundedOn] = useState(false)
  const [requireFundedApproveOn, setRequireFundedApproveOn] = useState(false)
  const [requireFundingDelayOn, setRequireFundingDelayOn] = useState(false)
  const [saveButtonErrorMsgs, setSaveButtonErrorMsgs] = useState([])
  const [disableSaveButton, setDisableSaveButton] = useState(null)
  const [requireDocumentsIssued, setRequireDocumentsIssued] = useState(false)
  const [requiredLeasePackageReceived, setRequiredLeasePackageReceived] =
    useState(false)
  const [requiredDocumentsRequested, setRequiredDocumentsRequested] =
    useState(false)


  let admin = JSON.parse(localStorage.user_data);
  let firstName = admin.first_name ? admin.first_name : '';
  let lastName = admin.last_name ? admin.last_name : '';

  const [reviewerNameCache] = useState(data.theReviewer);
  const [approverNameCache] = useState(data.theApprover);
  const [reviewerName, setReviewerName] = useState(data.theReviewer);
  const [approverName, setApproverName] = useState(data.theApprover);
  const dispatch = useDispatch();

  const handleSelectionChange = (value) => {
    if (value == 'funding_approved') {
      setApproverName(firstName + " " + lastName)
    } else if (value == 'documents_requested') {
      setReviewerName(firstName + " " + lastName)
    } else {
      setReviewerName(reviewerNameCache)
      setApproverName(approverNameCache)
    }
  }

  const updateDocumentsIssuedDate = (value) => {
    if (value === 'documents_issued') {
      form.setFieldsValue({
        documentsRequestedDate: getDate(data.documentsRequestedDate),
        documentsIssuedDate: getDate(data.documentsIssuedDate) || moment(),
        leasePackageReceivedDate: getDate(data.leasePackageReceivedDate),
        fundedOn: getDate(data.fundedOn),
        fundingDelayOn: getDate(data.fundingDelayOn),
        fundingApprovedOn: getDate(data.fundingApprovedOn),
      })
    }
  }

  const updateLeasePackageReceivedDate = (value) => {
    if (value === 'lease_package_received') {
      form.setFieldsValue({
        documentsRequestedDate: getDate(data.documentsRequestedDate),
        documentsIssuedDate: getDate(data.documentsIssuedDate),
        leasePackageReceivedDate: getDate(data.leasePackageReceivedDate) || moment(),
        fundedOn: getDate(data.fundedOn),
        fundingDelayOn: getDate(data.fundingDelayOn),
        fundingApprovedOn: getDate(data.fundingApprovedOn),
      })
    }
  }

  const updateFundingDelayValueDate = (value) => {
    if (value === 'funding_delay') {
      form.setFieldsValue({
        documentsRequestedDate: getDate(data.documentsRequestedDate),
        documentsIssuedDate: getDate(data.documentsIssuedDate),
        leasePackageReceivedDate: getDate(data.leasePackageReceivedDate),
        fundedOn: getDate(data.fundedOn),
        fundingDelayOn: getDate(data.fundingDelayOn) || moment(),
        fundingApprovedOn: getDate(data.fundingApprovedOn)
      })
    }
  }

  const updateFundingApprovedOnDate = (value) => {
    if (value === 'funding_approved') {
      form.setFieldsValue({
        documentsRequestedDate: getDate(data.documentsRequestedDate),
        documentsIssuedDate: getDate(data.documentsIssuedDate),
        leasePackageReceivedDate: getDate(data.leasePackageReceivedDate),
        fundedOn: getDate(data.fundedOn),
        fundingDelayOn: getDate(data.fundingDelayOn),
        fundingApprovedOn: getDate(data.fundingApprovedOn) || moment(),
      })
    }
  }

  const updateFundedOnDate = (value) => {
    if (value === 'funded') {
      form.setFieldsValue({
        documentsRequestedDate: getDate(data.documentsRequestedDate),
        documentsIssuedDate: getDate(data.documentsIssuedDate),
        leasePackageReceivedDate: getDate(data.leasePackageReceivedDate),
        fundedOn: getDate(data.fundedOn) || moment(),
        fundingDelayOn: getDate(data.fundingDelayOn),
        fundingApprovedOn: getDate(data.fundingApprovedOn),
      })
    }
  }

  const updateDocumentsRequestedDate = (value) => {
    if (value === 'documents_requested') {
      form.setFieldsValue({
        documentsRequestedDate: getDate(data.documentsRequestedDate) || moment(),
        documentsIssuedDate: getDate(data.documentsIssuedDate),
        leasePackageReceivedDate: getDate(data.leasePackageReceivedDate),
        fundedOn: getDate(data.fundedOn),
        fundingDelayOn: getDate(data.fundingDelayOn),
        fundingApprovedOn: getDate(data.fundingApprovedOn),
      })
    }
  }

  const updateCanceledDate = (value) => {
    if (value === 'canceled') {
      form.setFieldsValue({
        documentsRequestedDate: getDate(data.documentsRequestedDate),
        documentsIssuedDate: getDate(data.documentsIssuedDate),
        leasePackageReceivedDate: getDate(data.leasePackageReceivedDate),
        fundedOn: getDate(data.fundedOn),
        fundingDelayOn: getDate(data.fundingDelayOn),
        fundingApprovedOn: getDate(data.fundingApprovedOn),
      })
    }
  }

  const updateNoDocumentsDate = (value) => {
    if (value === 'no_documents') {
      form.setFieldsValue({
        documentsRequestedDate: null,
        documentsIssuedDate: null,
        leasePackageReceivedDate: null,
        fundedOn: null,
        fundingDelayOn: null,
        fundingApprovedOn: null,
      })
    }
  }

  const updateFieldsValues = (value) => {
    updateDocumentsRequestedDate(value)
    updateFundedOnDate(value)
    updateFundingApprovedOnDate(value)
    updateFundingDelayValueDate(value)
    updateLeasePackageReceivedDate(value)
    updateDocumentsIssuedDate(value)
    updateCanceledDate(value)
    updateNoDocumentsDate(value)
  }

  const prepareDocumentStatusOptions = (status) => {
    let options = [...documentStatusOptions];
    options = options.map((option) => {
      return { ...option, disabled: false };
    });

    setDocumentStatus(status)
    form.setFieldsValue({ 
      documentStatus: status
    });
    setDisableSaveButton(null)
    setSaveButtonErrorMsgs([])
    switch (status) {
      case 'no_documents':
        options[_.findIndex(options, { optionValue: 'funding_delay' })].disabled = true;
        options[_.findIndex(options, { optionValue: 'funding_approved' })].disabled = true;
        options[_.findIndex(options, { optionValue: 'funded' })].disabled = true;
      break;
      case 'documents_requested':
        options[_.findIndex(options, { optionValue: 'funding_delay' })].disabled = true
        options[_.findIndex(options, { optionValue: 'funding_approved' })].disabled = true
        options[_.findIndex(options, { optionValue: 'funded' })].disabled = true
        break;
      case 'documents_issued':
        options[_.findIndex(options, { optionValue: 'funding_delay' })].disabled = true;
        options[_.findIndex(options, { optionValue: 'funding_approved' })].disabled = true;
        options[_.findIndex(options, { optionValue: 'funded' })].disabled = true;
        break;
      case 'lease_package_received':
        validateBankingInformation(data.payment)
        break
      case 'funding_approved':
      case 'funded':
        validateBankingInformation(data.payment)
        validateInsuranceInformation(data.insurance)
        validateVerificationCallCompleted()
        break
    }
    setDocumentStatusOptions(options)
  }
console.log('doc status', data.documentStatus);
  useEffect(() => {
    prepareDocumentStatusOptions(data?.documentStatus)
    toggleRequired(data?.documentStatus)
    updateFieldsValues(data?.documentStatus)
  }, [data])

  const validateBankingInformation = (payment) => {
    // Meta validate Banking Information
    let requiredBankingFields = [
      'paymentBankName',
      'paymentAbaRoutingNumber',
      'paymentAccountNumber',
      'paymentAccountType',
      'paymentAccountHolder',
      'firstPaymentDate',
      'paymentFirstDay',
      'paymentFrequency',
    ]

    if (payment.paymentFrequency == 1) {
      requiredBankingFields.push('secondPaymentDate', 'paymentSecondDay')
    }

    let inputs = _.map(requiredBankingFields, function (field) {
      return _.get(payment, field)
    })

    // Get all `null` values from object into an array
    if (_.values(_.pickBy(inputs, _.isNull)).length > 0) {
      setDisableSaveButton(true)
      setSaveButtonErrorMsgs((oldData) => [...oldData, 'Please complete the required Banking fields.'])
    }
  }

  const validateInsuranceInformation = (insurance) => {
    let requiredInsuranceFields = [
      'additionalInsured',
      'bodilyInjuryPerOccurrence',
      'bodilyInjuryPerPerson',
      'collision',
      'comprehensive',
      'companyName',
      'effectiveDate',
      'expirationDate',
      'lossPayee',
      'policyNumber',
      'propertyDamage',
    ]

    let inputs = _.map(requiredInsuranceFields, function (field) {
      return _.get(insurance, field)
    })

    let insuranceValidated = true

    if (_.values(_.pickBy(inputs, _.isNull)).length > 0) {
      insuranceValidated = false
    }

    if (!insuranceValidated) {
      setDisableSaveButton(true)
      setSaveButtonErrorMsgs((oldData) => [...oldData, 'Please complete the required Insurance fields.'])
    }
  }

  const validateVerificationCallCompleted = () => {
    const callComplete = verificationCallComplete()
    const callIssue = verificationCallIssue()

    if (!callComplete) {
      setDisableSaveButton(true)
      setSaveButtonErrorMsgs((oldData) => [...oldData, 'Please complete the verification call.'])
      return false
    }

    if (callIssue) {
      setDisableSaveButton(true)
      setSaveButtonErrorMsgs((oldData) => [...oldData, 'Please resolve the outstanding verification call issues.'])
      return false
    }

    return true
  }

  const verificationCallComplete = () => {
    if (!data.leaseVerificationCall || !data.leaseVerificationCall.callExists) {
      return false
    }

    return true
  }

  const verificationCallIssue = () => {
    if (!data.leaseVerificationCall || data.leaseVerificationCall.issue) {
      return true
    }

    return false
  }

  const toggleRequired = (value) => {
    switch (value) {
      case 'funded':
        setRequireFundedOn(true)
        setRequireFundedApproveOn(false)
        setRequireFundingDelayOn(false)
        setRequireDocumentsIssued(false)
        setRequiredLeasePackageReceived(false)
        setRequiredDocumentsRequested(false)
        break
      case 'funding_approved':
        setRequireFundedOn(false)
        setRequireFundedApproveOn(true)
        setRequireFundingDelayOn(false)
        setRequireDocumentsIssued(false)
        setRequiredLeasePackageReceived(false)
        setRequiredDocumentsRequested(false)
        break
      case 'funding_delay':
        setRequireFundedOn(false)
        setRequireFundedApproveOn(false)
        setRequireFundingDelayOn(true)
        setRequireDocumentsIssued(false)
        setRequiredLeasePackageReceived(false)
        setRequiredDocumentsRequested(false)
        break
      case 'documents_issued':
        setRequireFundedOn(false)
        setRequireFundedApproveOn(false)
        setRequireFundingDelayOn(false)
        setRequireDocumentsIssued(true)
        setRequiredLeasePackageReceived(false)
        setRequiredDocumentsRequested(false)
        break
      case 'lease_package_received':
        setRequireFundedOn(false)
        setRequireFundedApproveOn(false)
        setRequireFundingDelayOn(false)
        setRequireDocumentsIssued(false)
        setRequiredLeasePackageReceived(true)
        setRequiredDocumentsRequested(false)
        break
      case 'documents_requested':
        setRequireFundedOn(false)
        setRequireFundedApproveOn(false)
        setRequireFundingDelayOn(false)
        setRequireDocumentsIssued(false)
        setRequiredLeasePackageReceived(false)
        setRequiredDocumentsRequested(true)
        break
      case 'canceled':
        setRequireFundedOn(false)
        setRequireFundedApproveOn(false)
        setRequireFundingDelayOn(false)
        setRequireDocumentsIssued(false)
        setRequiredLeasePackageReceived(false)
        setRequiredDocumentsRequested(false)
        break;
      case 'no_documents':
        setRequireFundedOn(false)
        setRequireFundedApproveOn(false)
        setRequireFundingDelayOn(false)
        setRequireDocumentsIssued(false)
        setRequiredLeasePackageReceived(false)
        setRequiredDocumentsRequested(false)
        break;
      default:
        setRequireFundedOn(false)
        setRequireFundedApproveOn(false)
        setRequireFundingDelayOn(false)
        setRequireDocumentsIssued(false)
        setRequiredLeasePackageReceived(false)
        setRequiredDocumentsRequested(false)
    }
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name)
  }

  const onFinish = async (values) => {
    values = { ...values, theReviewer: reviewerName, theApprover: approverName }
    values.lessee_attributes = {
      ...data.lessee,
      emailAddress: values.lesseEmail
    }
    delete values.lesseEmail
    setInitialDocumentStatus(values.documentStatus)
    await save(values)
    dispatch(updateDocumentStatus(values))
  }

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }

  return !data ? null : (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        documentStatus: data?.documentStatus,
        documentsIssuedDate: getDate(data.documentsIssuedDate),
        documentsRequestedDate: getDate(data.documentsRequestedDate),
        lesseEmail: data.lessee && data.lessee.emailAddress,
        leasePackageReceivedDate: getDate(data.leasePackageReceivedDate),
        fundedOn: getDate(data.fundedOn),
        fundingDelayOn: getDate(data.fundingDelayOn),
        fundingApprovedOn: getDate(data.fundingApprovedOn),
      }}
    >
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <Form.Item label="Document Status" name="documentStatus">
            <Select
              style={{ width: '100%' }}
              placeholder="Please Select"
              onChange={(value) => {
                setDocumentStatus(value)
                prepareDocumentStatusOptions(value)
                toggleRequired(value)
                handleSelectionChange(value)
                updateFieldsValues(value)
              }}
              disabled={disableFlag}
            >
              {(documentStatusOptions || []).map((obj, key) => {
                return (
                  <Option key={key} value={obj.optionValue} disabled={obj.disabled}>
                    {obj.optionName}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Email Address"
            name="lesseEmail"
            rules={[
              {
                required: true,
                message: 'Please enter the mail address',
              },
            ]}
          >
            <Input placeholder="Enter email" disabled={disableFlag} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Reviewed By">
            <em>{reviewerName}</em>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Approved By">
            <em>{approverName}</em>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Documents Requested Date"
            name="documentsRequestedDate"
            rules={[
              {
                required: requiredDocumentsRequested,
                message: 'Please enter docuements requested date',
              },
            ]}
          >
            <DatePicker format="MM-DD-YYYY" style={{ width: '100%' }} disabled={disableFlag} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Documents Issued Date"
            name="documentsIssuedDate"
            rules={[
              {
                required: requireDocumentsIssued,
                message: 'Please enter the Documents Issued Date',
              },
            ]}
          >
            <DatePicker format="MM-DD-YYYY" style={{ width: '100%' }} disabled={disableFlag} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
              label="Documents Received Date"
              name="leasePackageReceivedDate"
              rules={[
                {
                  required: requiredLeasePackageReceived,
                  message: 'Please enter documents received date',
                },
              ]}
          >
            <DatePicker format="MM-DD-YYYY" style={{ width: '100%' }} disabled={disableFlag} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <Form.Item
            label="Funding Delay On"
            name="fundingDelayOn"
            rules={[
              {
                required: requireFundingDelayOn,
                message: 'Please enter the Funding Delay Date',
              },
            ]}
          >
            <DatePicker format="MM-DD-YYYY" style={{ width: '100%' }} disabled={disableFlag} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Funding Approved On"
            name="fundingApprovedOn"
            rules={[
              {
                required: requireFundedApproveOn,
                message: 'Please enter the Funding Approved Date',
              },
            ]}
          >
            <DatePicker format="MM-DD-YYYY" style={{ width: '100%' }} disabled={disableFlag} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
              label="Funded On"
              name="fundedOn"
              rules={[
                {
                  required: requireFundedOn,
                  message: 'Please enter the Funded Date',
                },
              ]}
          >
            <DatePicker format="MM-DD-YYYY" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit" disabled={disableSaveButton || disableFlag}>
            Save
          </Button>
        </Col>
      </Row>
      <Row className="save-helper">
        <Col span={24}>
          {saveButtonErrorMsgs.map((msg, key) => {
            return (
              <>
                {key ? <br /> : ``}
                <Text key={key} type="danger">
                  {msg}
                </Text>
              </>
            )
          })}
        </Col>
      </Row>
    </Form>
  )
}
import React, { useState, useEffect } from "react";
import { Row, Col, Form, Layout, Menu, Collapse, message, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import Icon, { RocketOutlined } from '@ant-design/icons';
import { GET } from '../../../util/network'
import MainHeader from '../../../components/layouts/MainHeader';
import MainBreadcrumb from '../../../components/layouts/MainBreadcrumb';
import MainFooter from '../../../components/layouts/MainFooter';
import { SiderProps } from '../../../components/layouts/MainSiderProps';
import Summary from './blackbox_components/Summary';
import AdverseReasonCodes from './blackbox_components/AdverseReasonCodes';
import TloAddressSearch from './blackbox_components/TloAddressSearch';
import ErrorCodes from './blackbox_components/ErrorCodes';
import EmploymentSearch from './blackbox_components/EmploymentSearch';
import { MailboxSvg, FileErrorSvg, ExclamationCircleOutlinedSvg, BriefCaseSvg } from '../../../components/layouts/Svg';
import logger from '../../../util/logger';

const { Content, Sider } = Layout;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function Blackbox(props) {
  const { handleNoAuth } = props;
  const history = useHistory();

  const auth_token = window.localStorage.getItem('auth_token');
  const [activeMenu, setActiveMenu] = useState([1]);
  const { appNumber, id } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  let applicationIdentifier;

  const getBlackbox = async () => {
    setLoading(true);

    try {
      const response = await GET(`/api/v1/lease-application-blackbox-requests/${id}`);
      setData(response.data);      
    } catch (e) {
      logger.error("Error while fetching Blackbox", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while fetching Blackbox');
      }
    }
    setLoading(false);
  };

    useEffect(() => {
      getBlackbox();      
    }, []);

  return data ? (    
    applicationIdentifier = data.leaseApplicationBlackboxRequest.applicationIdentifier 
      ? data.leaseApplicationBlackboxRequest.applicationIdentifier 
      : appNumber,
    <Spin spinning={loading}>
      <Layout>
        <MainHeader activePage='leaseApplication'/>
        <Layout>
          <Sider {...SiderProps} >
            <Menu              
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu.toString()]}
              style={{ width: '100%' }}
              onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
            >
              <Menu.Item key="1" > <RocketOutlined /> Summary </Menu.Item>
              <Menu.Item key="2" > <Icon component={MailboxSvg} /> Address Search </Menu.Item>
              <Menu.Item key="3" > <Icon component={FileErrorSvg} /> Adverse Reason </Menu.Item>
              <Menu.Item key="4" > <Icon component={BriefCaseSvg} /> Employment </Menu.Item>
              <Menu.Item key="5" > <Icon component={ExclamationCircleOutlinedSvg} /> Error Codes </Menu.Item>
            </Menu>
          </Sider>

          <Layout id='content-area-layout'>
            <MainBreadcrumb items={
              [{ text: "Home", link_type: "linkto", link: "/dashboard" },
              { text: "Lease Applications", link_type: "linkto", link: "/lease-applications-list" },
              { text: `${applicationIdentifier}`, link_type: "linkto", link: `/lease-applications/${appNumber}` },
              { text: "Blackbox" }
              ]}
            />
        
            <Content id='main-content'>
              <Form 
                {...layout}  
                colon={false}
                scrollToFirstError={true}
              >
                <Row gutter={[0, 24]} >
                  <Col span={24} >
                    <Collapse accordion={false} onChange={(key) => { key !== undefined && setActiveMenu(key); }} activeKey={activeMenu}>
                      <Panel className='slc-panel' header="Summary" key="1">
                        <Summary 
                          data={data}
                          handleNoAuth={handleNoAuth}
                          setLoading={setLoading}
                        />
                      </Panel>
                      <Panel className='slc-panel' header="TLO Address Search" key="2">
                        <TloAddressSearch 
                          data={data} 
                          handleNoAuth={handleNoAuth}
                          setLoading={setLoading} 
                        />
                      </Panel>
                      <Panel className='slc-panel' header="Adverse Reason Codes" key="3">
                        <AdverseReasonCodes 
                          data={data} 
                          handleNoAuth={handleNoAuth}
                        />
                      </Panel>
                      <Panel className='slc-panel' header="TWN Employment Search" key="4">
                        <EmploymentSearch
                          data={data}
                          handleNoAuth={handleNoAuth}
                        />
                      </Panel>
                      <Panel className='slc-panel' header="Error Codes" key="5">
                        <ErrorCodes 
                          data={data}
                          handleNoAuth={handleNoAuth}
                        />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Form>
            </Content>
          </Layout>
        </Layout>
        <MainFooter/>
      </Layout>
    </Spin>
  ) : null;
}
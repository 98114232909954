import React, {useState} from "react";
import {Layout, Button} from "antd";
import '../../App.css';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import FundingDelaysDataGrid from './components/FundingDelaysDataGrid'
import {styled} from '@mui/system';
import Typography from "@material-ui/core/Typography";
import IconButton from '@mui/material/IconButton';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import {useHistory} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import {Link} from 'react-router-dom';
import {getGridStringOperators, getGridNumericOperators, getGridDateOperators} from '@mui/x-data-grid-pro';

export default function FundingDelaysList(props) {
  const {Content} = Layout;
  const auth_token = window.localStorage.getItem('auth_token');
  const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;
  const history = useHistory();
  const {data, loading, pageState, setPageState, total} = props;

  const columns = [
    {
      field: 'id', headerName: 'Id', type: 'string', flex: 0.5, minWidth: 10, filterable: false
    },
    {
      field: 'leaseApplicationId',
      headerName: 'Lease Application Id',
      type: 'string',
      flex: .75,
      minWidth: 75,
      filterable: false,
      renderCell: (record) => {
        return record ?
          <a href={`/lease-applications/${record.row.leaseApplicationId}`}>{record.row.leaseApplicationId}</a> : "";
      },
    },
    {
      field: 'fundingDelayReason',
      headerName: 'Funding Delay Reason',
      type: 'string',
      flex: 2,
      minWidth: 75,
      filterable: false
    },
    {
      field: 'notes', headerName: 'Notes', type: 'string', flex: 1.5, minWidth: 50, filterable: false
    },
    {
      field: 'status', headerName: 'Status', type: 'string', flex: 1, minWidth: 50, filterable: false
    },
    {
      field: 'createdAt', headerName: 'Created At', type: 'string', flex: 1, minWidth: 72, filterable: false
    },
    {
      field: 'updatedAt', headerName: 'Updated At', type: 'string', flex: 1, minWidth: 72, filterable: false
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'action',
      flex: 1,
      filterable: false,
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{textTransform: "none"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/funding_delays/${record.row.id}`,
                });
              }}
            >
              View
            </Button>
            <Button
              style={{textTransform: "none", marginLeft: "1rem"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/edit_funding_delays/${record.row.id}`,
                });
              }}
            >
              Edit
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ]

  return (
    <Layout>
      <MainHeader activePage="leaseApplicationRelated"/>
      <Layout>
        <Layout id='content-area-layout'>
          <Content id='main-content' style={{backgroundColor: "#ffff !important"}}>
            <Box sx={{flexGrow: 1, pb: 2}}>
              <Grid container spacing={2} direction="row" justifyContent="flex-start">
                <Grid item md={4} sm={4}>
                  <Typography variant={"h4"}>Funding Delays</Typography>
                </Grid>
              </Grid>
            </Box>
            <StyledBox>
              {
                data &&
                <FundingDelaysDataGrid
                  columns={columns}
                  data={data}
                  loading={loading}
                  setPageState={setPageState}
                  pageState={pageState}
                  total={total}
                />
              }
            </StyledBox>
          </Content>
        </Layout>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})
